import PlanCacheReader from 'services/cache/PlanCacheReader'
import { db } from 'services/cache/CacheController'
import { getUsername } from 'services/common/user'
import tableLock from 'services/common/TableLock'

export default class PlanCacheWriter {
    constructor() {
        this.planCacheReader = new PlanCacheReader()
    }
    
    /**
     * Create or overwrite into the cache.
     * @param {*} plan
     */
    async bulkPut(plans) {
        let userId = await getUsername()
        await db.transaction('rw', db.plans, async () => {
            await db.plans.bulkPut(plans.map(plan => ({ userId, ...plan })))
        })
    }

    async bulkDelete(dates) {
        let userId = await getUsername()
        await db.transaction('rw', db.plans, async () => {
            await db.plans.bulkDelete(dates.map(date => [ date, userId ]))
        })
    }

    async deletePlannedRecipe(date, recipeId) {
        const unlock = await tableLock.getLock('plans')
        try {
            let plan = await this.planCacheReader.getByDate(date)
            if (plan != null) {
                const index = plan.items.findIndex(item => item.recipeId === recipeId)
                if (index !== -1) {
                    // Remove the matching item from the array.
                    plan.items.splice(index, 1)
                    await db.transaction('rw', db.plans, async () => {
                        await db.plans.put(plan)
                    })
                }
            }
        } finally {
            unlock()
        }
    }

    async planRecipe(date, recipeId) {
        const unlock = await tableLock.getLock('plans')
        try {
            let plan = await this.planCacheReader.getByDate(date)
            if (plan != null) {
                // Update existing plan.
                plan.items.push({ recipeId })
                
            } else {
                // Create the plan.
                let userId = await getUsername()
                plan = {
                    date,
                    userId,
                    items: [
                        { recipeId },
                    ],
                }
            }

            await db.transaction('rw', db.plans, async () => {
                await db.plans.put(plan)
            })
        } finally {
            unlock()
        }
    }
}