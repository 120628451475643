import {
    createMuiTheme
} from '@material-ui/core/styles'

export const themeList = [
    {
        name: 'Material UI',
        theme: createMuiTheme({
        })
    },
    {
        name: 'GreenOrange',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#388e3c'
                },
                secondary: {
                    main: '#f57c00'
                }
            }
        })
    },
    {
        name: 'OrangeBlue',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#ffc107'
                },
                secondary: {
                    main: '#0745ff'
                }
            }
        })
    },
    {
        name: 'OrangeBlueDarker',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#ffa100'
                },
                secondary: {
                    main: '#005dff'
                }
            }
        })
    },
    {
        name: 'OrangeBlueDarkerTriadic',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#ffa100'
                },
                secondary: {
                    main: '#5dff00'
                }
            }
        })
    },
    {
        name: 'GreenComplementary',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#00bc00'
                },
                secondary: {
                    main: '#bc00bc'
                }
            }
        })
    },
    {
        name: 'GreenAnalogous',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#00bc00'
                },
                secondary: {
                    main: '#009947'
                }
            }
        })
    },
    {
        name: 'GreenTriadic1',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#00bc00'
                },
                secondary: {
                    main: '#00bcbc'
                }
            }
        })
    },
    {
        name: 'GreenTriadic2',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#00bc00'
                },
                secondary: {
                    main: '#0000bc'
                }
            }
        })
    },
    {
        name: 'BlueComplementary1',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#00a4f6'
                },
                secondary: {
                    main: '#f65200'
                }
            }
        })
    },
    {
        name: 'BlueComplementary2',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#00a4f6'
                },
                secondary: {
                    main: '#ff9100'
                }
            }
        })
    },
    {
        name: 'BlueAnalogous1',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#00a4f6'
                },
                secondary: {
                    main: '#00f6cd'
                }
            }
        })
    },
    {
        name: 'BlueAnalogous2',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#00a4f6'
                },
                secondary: {
                    main: '#0029f6'
                }
            }
        })
    },
    {
        name: 'BlueTriadic1',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#00a4f6'
                },
                secondary: {
                    main: '#5200f6'
                }
            }
        })
    },
    {
        name: 'BlueTriadic2',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#00a4f6'
                },
                secondary: {
                    main: '#f600a4'
                }
            }
        })
    },
    {
        name: 'IndigoComplementary',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#0023f6'
                },
                secondary: {
                    main: '#f6d100'
                }
            }
        })
    },
    {
        name: 'IndigoAnalogous1',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#0023f6'
                },
                secondary: {
                    main: '#00a0f6'
                }
            }
        })
    },
    {
        name: 'IndigoAnalogous2',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#0023f6'
                },
                secondary: {
                    main: '#5600f6'
                }
            }
        })
    },
    {
        name: 'IndigoTriadic1',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#0023f6'
                },
                secondary: {
                    main: '#d100f6'
                }
            }
        })
    },
    {
        name: 'IndigoTriadic2',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#0023f6'
                },
                secondary: {
                    main: '#f60025'
                }
            }
        })
    },
    {
        name: 'PurpleComplementary',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#5300d9'
                },
                secondary: {
                    main: '#86d900'
                }
            }
        })
    },
    {
        name: 'PurpleAnalogous1',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#5300d9'
                },
                secondary: {
                    main: '#0019d9'
                }
            }
        })
    },
    {
        name: 'PurpleAnalogous2',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#5300d9'
                },
                secondary: {
                    main: '#c000d9'
                }
            }
        })
    },
    {
        name: 'PurpleTriadic1',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#5300d9'
                },
                secondary: {
                    main: '#eb008b'
                },
                text: {
                    primary: '#4d0061'
                }
            }
        })
    },
    {
        name: 'PurpleTriadic2',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#5300d9'
                },
                secondary: {
                    main: '#d95300'
                }
            }
        })
    },
    {
        name: 'BurgandyComplementary',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#8c0c1e'
                },
                secondary: {
                    main: '#00705f'
                }
            }
        })
    },
    {
        name: 'BurgandyAnalogous1',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#8c0c1e'
                },
                secondary: {
                    main: '#700049'
                }
            }
        })
    },
    {
        name: 'BurgandyAnalogous2',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#8c0c1e'
                },
                secondary: {
                    main: '#ffbb92'
                }
            }
        })
    },
    {
        name: 'BurgandyTriadic1',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#8c0c1e'
                },
                secondary: {
                    main: '#705f00'
                }
            }
        })
    },
    {
        name: 'BurgandyTriadic2',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#f82335'
                },
                secondary: {
                    main: '#35f823'
                }
            }
        })
    },
    {
        name: 'AugbergineGreen',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#6b057d'
                },
                secondary: {
                    main: '#35a022'
                }
            }
        })
    },
    {
        name: 'AugbergineYellow',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#6b057d'
                },
                secondary: {
                    main: '#e6e693'
                }
            }
        })
    },
    {
        name: 'AugbergineGold',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#6b057d'
                },
                secondary: {
                    main: '#7d6b05'
                }
            }
        })
    },
    {
        name: 'DarkAugbergineGreen',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#4f006f'
                },
                secondary: {
                    main: '#005f00'
                }
            }
        })
    },
    {
        name: 'DarkAugbergineLightGreen',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#4f006f'
                },
                secondary: {
                    main: '#5ebb4e'
                }
            }
        })
    },
    {
        name: 'DarkAugbergineGold',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#4f006f'
                },
                secondary: {
                    main: '#ada717'
                }
            }
        })
    },
    {
        name: 'DarkAugbergineGold2',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#4f006f'
                },
                secondary: {
                    main: '#cbcd23'
                }
            }
        })
    },
    {
        name: 'CharcoalPink',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#414247'
                },
                secondary: {
                    main: '#a86378'
                },
                text: {
                    primary: '#262626'
                }
            }
        })
    },
    {
        name: 'CharcoalLightBrown',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#414247'
                },
                secondary: {
                    main: '#d5c0a5'
                },
                text: {
                    primary: '#262626'
                }
            }
        })
    },
    {
        name: 'DustyBlueOrange',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#365370'
                },
                secondary: {
                    main: '#dfa64a'
                },
                text: {
                    primary: '#262626'
                }
            }
        })
    },
    {
        name: 'DustyBlueLightBlue',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#365370'
                },
                secondary: {
                    main: '#e1e8f1'
                },
                text: {
                    primary: '#262626'
                }
            }
        })
    },
    {
        name: 'DustyBlueDustyRed',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#365370'
                },
                secondary: {
                    main: '#993b3e'
                },
                text: {
                    primary: '#262626'
                }
            }
        })
    },
    {
        name: 'OliveGreenMustard',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#949b51'
                },
                secondary: {
                    main: '#dbb94b'
                },
                text: {
                    primary: '#262626'
                }
            }
        })
    },
    {
        name: 'OliveGreenBurntOrange',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#949b51'
                },
                secondary: {
                    main: '#a13c2c'
                },
                text: {
                    primary: '#262626'
                }
            }
        })
    },
    {
        name: 'OliveGreenBrown',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#949b51'
                },
                secondary: {
                    main: '#5c2316'
                },
                text: {
                    primary: '#262626'
                }
            }
        })
    },
    {
        name: 'LightGreyLightBrown',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#e6e9ea'
                },
                secondary: {
                    main: '#d0a257'
                },
                text: {
                    primary: '#262626'
                }
            }
        })
    },
    {
        name: 'BeetrootLightBrown',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#6e324d'
                },
                secondary: {
                    main: '#c49d74'
                },
                text: {
                    primary: '#262626'
                }
            }
        })
    },
    {
        name: 'DarkBlueDustyGreen',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#1f3249'
                },
                secondary: {
                    main: '#9fbb77'
                },
                text: {
                    primary: '#262626'
                }
            }
        })
    },
    {
        name: 'DarkBlueLightBrown',
        theme: createMuiTheme({
            palette: {
                primary: {
                    main: '#1f3249'
                },
                secondary: {
                    main: '#f3ecbc'
                },
                text: {
                    primary: '#262626'
                }
            }
        })
    }
]

//export const defaultTheme = themeList[0].theme
//export const defaultTheme = themeList[34].theme
//export const defaultTheme = themeList[37].theme
export const defaultTheme = themeList[39].theme