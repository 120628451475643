import {
    refreshCache,
} from 'actions/'
import { TimerObservable, ONE_MINUTE_MILLISECONDS } from 'common/observables'
import OnlineSignedInController from 'services/common/OnlineSignedInController'

export default class CacheRefreshController extends OnlineSignedInController {
    constructor(store) {
        super()

        this.store = store

        // Refresh the cache on a periodic basis.
        this.timerObservable = new TimerObservable(5 * ONE_MINUTE_MILLISECONDS)
        this.timerObservable.subscribe(() => {
            this.onUpdate()
        })
    }

    async onUpdate() {
        if (this.state.isSignedIn && this.state.isOnline) {
            this.timerObservable.resetTimer()

            await this.store.dispatch(refreshCache())
        }
    }
}