import React from 'react'
import { Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

const RecipeNotFound = () => {
    return (
        <Box margin={1}>
            <Alert severity="error">Recipe not found.</Alert>
        </Box>
    )
}

export default RecipeNotFound