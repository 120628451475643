import { onlineObservable } from 'common/observables'
import { 
    setOnlineStatus,
} from 'actions'

export default class OnlineListener {
    constructor(store) {
        this.store = store

        onlineObservable.subscribe(val => {
            this.store.dispatch(setOnlineStatus(val === 'online'))
        })
    }
}