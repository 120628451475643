import { db } from 'services/cache/CacheController'
import { getUsername } from 'services/common/user'

export default class RecipeCacheReader {
    async getAll() {
        let userId = await getUsername()
        return await db.recipes.where({ userId }).toArray()
    }

    async getById(id) {
        let userId = await getUsername()
        return await db.recipes
            .where({ id, userId })
            .first()
    }
}