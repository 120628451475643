import {
    SET_SORT_OPTIONS
} from './actions'
import { storeObservable } from 'common/observables'

const sortOptionsLocalStorageKey = 'sort-options'

export const setSortOptions = options => ({
    type: SET_SORT_OPTIONS,
    options
})

export const setSortOptionsThunk = options => {
    return dispatch => {
        dispatch(setSortOptions(options))
        localStorage.setItem(sortOptionsLocalStorageKey, JSON.stringify(options))
    }
}

export const loadSortOptionsThunk = () => {
    return dispatch => {
        try {
            const options = JSON.parse(localStorage.getItem(sortOptionsLocalStorageKey))
            dispatch(setSortOptions(options))
        } catch {
            // Fine, options have never been saved.
        }
    }
}

// Load the sort options once we get the store.
storeObservable.subscribe(store => {
    store != null && store.dispatch(loadSortOptionsThunk())
})