import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Color from 'color'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  Button,
  Typography
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import PolicyLink from './containers/PolicyLink'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: Color(theme.palette.primary.dark).alpha(0.9).rgb().string(),
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      // Increase padding to maintain banner size on larger displays.
      padding: theme.spacing(4)
    }
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(1)
  },
  button: {
    margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0`
  },
  manageButton: {
    color: theme.palette.primary.contrastText
  }
}))

const AcceptCookiesBanner = ({
  classes,
  open,
  disabled,
  acceptAllCookies,
  cookieSettingsUrl
}) => {
  const internalClasses = useStyles()

  return (
    !open || disabled ? null : (
      <Box className={clsx(internalClasses.root, classes && classes.root)}>
        <Typography variant='body1' color='inherit'>
          We use cookies to understand how you use ScranPlan.
          This allows us to improve the services we offer to you.
          See our <PolicyLink><span>Cookie Policy</span></PolicyLink>
          &nbsp;for more information.
        </Typography>
        <Box className={internalClasses.buttonContainer}>
          <Button
            className={internalClasses.button}
            variant='contained'
            color='secondary'
            onClick={acceptAllCookies}
          >
            Accept Cookies
          </Button>
          <Link
            to={cookieSettingsUrl}
          >
            <Button
              className={internalClasses.button}
              classes={{ root: internalClasses.manageButton }}
              variant='text'
            >
              Manage
            </Button>
          </Link>
        </Box>
      </Box>
    )
  )
}

AcceptCookiesBanner.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  acceptAllCookies: PropTypes.func.isRequired,
  cookieSettingsUrl: PropTypes.string.isRequired
}

export default AcceptCookiesBanner
