import React from 'react'
import SaveCancelButtons from 'components/common/SaveCancelButtons'

const RecipeFormButtons = ( { onSave, onCancel, formData, isDirty, isValid }) => {
    const onSaveButton = React.useCallback(() => {
        onSave(formData)
    }, [onSave, formData])

    return (
        <React.Fragment>
            <SaveCancelButtons
            onCancel={onCancel}
            onSave={onSaveButton}
            isDirty={isDirty && isValid}
            />

        </React.Fragment>
    )
}

export default RecipeFormButtons