import { Observable, Subject, BehaviorSubject } from 'rxjs'
import Auth from '@aws-amplify/auth'
import { Hub } from '@aws-amplify/core'
import log from 'loglevel'

export const ONE_MINUTE_MILLISECONDS = 60 * 1000

export const ONE_HOUR_MILLISECONDS = 60 * ONE_MINUTE_MILLISECONDS

/**
 * Provides the store as an observable.
 */
class StoreObservable {
    constructor() {
        this.subject = new BehaviorSubject(null)
        Observable.create(observer => this.observer = observer).subscribe(this.subject)
    }

    subscribe = fn => {
        return this.subject.subscribe(fn)
    }

    setStore(store) {
        this.observer.next(store)
    }
}

export const storeObservable = new StoreObservable()

export class TimerObservable {
    constructor(delayMilliseconds = ONE_MINUTE_MILLISECONDS) {
        this.delayMilliseconds = delayMilliseconds
        this.interval = null
        this.subject = new Subject()
        Observable.create(observer => this.observer = observer).subscribe(this.subject)

        this.resetTimer()
    }

    subscribe = fn => {
        return this.subject.subscribe(fn)
    }

    resetTimer = () => {
        if (this.interval != null) {
            clearInterval(this.interval)
        }
        this.interval = setInterval(() => this.observer.next('interval'), this.delayMilliseconds)
    }
}

class OnlineObservable {
    constructor() {
        // BehaviorSubject will notify first with its initial value.
        this.subject = new BehaviorSubject(navigator.onLine == null || navigator.onLine ? 'online' : 'offline')
        Observable.create(observer => this.observer = observer).subscribe(this.subject)

        window.addEventListener('online', () => this.observer.next('online'))
        window.addEventListener('offline', () => this.observer.next('offline'))
    }

    subscribe = fn => {
        return this.subject.subscribe(fn)
    }
}

export const onlineObservable = new OnlineObservable()

class InstallPromptObservable {
    constructor() {
        this.subject = new Subject()
        Observable.create(observer => this.observer = observer).subscribe(this.subject)

        window.addEventListener('beforeinstallprompt', (e) => { e.preventDefault(); this.observer.next(e) })
    }

    subscribe = fn => {
        return this.subject.subscribe(fn)
    }
}

export const installPromptObservable = new InstallPromptObservable()

class AuthObservable {
    constructor() {
        this.log = log.getLogger('AuthObservable')
        this.subject = new BehaviorSubject()
        Observable.create(observer => this.observer = observer).subscribe(this.subject)

        Hub.listen('auth', ({payload: { event, data }}) => {
            switch (event) {
                case 'configured':
                    // Auth configured, now determine initial state.
                    Auth.currentAuthenticatedUser()
                        .then(() => this.observer.next('signedIn'))
                        .catch(() => this.observer.next('signedOut'))
                    break
                case 'signIn':
                    this.observer.next('signedIn')
                    break
                case 'signOut':
                    this.observer.next('signedOut')
                    break
                case 'signUp':
                    this.observer.next('signedUp')
                    break
                case 'parsingCallbackUrl':
                    break
                default:
                    this.log.warn('Unknown auth event:', event, data)
                    break
            }
        })
    }

    subscribe = fn => {
        return this.subject.subscribe(fn)
    }
}

export const authObservable = new AuthObservable()

/**
 * Sends service worker 'controllerchange' events.
 */
class ControllerChangeObservable {
    constructor() {
        this.subject = new Subject()
        Observable.create(observer => this.observer = observer).subscribe(this.subject)

        'serviceWorker' in navigator && navigator.serviceWorker.getRegistration().then(registration => {
            if (registration != null) {
                navigator.serviceWorker.oncontrollerchange = () => this.observer.next('changed')
            }
        })
    }

    subscribe = fn => {
        return this.subject.subscribe(fn)
    }
}

export const controllerChangeObservable = new ControllerChangeObservable()

/**
 * Sends service worker waiting events.
 * 
 * When this event fires, the new service worker is in a safe state
 * to take control of the app. However, the app itself may not be in
 * a safe state, which must be checked separately.
 */
class ServiceWorkerWaitingObservable {
    constructor() {
        this.log = log.getLogger('ServiceWorkerWaitingObservable')
        this.subject = new Subject()
        Observable.create(observer => this.observer = observer).subscribe(this.subject)

        'serviceWorker' in navigator && navigator.serviceWorker.getRegistration().then(registration => {
            if (registration != null) {
                // In case there is already a service working waiting.
                registration.waiting != null && this.observer.next(registration)

                // Listen for service worker updates.
                registration.addEventListener('updatefound', () => {
                    if (registration.installing) {
                        // Wait for the service worker to transition into the waiting state.
                        registration.installing.addEventListener('statechange', () => {
                            if (registration.waiting != null) {
                                if (navigator.serviceWorker.controller) {
                                    // Another controller already exists and the new one is waiting.
                                    this.observer.next(registration)
                                } else {
                                    this.log.info('Service worker first time install.')
                                }
                            }
                        })
                    }
                })
            }
        })
    }

    subscribe = fn => {
        return this.subject.subscribe(fn)
    }
}

export const serviceWorkerWaitingObservable = new ServiceWorkerWaitingObservable()