import {
  SET_COOKIE_SETTINGS
} from '../actions/actions'
import { defaultCookieSettings } from '../cookieSettingsStorage'

const initialState = {
  ...defaultCookieSettings
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COOKIE_SETTINGS:
      return {
        ...state,
        ...action.settings
      }
    default:
      return state
  }
}

export default reducer
