import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
    ListItemIcon
} from '@material-ui/core'

const styles = {
    root: {
        // Makes more sense to inherit the color just like Typography.
        color: 'inherit'
    }
}

/**
 * Wraps MUI's ListItemIcon and sets color to 'inherit'.
 */
const CustomListItemIcon = ({ children, classes, className }) => {
    return (
        <ListItemIcon classes={{ root: classes.root }} className={className}>{children}</ListItemIcon>
    )
}

export default withStyles(styles)(CustomListItemIcon)