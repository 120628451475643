import { createRecipeThunk } from 'actions'
import { connect } from 'react-redux';
import NewRecipePage from '../NewRecipePage'

const mapStateToProps = state => ({
    allTags: state.entities.tags,
    recipes: state.entities.recipes
});

const mapDispatchToProps = dispatch => ({
    createNewRecipe: (recipe) => dispatch(createRecipeThunk(recipe)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewRecipePage);