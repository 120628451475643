import BaseQueryHandler from './BaseQueryHandler'
import HttpHelper from '../../common/HttpHelper'
import RecipeCacheReader from 'services/cache/RecipeCacheReader'
import RecipeCacheWriter from 'services/cache/RecipeCacheWriter'
import { Set as ImmutableSet } from 'immutable'

export default class GetAllRecipesQueryHandler extends BaseQueryHandler{
    constructor() {
        super()
        this.httpHelper = new HttpHelper()
        this.recipeCacheReader = new RecipeCacheReader()
        this.recipeCacheWriter = new RecipeCacheWriter()
    }

    async fetchCache() {
        return await this.recipeCacheReader.getAll()
    }

    async fetchHttp() {
        const response = await this.httpHelper.get('recipes')
        this.checkHttpResponse(response)
        return await response.json()
    }

    async updateCache(recipes, cachedRecipes) {
        const cachedRecipeIds = ImmutableSet((cachedRecipes || []).map(recipe => recipe.id))
        const recipeIdsToDelete = cachedRecipeIds.subtract((recipes || []).map(recipe => recipe.id))
        await this.recipeCacheWriter.bulkDelete(recipeIdsToDelete.toArray())
        await this.recipeCacheWriter.bulkPut(recipes)
    }
}

export const getAllRecipesQueryHandler = new GetAllRecipesQueryHandler()