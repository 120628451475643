import { onlineObservable, authObservable } from 'common/observables'

export default class OnlineSignedInController {
    constructor() {
        this.state = {
            isOnline: false,
            isSignedIn: false
        }

        onlineObservable.subscribe(val => {
            this.state = { ...this.state, isOnline: val === 'online'  }

            this.onUpdate()
        })
        
        authObservable.subscribe(val => {
            if (val === 'signedIn') {
                this.state = { ...this.state, isSignedIn: true  }
                this.onUpdate()
            } else if (val === 'signedOut') {
                this.state = { ...this.state, isSignedIn: false  }
                this.onUpdate()
            }
        })
    }

    /**
     * Override this to define what should happen when the
     * online/signed-in status changes.
     */
    async onUpdate() {
        throw Error('Function not implemented')
    }
}