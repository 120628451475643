import { connect } from 'react-redux';
import NavDrawer from '../NavDrawer'

const mapStateToProps = state => ({
    isOnline: state.app.isOnline,
    isInstallable: state.app.installPrompt != null,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavDrawer);