import { hash } from 'immutable'

class CaseInsensitiveString {
    constructor(value) {
        this.value = value
    }

    equals(other) {
        return other != null && (other instanceof CaseInsensitiveString) && other.value.toLowerCase() === this.value.toLowerCase()
    }

    hashCode() {
        return hash(this.value)
    }
}

export default CaseInsensitiveString