import { connect } from 'react-redux';
import Profile from '../Profile' 
import { setProfileEditing } from 'actions'

const mapStateToProps = state => ({
    profile: state.user.profile,
    isEditing: state.user.isEditingProfile
});

const mapDispatchToProps = dispatch => ({
    setProfileEditing: isEditing => dispatch(setProfileEditing(isEditing))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Profile);