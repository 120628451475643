import moment from 'moment';
import { Map as ImmutableMap } from 'immutable'
import {
    SET_PLANNER_WEEK,
    PREV_PLANNER_WEEK,
    NEXT_PLANNER_WEEK,
    DELETE_PLANNED_RECIPE,
    SELECT_RECIPE_FOR_DAY,
    SET_WEEK_START,
    SET_FULL_CALENDAR
} from '../actions/actions'
import { startOfWeek } from 'common/'

const initialState = {
    weekDate: startOfWeek(new Date(moment()), 0),
    weekStart: 0,
    recipeMap: ImmutableMap(),
};

const planner = (state = initialState, action) => {
    switch (action.type) {
        case SET_PLANNER_WEEK:
            return {...state, weekDate: startOfWeek(action.weekDate, state.weekStart)}
        case PREV_PLANNER_WEEK:
            return {...state, weekDate: new Date(moment(state.weekDate).add(-7, 'days'))}
        case NEXT_PLANNER_WEEK:
            return {...state, weekDate: new Date(moment(state.weekDate).add(7, 'days'))}
        case DELETE_PLANNED_RECIPE:
            return {...state, recipeMap: state.recipeMap.delete(action.stamp)}
        case SELECT_RECIPE_FOR_DAY:
            return {...state, recipeMap: state.recipeMap.set(action.stamp, action.recipeId)}
        case SET_WEEK_START:
            return { ...state, weekStart: action.weekStart, weekDate: startOfWeek(state.weekDate, action.weekStart) }
        case SET_FULL_CALENDAR:
            return {
                ...state,
                recipeMap: action.calendar.reduce((map, entry) => map.set(new Date(entry.date).getTime(), entry.recipeId), ImmutableMap())
            }
        default:
            return state;
    }
}

export default planner;