import React from 'react'
import {
    Checkbox,
    FormControl,
    FormControlLabel
} from '@material-ui/core'

const SubscribeToMarketingCheckbox = ({ checked, onChange }) => {
    return (
        <FormControl>
            <FormControlLabel
            label={
                <span>
                    Keep me updated on new feature releases
                </span>
            }
            control={
                <Checkbox
                id="keep-me-updated"
                color="primary"
                checked={ checked }
                name="subscribe_marketing"
                onChange={ onChange }
                />}
            />
        </FormControl>
    )
}

export default SubscribeToMarketingCheckbox