import moment from 'moment';
import momentTz from 'moment-timezone';
import { Seq } from 'immutable'

// Default timezome must be UTC.
momentTz.tz.setDefault('Etc/UTC')

export const startOfWeek = (date, weekStart = 0) => {
    const day = moment(date).day();
    if (weekStart <= day) {
        return new Date(moment(date).day(weekStart).startOf('day'));
    }
    return new Date(moment(date).day(weekStart - 7).startOf('day'));
};

export const getSuggestions = (value, tags, currentTags) => {
  const valueLowerCase = value.toLowerCase()
  return Seq(tags.subtract(currentTags))
    .map(tag => tag.value)
    .filter(tag => tag.toLowerCase().includes(valueLowerCase))
    .take(5).toArray()
}
