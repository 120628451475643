import BookmarkCacheWriter from 'services/cache/BookmarkCacheWriter'
import commandScheduler from 'services/api/commands/commandScheduler'

export default class BookmarkRecipeCommandHandler {
    constructor() {
        this.bookmarkCacheWriter = new BookmarkCacheWriter()
    }

    /**
     * Bookmark the recipe.
     * 
     * @param {string} recipeId the ID of the recipe to bookmark.
     */
    async execute(recipeId) {
        await this.bookmarkCacheWriter.bookmark(recipeId)
        commandScheduler.bookmarkRecipe(recipeId)
    }
}

export const bookmarkRecipeCommandHandler = new BookmarkRecipeCommandHandler()