import React from 'react'
import moment from 'moment'
import { PickRecipePageAppBarMenu } from './PickRecipePageAppBarMenu'
import AppBar from 'components/common/containers/AppBar'
import RecipesBody from './containers/RecipesBody'
import ReactGA from 'react-ga4'

export class PickRecipePage extends React.Component {
    getStamp = () => parseInt(this.props.match.params.stamp, 10)

    goBack = () => {
        this.props.history.goBack()
    }

    onSelectRecipe = (recipe) => {
        const stamp = this.getStamp()
        const origRecipe = this.props.recipes[this.props.recipeMap.get(stamp)]
        if (origRecipe != null) {
            this.props.deletePlannedRecipe(this.getStamp(), origRecipe)
        }
        this.props.selectRecipeForDay(this.getStamp(), recipe)
        this.props.history.goBack()
        ReactGA.event({
            category: 'Calendar',
            action: 'End Plan Day',
            label: stamp.toString(),
        })
    }

    render() {
        const dateString = moment(parseInt(this.getStamp(), 10)).format(
            'ddd Do'
        )
        const title = `Pick recipe (${dateString})`

        return (
            <div>
                <AppBar
                    returnable
                    title={title}
                    rightMenu={<PickRecipePageAppBarMenu />}
                />
                <RecipesBody
                    enableAddButton
                    onSelectRecipe={this.onSelectRecipe}
                />
            </div>
        )
    }
}
