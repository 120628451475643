import React from 'react'
import PropTypes from 'prop-types'
import {
    makeStyles
} from '@material-ui/core/styles'
import {
    Box,
    Button,
    Paper,
    Typography
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText
    }
}))

const AppInstallCta = ({ showInstallPrompt, onClickedCta }) => {
    const classes = useStyles()

    const onClickInstall = React.useCallback(() => {
        showInstallPrompt()
        onClickedCta()
    }, [showInstallPrompt, onClickedCta])

    return (
        <Paper classes={{ root: classes.root }}>
            <Typography variant='body2'>Use this app offline</Typography>
            <Typography variant='body2'>Doesn't take any space</Typography>
            <Box
                display='flex'
                justifyContent='center'
                margin={1}
            >
                <Button
                    variant='outlined'
                    color='secondary'
                    onClick={onClickInstall}
                >
                    Install
                </Button>
            </Box>
        </Paper>
    )
}

AppInstallCta.propTypes = {
    showInstallPrompt: PropTypes.func.isRequired,
    onClickedCta: PropTypes.func.isRequired
}

export default AppInstallCta