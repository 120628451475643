import {
    SET_IS_DRAGGING
} from '../actions/actions'

const initialState = {
    isDragging: false
};

export const recipeList = (state = initialState, action) => {
    switch (action.type) {
        case SET_IS_DRAGGING:
            return {
                ...state,
                isDragging: action.isDragging
            }
        default:
            return state;
    }
}
