import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper
} from '@material-ui/core';
import FormEdit from 'components/common/FormEdit'
import RecipeForm, { validationSchema } from './RecipeForm'
import RecipeFormButtons from './RecipeFormButtons'
import RecipeFormDto from './RecipeFormDto'

const useStyles = makeStyles(theme => ({
    paper: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    status:{
        marginTop: theme.spacing(2),
    },
}));

const RecipeFormPage = ({ isDisabled, onCancel, onSave, recipe, allTags, showDialog, onCloseDialog }) => {
    const classes = useStyles();

    const formData = React.useMemo(() => new RecipeFormDto(recipe), [recipe])

    return (
        <Paper className={classes.paper}>
            <Dialog
                aria-labelledby='recipe-error-dialog'
                onClose={onCloseDialog}
                open={showDialog}
            >
                <DialogTitle id='recipe-error-dialog'>
                    Recipe already exists
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Change the recipe title or edit the existing recipe.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        color='primary'
                        autoFocus
                        onClick={onCloseDialog}
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Chip
            className={classes.status}
            size="small"
            color={formData.isPersonal ? 'primary' : 'secondary'}
            label={formData.isPersonal ? 'Personal' : 'ScranPlan'}
            />
            {
                isDisabled ?
                <RecipeForm formData={ formData } isDisabled /> :   
                (<FormEdit formData={ formData } validationSchema={ validationSchema }>
                    <RecipeForm allTags={ allTags } />
                    <RecipeFormButtons
                    onSave={onSave}
                    onCancel={onCancel}
                    />
                </FormEdit>)
            }
        </Paper>
    );
}

export default RecipeFormPage;