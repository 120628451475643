import { db } from 'services/cache/CacheController'
import { getUsername } from 'services/common/user'

export default class RecipeCacheWriter {
    async withUserId() {
        let userId = await getUsername()
        return recipe => ({ ...recipe, userId })
    }

    async add(recipe) {
        await db.transaction('rw', db.recipes, async () => {
            await db.recipes.add((await this.withUserId())(recipe))
        })
    }

    async update(recipe) {
        let userId = await getUsername()
        await db.transaction('rw', db.recipes, async () => {
            await db.recipes.update([ recipe.id, userId ], recipe)
        })
    }

    /**
     * Create or overwrite into the cache.
     * @param {*} recipes 
     */
    async bulkPut(recipes) {
        await db.transaction('rw', db.recipes, async () => {
            await db.recipes.bulkPut(recipes.map(await this.withUserId()))
        })
    }

    async bulkDelete(recipeIds) {
        let userId = await getUsername()
        await db.transaction('rw', db.recipes, async () => {
            await db.recipes.bulkDelete(recipeIds.map(recipeId => [ recipeId, userId ]))
        })
    }
}