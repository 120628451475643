import PlanCacheWriter from 'services/cache/PlanCacheWriter'
import commandScheduler from 'services/api/commands/commandScheduler'

export default class DeletePlannedRecipeCommandHandler {
    constructor() {
        this.planCacheWriter = new PlanCacheWriter()
    }

    /**
     * Delete the planned recipe on the given date.
     * 
     * @param {date} date the date of the plan
     * @param {string} recipeId the recipe ID toe delete
     */
    async execute(date, recipeId) {
        await this.planCacheWriter.deletePlannedRecipe(date, recipeId)
        commandScheduler.deletePlannedRecipe(date, recipeId)
    }
}

export const deletePlannedRecipeCommandHandler = new DeletePlannedRecipeCommandHandler()