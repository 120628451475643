import {
    SET_SORT_OPTIONS
} from '../actions/actions'

const initialState = {
    sortByPlanCount: true
};

export const sortOptions = (state = initialState, action) => {
    switch (action.type) {
        case SET_SORT_OPTIONS:
            return {
                ...action.options
            }
        default:
            return state;
    }
}
