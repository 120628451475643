import React from 'react'
import { SignIn } from 'aws-amplify-react'
import { Box, Button, Link, TextField, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { object, string } from 'yup'
import { checkErrors } from './common'
import CenterWrapper from 'components/common/CenterWrapper'
import logo from 'images/scran-plan-2.png'
import background from 'images/fruit-salads-backing-3.jpg'
import ReactGA from 'react-ga4'

const REQUIRED_FIELD_MESSAGE = 'This is a required field.'
const INVALID_EMAIL_MESSAGE = 'This must be a valid email.'

const styles = (theme) => ({
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        color: theme.palette.primary.main,
    },
    button: {
        marginTop: theme.spacing(2),
    },
    createAccount: {
        marginTop: theme.spacing(4),
    },
    logo: {
        maxWidth: '33%',
    },
    background: {
        backgroundImage: `url(${background})`,
    },
})

class CustomSignIn extends SignIn {
    constructor(props) {
        super(props)
        this.classes = props.classes
        this._validAuthStates = ['signIn', 'signedOut', 'signedUp']

        // Yup validation schema.
        this.validationSchema = object().shape({
            email: string()
                .email(INVALID_EMAIL_MESSAGE)
                .required(REQUIRED_FIELD_MESSAGE),
            password: string().required(REQUIRED_FIELD_MESSAGE),
        })

        this.state = {
            form: {
                email: '',
                password: '',
            },
            error: {},
        }
    }

    signIn = () => {
        const error = checkErrors(this.state.form, this.validationSchema)
        this.setState({ error })

        // Hack: autofill does not generate a change event, hence we need to
        // manually trigger input changes with the base class for all relevant
        // inputs.
        this.handleInputChange({
            target: {
                name: 'email',
                value: this.state.form.email,
                type: 'text',
            },
        })
        this.handleInputChange({
            target: {
                name: 'password',
                value: this.state.form.password,
                type: 'text',
            },
        })

        if (Object.keys(error).length === 0) {
            super.signIn()
            ReactGA.event({
                category: 'Sign In',
                action: 'Click Sign In',
            })
        }
    }

    onClickResetPassword = () => {
        super.changeState('forgotPassword')
        ReactGA.event({
            category: 'Sign In',
            action: 'Click Forgot Password',
        })
    }

    onClickCreateAccount = () => {
        super.changeState('signUp')
        ReactGA.event({
            category: 'Sign In',
            action: 'Click Sign Up',
        })
    }

    onChange = (e) => {
        const form = { ...this.state.form, [e.target.name]: e.target.value }
        const error = checkErrors(form, this.validationSchema)
        this.setState({ form, error })
        this.handleInputChange(e)
    }

    showComponent(theme) {
        return (
            <CenterWrapper>
                <Box className={this.classes.background}>
                    <Box padding={2}>
                        <Box textAlign="center">
                            <img
                                src={logo}
                                alt="logo"
                                className={this.classes.logo}
                            />
                        </Box>
                        <Box display="flex" flexDirection="column" padding={1}>
                            <Typography
                                className={this.classes.title}
                                variant="h3"
                                align="center"
                            >
                                ScranPlan
                            </Typography>
                            <Typography color="textPrimary">
                                Sign into your account
                            </Typography>
                            <TextField
                                margin="normal"
                                id="email"
                                name="email"
                                label="Email"
                                type="email"
                                variant="outlined"
                                autoComplete="email"
                                value={this.state.form.email}
                                onChange={this.onChange}
                                error={
                                    this.state.error &&
                                    this.state.error.email != null
                                }
                                helperText={
                                    this.state.error && this.state.error.email
                                }
                            />
                            <TextField
                                margin="normal"
                                type="password"
                                id="password"
                                name="password"
                                label="Password"
                                variant="outlined"
                                autoComplete="current-password"
                                value={this.state.form.password}
                                onChange={this.onChange}
                                error={
                                    this.state.error &&
                                    this.state.error.password != null
                                }
                                helperText={
                                    this.state.error &&
                                    this.state.error.password
                                }
                            />
                            <Typography
                                variant="subtitle2"
                                color="textSecondary"
                            >
                                Forgot your password?{' '}
                                <Link
                                    href="#"
                                    color="primary"
                                    onClick={this.onClickResetPassword}
                                >
                                    Reset password
                                </Link>
                            </Typography>
                            <Button
                                className={this.classes.button}
                                variant="contained"
                                color="primary"
                                onClick={this.signIn}
                            >
                                Sign In
                            </Button>

                            <Typography
                                className={this.classes.createAccount}
                                align="center"
                                variant="subtitle2"
                                color="textSecondary"
                            >
                                No account?{' '}
                                <Link
                                    href="#"
                                    color="primary"
                                    onClick={this.onClickCreateAccount}
                                >
                                    Create account
                                </Link>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </CenterWrapper>
        )
    }
}

export default withStyles(styles)(CustomSignIn)
