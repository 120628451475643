import HttpHelper from '../../common/HttpHelper'

export default class AcceptNewTermsCommandHandler {
    constructor() {
        this.httpHelper = new HttpHelper()
    }

    async execute(termsId) {
        await this.httpHelper.put('terms/accept', { termsId })
    }
}

export const acceptNewTermsCommandHandler = new AcceptNewTermsCommandHandler()