import SettingsCacheWriter from 'services/cache/SettingsCacheWriter'
import commandScheduler from 'services/api/commands/commandScheduler'

export default class EditSettingsCommandHandler {
    constructor() {
        this.settingsCacheWriter = new SettingsCacheWriter()
    }

    async execute(settings) {
        await this.settingsCacheWriter.update(settings)
        await commandScheduler.editSettings(settings)
    }
}

export const editSettingsCommandHandler = new EditSettingsCommandHandler()