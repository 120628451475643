import { connect } from 'react-redux';
import AppMux from '../AppMux'
import {
    registerAppMuxPages,
    pushAppMuxPage,
} from 'actions'

const mapStateToProps = state => ({
    currentPageName: state.app.appMux.pageQueue.last() ? state.app.appMux.pageQueue.last().name : null,
});

const mapDispatchToProps = dispatch => ({
    registerPages: pages => dispatch(registerAppMuxPages(pages)),
    pushAppMuxPage: pageName => dispatch(pushAppMuxPage(pageName)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppMux);