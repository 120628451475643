import React from 'react';
import AppBar from 'components/common/AppBar';
import RecipeFormPage from '../editor/RecipeFormPage';

const NewRecipePage = ({ history, createNewRecipe, allTags, recipes }) => {
    const [showErrorDialog, setShowError] = React.useState(false)
    
    const onCloseErrorDialog = React.useCallback(() => {
        setShowError(false)
    }, [setShowError])
    
    const goBack = React.useCallback(() => {
        history.goBack();
    }, [history]);

    const onSave = React.useCallback(recipe => {
        if (Object.values(recipes).find(r => r.title.trim().toLowerCase() === recipe.title.trim().toLowerCase()) != null) {
            setShowError(true)
            return
        }
        createNewRecipe(recipe);
        goBack();
    }, [createNewRecipe, goBack, setShowError, recipes]);

    return (
        <div>
            <AppBar
            returnable
            title="New recipe"/>
            <RecipeFormPage
                onCancel={goBack}
                onSave={onSave}
                allTags={allTags}
                showDialog={showErrorDialog}
                onCloseDialog={onCloseErrorDialog}
            />
        </div>
    );
};

export default NewRecipePage;