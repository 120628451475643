import { db } from 'services/cache/CacheController'
import { getUsername } from 'services/common/user'

export default class SettingsCacheWriter {
    /**
     * Merge into the cache.
     * @param {*} settings 
     */
    async update(settings) {
        let username = await getUsername()
        await db.transaction('rw', db.settings, async () => {
            await db.settings.update(username, settings)
        })
    }

    /**
     * Create or overwrite into the cache.
     * @param {*} settings 
     */
    async put(settings) {
        let username = await getUsername()
        await db.transaction('rw', db.settings, async () => {
            await db.settings.put({ userId: username, ...settings })
        })
    }
}