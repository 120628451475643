import React from 'react'
import {
    Box,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core'
import ReactGA from 'react-ga4'
import { Map as ImmutableMap } from 'immutable'
import { themeList } from 'components/app/themes'
import SubscribeToMarketingCheckbox from 'components/auth/SubscribeToMarketingCheckbox'

const weekdayMap = ImmutableMap([
    [0, 'Sunday'],
    [1, 'Monday'],
    [2, 'Tuesday'],
    [3, 'Wednesday'],
    [4, 'Thursday'],
    [5, 'Friday'],
    [6, 'Saturday'],
])

const SettingsBody = ({
    weekStart,
    subscribedToMarketing,
    setWeekStart,
    themeConfig,
    setThemeConfig,
    setSubscribedToMarketing,
}) => {
    const onWeekStartChange = React.useCallback(
        (event) => {
            const value = event.target.value
            setWeekStart(value)
            ReactGA.event({
                category: 'Settings',
                action: 'Set Week Start',
                label: weekdayMap.get(value),
            })
        },
        [setWeekStart]
    )

    const onSubscribedToMarketingChange = React.useCallback(
        (event) => {
            const value = event.target.checked
            setSubscribedToMarketing(value)
            ReactGA.event({
                category: 'Settings',
                action: value
                    ? 'Subscribed to Marketing'
                    : 'Unsubscribed from Marketing',
            })
        },
        [setSubscribedToMarketing]
    )

    const onThemeChange = React.useCallback(
        (event) => {
            setThemeConfig({
                index: event.target.value,
                theme: themeList[event.target.value].theme,
            })
        },
        [setThemeConfig]
    )

    return (
        <React.Fragment>
            <FormControl fullWidth>
                <InputLabel htmlFor="week-start">Week Start</InputLabel>
                <Select
                    value={weekStart}
                    onChange={onWeekStartChange}
                    inputProps={{
                        name: 'week-start',
                        id: 'week-start',
                    }}
                >
                    {weekdayMap.toArray().map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                            {value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <SubscribeToMarketingCheckbox
                checked={subscribedToMarketing}
                onChange={onSubscribedToMarketingChange}
            />
            <Divider />
            <FormControl fullWidth>
                <InputLabel htmlFor="theme">Theme</InputLabel>
                <Select
                    value={themeConfig.index}
                    onChange={onThemeChange}
                    inputProps={{
                        name: 'theme',
                        id: 'theme',
                    }}
                >
                    {themeList.map((item, index) => (
                        <MenuItem key={index} value={index}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Box display="flex" flexDirection="row">
                <Box flexBasis="50%">
                    <Box
                        bgcolor={themeConfig.theme.palette.primary.light}
                        color={themeConfig.theme.palette.primary.contrastText}
                        padding="8px"
                    >
                        <Typography>Primary Light</Typography>
                    </Box>
                    <Box
                        bgcolor={themeConfig.theme.palette.primary.main}
                        color={themeConfig.theme.palette.primary.contrastText}
                        padding="8px"
                    >
                        <Typography>Primary Main</Typography>
                    </Box>
                    <Box
                        bgcolor={themeConfig.theme.palette.primary.dark}
                        color={themeConfig.theme.palette.primary.contrastText}
                        padding="8px"
                    >
                        <Typography>Primary Dark</Typography>
                    </Box>
                </Box>
                <Box flexBasis="50%">
                    <Box
                        bgcolor={themeConfig.theme.palette.secondary.light}
                        color={themeConfig.theme.palette.secondary.contrastText}
                        padding="8px"
                    >
                        <Typography>Secondary Light</Typography>
                    </Box>
                    <Box
                        bgcolor={themeConfig.theme.palette.secondary.main}
                        color={themeConfig.theme.palette.secondary.contrastText}
                        padding="8px"
                    >
                        <Typography>Secondary Main</Typography>
                    </Box>
                    <Box
                        bgcolor={themeConfig.theme.palette.secondary.dark}
                        color={themeConfig.theme.palette.secondary.contrastText}
                        padding="8px"
                    >
                        <Typography>Secondary Dark</Typography>
                    </Box>
                </Box>
            </Box>
            <Box display="flex" flexDirection="row">
                <Box flexBasis="25%">
                    <Box
                        bgcolor={themeConfig.theme.palette.info.light}
                        color={themeConfig.theme.palette.info.contrastText}
                        padding="8px"
                    >
                        <Typography>Info Light</Typography>
                    </Box>
                    <Box
                        bgcolor={themeConfig.theme.palette.info.main}
                        color={themeConfig.theme.palette.info.contrastText}
                        padding="8px"
                    >
                        <Typography>Info Main</Typography>
                    </Box>
                    <Box
                        bgcolor={themeConfig.theme.palette.info.dark}
                        color={themeConfig.theme.palette.info.contrastText}
                        padding="8px"
                    >
                        <Typography>Info Dark</Typography>
                    </Box>
                </Box>
                <Box flexBasis="25%">
                    <Box
                        bgcolor={themeConfig.theme.palette.success.light}
                        color={themeConfig.theme.palette.success.contrastText}
                        padding="8px"
                    >
                        <Typography>Success Light</Typography>
                    </Box>
                    <Box
                        bgcolor={themeConfig.theme.palette.success.main}
                        color={themeConfig.theme.palette.success.contrastText}
                        padding="8px"
                    >
                        <Typography>Success Main</Typography>
                    </Box>
                    <Box
                        bgcolor={themeConfig.theme.palette.success.dark}
                        color={themeConfig.theme.palette.success.contrastText}
                        padding="8px"
                    >
                        <Typography>Success Dark</Typography>
                    </Box>
                </Box>
                <Box flexBasis="25%">
                    <Box
                        bgcolor={themeConfig.theme.palette.warning.light}
                        color={themeConfig.theme.palette.warning.contrastText}
                        padding="8px"
                    >
                        <Typography>Warning Light</Typography>
                    </Box>
                    <Box
                        bgcolor={themeConfig.theme.palette.warning.main}
                        color={themeConfig.theme.palette.warning.contrastText}
                        padding="8px"
                    >
                        <Typography>Warning Main</Typography>
                    </Box>
                    <Box
                        bgcolor={themeConfig.theme.palette.warning.dark}
                        color={themeConfig.theme.palette.warning.contrastText}
                        padding="8px"
                    >
                        <Typography>Warning Dark</Typography>
                    </Box>
                </Box>
                <Box flexBasis="25%">
                    <Box
                        bgcolor={themeConfig.theme.palette.error.light}
                        color={themeConfig.theme.palette.error.contrastText}
                        padding="8px"
                    >
                        <Typography>Error Light</Typography>
                    </Box>
                    <Box
                        bgcolor={themeConfig.theme.palette.error.main}
                        color={themeConfig.theme.palette.error.contrastText}
                        padding="8px"
                    >
                        <Typography>Error Main</Typography>
                    </Box>
                    <Box
                        bgcolor={themeConfig.theme.palette.error.dark}
                        color={themeConfig.theme.palette.error.contrastText}
                        padding="8px"
                    >
                        <Typography>Error Dark</Typography>
                    </Box>
                </Box>
            </Box>
            <Box display="flex" flexDirection="row">
                <Box
                    bgcolor={themeConfig.theme.palette.background.paper}
                    color={themeConfig.theme.palette.text.primary}
                    padding="8px"
                    flexGrow="1"
                >
                    <Typography>Text Primary</Typography>
                </Box>
                <Box
                    bgcolor={themeConfig.theme.palette.background.paper}
                    color={themeConfig.theme.palette.text.secondary}
                    padding="8px"
                    flexGrow="1"
                >
                    <Typography>Text Secondary</Typography>
                </Box>
                <Box
                    bgcolor={themeConfig.theme.palette.background.paper}
                    color={themeConfig.theme.palette.text.disabled}
                    padding="8px"
                    flexGrow="1"
                >
                    <Typography>Text Disabled</Typography>
                </Box>
            </Box>
        </React.Fragment>
    )
}

export default SettingsBody
