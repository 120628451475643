import React from 'react'
import { Dialog, DialogContent, DialogContentText, CircularProgress } from '@material-ui/core'

const BusyModal = ( { open, message } ) => {
    return (
        <Dialog
        open={ open }
        aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    { message }
                </DialogContentText>
                <CircularProgress color="inherit" size={20} />
            </DialogContent>
        </Dialog>
    )
}

export default BusyModal