import { connect } from 'react-redux'
import SearchableRecipeList from '../SearchableRecipeList'

const mapStateToProps = state => ({
    recipes: state.entities.recipes,
    tags: state.entities.tags,
    bookmarks: state.user.bookmarks,
    plans: state.planner.recipeMap,
    sortByPlanCount: state.sortOptions.sortByPlanCount
});

export default connect(
    mapStateToProps,
)(SearchableRecipeList)