import React from 'react'
import { object, string } from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import { Link, TextField } from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'
import ReactAutosuggest from 'common/ReactAutosuggest'
import CaseInsensitiveString from 'common/CaseInsensitiveString'
import { getSuggestions } from 'common'

const useStyles = makeStyles((theme) => ({
    tags: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}))

/**
 * Makes a dummy event with just a target with name & value
 * properties. This is really a hack to make add/delete chips
 * work with the onChange callback in FormEdit.
 *
 * A better way is to change the FormEdit onChange callback to
 * accept name & value parameters directly instead of expecting
 * an event. This way, onChange should never access an event directly.
 * @param {string} name the name of the property
 * @param {any} value the value of the property
 */
const makeDummyEvent = (name, value) => ({
    target: {
        name,
        value,
    },
})

const REQUIRED_FIELD_MESSAGE = 'This is a required field.'
const INVALID_URL_MESSAGE = 'This must be a valid URL.'

export const validationSchema = object().shape({
    title: string().required(REQUIRED_FIELD_MESSAGE),
    uri: string().url(INVALID_URL_MESSAGE),
})

const RecipeForm = ({ isDisabled, formData, error, onChange, allTags }) => {
    const classes = useStyles()

    const onGetSuggestions = React.useCallback(
        (value) => {
            return getSuggestions(value, allTags, formData.tags)
        },
        [allTags, formData]
    )

    const onAddChip = React.useCallback(
        (chip) => {
            const event = makeDummyEvent(
                'tags',
                formData.tags.add(new CaseInsensitiveString(chip))
            )
            onChange(event)
        },
        [onChange, formData]
    )

    const onDeleteChip = React.useCallback(
        (chip) => {
            const event = makeDummyEvent(
                'tags',
                formData.tags.delete(new CaseInsensitiveString(chip))
            )
            onChange(event)
        },
        [onChange, formData]
    )

    return (
        <React.Fragment>
            <TextField
                disabled={isDisabled}
                id="recipe-title"
                label="Title"
                name="title"
                fullWidth
                variant="outlined"
                margin="normal"
                value={formData.title}
                onChange={onChange}
                error={error != null && error.title != null}
                helperText={error != null ? error.title : undefined}
            />
            <TextField
                disabled={isDisabled}
                id="recipe-uri"
                label="URI"
                name="uri"
                fullWidth
                variant="outlined"
                margin="normal"
                InputProps={{
                    endAdornment:
                        !isDisabled || formData.uri === '' ? null : (
                            <Link
                                href={formData.uri}
                                target="_blank"
                                rel="noopener"
                            >
                                <LinkIcon />
                            </Link>
                        ),
                }}
                value={formData.uri}
                onChange={onChange}
                error={error && error.uri != null}
                helperText={error && error.uri}
            />
            <ReactAutosuggest
                disabled={isDisabled}
                className={classes.tags}
                fullWidth
                variant="outlined"
                label="Tags"
                placeholder="New tag"
                chips={formData.tags.toArray().map((tag) => tag.value)}
                onAddChip={onAddChip}
                onDeleteChip={onDeleteChip}
                onGetSuggestions={onGetSuggestions}
            />
            <TextField
                disabled={isDisabled}
                id="recipe-notes"
                label="Notes"
                name="notes"
                fullWidth
                variant="outlined"
                margin="dense"
                multiline
                rows="4"
                rowsMax="10"
                value={formData.notes}
                onChange={onChange}
            />
        </React.Fragment>
    )
}

export default RecipeForm
