import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom';
import { Box } from '@material-ui/core'
import RecipeCard from './RecipeCard'
import DraggableCard from 'components/common/DraggableCard'
import { recipeDragChannel } from 'common/dragChannels'

const useStyles = makeStyles(theme => ({
    backingCard: {
        backgroundColor: '#eeeeff'
    }
}))

const RecipeCardDraggable = ({
    history,
    recipe,
    onSelectRecipe,
    enableAddButton,
    enableDrag,
    onDragStart,
    onDragStop,
}) => {
    const classes = useStyles()

    const [isDisableClick, setIsDisableClick] = React.useState(false)

    const onBeforeTouchStart = React.useCallback(() => {
        setIsDisableClick(false)
    }, [setIsDisableClick])

    const onBeforeMouseDown = React.useCallback(() => {
        setIsDisableClick(false)
    }, [setIsDisableClick])

    const onDragStartWrapper = React.useCallback(() => {
        setIsDisableClick(true)
        onDragStart && onDragStart()
    }, [onDragStart, setIsDisableClick])

    return (
        <Box
        marginBottom="2px"
        marginRight="2px"
        >
            <DraggableCard
            enableDrag={enableDrag}
            onDragStart={onDragStartWrapper}
            onDragStop={onDragStop}
            dropItem={recipe}
            backingCardClassName={classes.backingCard}
            dragChannel={recipeDragChannel}
            onBeforeTouchStart={onBeforeTouchStart}
            onBeforeMouseDown={onBeforeMouseDown}
            >
                <RecipeCard
                history={history}
                recipe={recipe}
                onSelectRecipe={onSelectRecipe}
                enableAddButton={enableAddButton}
                disableClick={isDisableClick}
                />
            </DraggableCard>
        </Box>
    )
}

export default withRouter(RecipeCardDraggable)