import React from 'react'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Chip } from '@material-ui/core'
import ChipInput from 'material-ui-chip-input'

const useChipStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  }
}))

const useChipInputStyles = makeStyles(theme => ({
  contrastTextLabel: {
    color: theme.palette.primary.contrastText,
    '&.Mui-focused': {
      color: theme.palette.primary.contrastText,
    }
  },
  contrastTextInputRoot: {
    color: theme.palette.primary.contrastText,
    '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.contrastText,
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.contrastText,
    }
  }
}))


const CustomChipInput = React.forwardRef((props, ref) => {
  const chipClasses = useChipStyles()
  const chipInputClasses = useChipInputStyles()
  const { value, onChange, chips, enableContrastText, ...other } = props

  return (
    <ChipInput
      classes={ enableContrastText ? { label: chipInputClasses.contrastTextLabel, inputRoot: chipInputClasses.contrastTextInputRoot } : null }
      clearInputValueOnChange
      onUpdateInput={onChange}
      value={chips}
      inputRef={ref}
      chipRenderer={({ value, text, chip, isFocused, isDisabled, isReadOnly, handleClick, handleDelete, className }, key) => (
        <Chip classes={{ root: chipClasses.root }} label={text} className={className} onDelete={isDisabled || isReadOnly ? null : handleDelete} key={key}></Chip>
      )}
      {...other}
    />
  )
})

function renderInput(props) {
  const { ref, ...actualProps } = props
  return (
    <CustomChipInput {...actualProps} ref={ref} />
  )
}

function renderSuggestion (suggestion, { query, isHighlighted }) {
  const matches = match(suggestion, query)
  const parts = parse(suggestion, matches)
  return (
    <MenuItem
      selected={isHighlighted}
      component='div'
      onMouseDown={(e) => e.preventDefault()} // prevent the click causing the input to be blurred
    >
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <span key={String(index)}>
              {part.text}
            </span>
          )
        })}
      </div>
    </MenuItem>
  )
}

function renderSuggestionsContainer (options) {
  const { containerProps, children } = options
  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  )
}

function getSuggestionValue (suggestion) {
  return suggestion
}

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    left: 0,
    right: 0
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  textField: {
    width: '100%'
  }
})

class ReactAutosuggest extends React.Component {
  state = {
    // value: '',
    suggestions: [],
    textFieldInput: ''
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.props.onGetSuggestions(value)
    })
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  };

  handletextFieldInputChange = (event, { newValue }) => {
    this.setState({
      textFieldInput: newValue
    })
  };

  handleAddChip (chip) {
    this.props.onAddChip(chip)
    this.setState(({
      textFieldInput: ''
    }))
  }

  handleDeleteChip (chip, index) {
    this.props.onDeleteChip(chip, index)
  };

  render () {
    const { classes, chips, onAddChip, onDeleteChip, onGetSuggestions, ...other } = this.props

    return (
      <Autosuggest
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        renderInputComponent={renderInput}
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestionsContainer={renderSuggestionsContainer}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={(e, { suggestionValue }) => { this.handleAddChip(suggestionValue); e.preventDefault() }}
        focusInputOnSuggestionClick={true}
        inputProps={{
          chips,
          value: this.state.textFieldInput,
          onChange: this.handletextFieldInputChange,
          onAdd: (chip) => {this.handleAddChip(chip)},
          onDelete: (chip, index) => this.handleDeleteChip(chip, index),
          ...other
        }}
      />
    )
  }
}

ReactAutosuggest.propTypes = {
  allowDuplicates: PropTypes.bool,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ReactAutosuggest)