import Auth from '@aws-amplify/auth'
import AuthorizationException from '../exceptions/AuthorizationException'

const apiRoot = process.env.REACT_APP_API_ROOT

const defaultConfig = {
    body: null,
    method: 'get',
    isAnonymous: false,
}

export default class HttpHelper {
    async _fetch(urlPath, config = defaultConfig) {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        if (!config.isAnonymous) {
            try {
                let session = await Auth.currentSession()
                headers['Authorization'] = `Bearer ${session.accessToken.jwtToken}`
            } catch(e) {
                throw new AuthorizationException(e)
            }
        }
        
        const response = await fetch(`${apiRoot}/${urlPath}`, {
            method: config.method,
            headers,
            body: config.body != null ? JSON.stringify(config.body) : null,
        })
        return response
    }

    async get(urlPath, isAnonymous = false) {
        return await this._fetch(urlPath, { ...defaultConfig, isAnonymous } )
    }

    async put(urlPath, data, isAnonymous = false) {
        return await this._fetch(urlPath, { ...defaultConfig, body: data, method: 'put', isAnonymous })
    }

    async post(urlPath, data, isAnonymous = false) {
        return await this._fetch(urlPath, { ...defaultConfig, body: data, method: 'post', isAnonymous })
    }

    async delete(urlPath, data, isAnonymous = false) {
        return await this._fetch(urlPath, { ...defaultConfig, body: data, method: 'delete', isAnonymous })
    }
}