import React from 'react'
import { SignUp } from 'aws-amplify-react'
import {
    Box,
    Button,
    CircularProgress,
    Link,
    TextField,
    Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'
import { boolean, object, string } from 'yup'
import { checkErrors } from './common'
import AcceptTermsCheckbox from './AcceptTermsCheckbox'
import TermsAndConditionsDialog from './TermsAndConditionsDialog'
import CenterWrapper from 'components/common/CenterWrapper'
import SubscribeToMarketingCheckbox from './SubscribeToMarketingCheckbox'
import ReactGA from 'react-ga4'

const REQUIRED_FIELD_MESSAGE = 'This is a required field.'
const INVALID_EMAIL_MESSAGE = 'This must be a valid email.'
const PASSWORD_REQUIREMENTS_MESSAGE =
    'Minimum 10 characters, at least one uppercase letter, one lowercase letter and one number.'
const ACCEPT_TERMS_MESSAGE =
    'You must accept the terms and conditions to create an account.'

const styles = (theme) => ({
    title: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    button: {
        marginTop: theme.spacing(2),
    },
    createAccount: {
        marginTop: theme.spacing(4),
    },
})

class CustomSignUp extends SignUp {
    constructor(props) {
        super(props)
        this.classes = props.classes
        this._validAuthStates = ['signUp']

        // Yup validation schema.
        this.validationSchema = object().shape({
            family_name: string().required(REQUIRED_FIELD_MESSAGE),
            given_name: string().required(REQUIRED_FIELD_MESSAGE),
            email: string()
                .email(INVALID_EMAIL_MESSAGE)
                .required(REQUIRED_FIELD_MESSAGE),
            password: string()
                .required(REQUIRED_FIELD_MESSAGE)
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{10,}$/g,
                    PASSWORD_REQUIREMENTS_MESSAGE
                ),
            accepted_terms: boolean().test(
                'accepted-terms',
                ACCEPT_TERMS_MESSAGE,
                (value) => value === true
            ),
        })

        this.state = {
            showTermsDialog: false,
            form: {
                given_name: '',
                family_name: '',
                email: '',
                password: '',
                accepted_terms: false,
                subscribe_marketing: false,
            },
            error: {},
        }

        // Required for SignUp.signUp call to succeed.
        this.signUpFields = [
            {
                label: 'Email',
                key: 'email',
                required: true,
            },
            {
                label: 'Password',
                key: 'password',
                required: true,
            },
            {
                label: 'First name',
                key: 'given_name',
                required: true,
            },
            {
                label: 'Family name',
                key: 'family_name',
                required: true,
            },
            {
                label: 'Terms id',
                key: 'accepted_terms',
                required: true,
                custom: true,
            },
            {
                label: 'Subscribe to marketing',
                key: 'subscribe_marketing',
                required: false,
                custom: true,
            },
        ]
    }

    signUp = () => {
        const error = checkErrors(this.state.form, this.validationSchema)
        this.setState({ error })

        if (Object.keys(error).length === 0) {
            super.signUp()
            ReactGA.event({
                category: 'Sign Up',
                action: 'Click Sign Up',
            })
        }
    }

    onClickSignIn = () => {
        super.changeState('signIn')
        ReactGA.event({
            category: 'Sign Up',
            action: 'Go to Sign In',
        })
    }

    onChange = (e) => {
        const { name, value, type, checked } = e.target
        const isCheckbox = type === 'checkbox'
        const form = {
            ...this.state.form,
            [name]: isCheckbox ? checked : value,
        }
        const error = checkErrors(form, this.validationSchema)
        this.setState({ form, error })

        if (name === 'password') {
            // Password is hoisted for later use in other elements.
            this.props.onSetPassword(value)
        }

        if (name === 'accepted_terms') {
            // Instead of passing the event for the checkbox, we want to pass
            // the ID of the terms that have been accepted.
            // Create a synthetic event to send the terms ID to Amplify base class.
            this.handleInputChange({
                target: {
                    name,
                    value: this.props.termsId,
                    type: 'text',
                },
            })
        } else if (name === 'subscribe_marketing') {
            this.handleInputChange({
                target: {
                    name,
                    value: checked ? '1' : '0',
                    type: 'text',
                },
            })
        } else {
            this.handleInputChange(e)
        }

        if (isCheckbox) {
            ReactGA.event({
                category: 'Sign Up',
                action: `${checked ? 'Checked' : 'Unchecked'} ${name}`,
            })
        } else if (value) {
            ReactGA.event({
                category: 'Sign Up',
                action: `Entered ${name}`,
            })
        }
    }

    onOpenTermsDialog = () => {
        this.setState({ showTermsDialog: true })
        ReactGA.event({
            category: 'Sign Up',
            action: 'Open Terms Dialog',
        })
    }

    onCloseTermsDialog = () => {
        this.setState({ showTermsDialog: false })
    }

    showComponent(theme) {
        return (
            <CenterWrapper>
                <TermsAndConditionsDialog
                    open={this.state.showTermsDialog}
                    onClose={this.onCloseTermsDialog}
                    terms={this.props.terms}
                />
                <Box display="flex" flexDirection="column" margin={2}>
                    <Typography
                        className={this.classes.title}
                        variant="h3"
                        align="center"
                    >
                        ScranPlan
                    </Typography>
                    <Typography color="textPrimary">
                        Create a new account
                    </Typography>
                    <TextField
                        margin="normal"
                        id="given-name"
                        name="given_name"
                        label="First name"
                        variant="outlined"
                        autoComplete="given-name"
                        value={this.state.form.given_name}
                        onChange={this.onChange}
                        error={
                            this.state.error &&
                            this.state.error.given_name != null
                        }
                        helperText={
                            this.state.error && this.state.error.given_name
                        }
                    />
                    <TextField
                        margin="normal"
                        id="family-name"
                        name="family_name"
                        label="Family name"
                        variant="outlined"
                        autoComplete="family-name"
                        value={this.state.form.family_name}
                        onChange={this.onChange}
                        error={
                            this.state.error &&
                            this.state.error.family_name != null
                        }
                        helperText={
                            this.state.error && this.state.error.family_name
                        }
                    />
                    <TextField
                        margin="normal"
                        id="email"
                        name="email"
                        label="Email"
                        type="email"
                        variant="outlined"
                        autoComplete="email"
                        value={this.state.form.email}
                        onChange={this.onChange}
                        error={
                            this.state.error && this.state.error.email != null
                        }
                        helperText={this.state.error && this.state.error.email}
                    />
                    <TextField
                        margin="normal"
                        type="password"
                        id="password"
                        name="password"
                        label="Password"
                        variant="outlined"
                        autoComplete="new-password"
                        value={this.props.password}
                        onChange={this.onChange}
                        error={
                            this.state.error &&
                            this.state.error.password != null
                        }
                        helperText={
                            this.state.error && this.state.error.password
                        }
                    />
                    <SubscribeToMarketingCheckbox
                        checked={this.state.form.subscribe_marketing}
                        onChange={this.onChange}
                    />
                    {this.props.isTermsFetching ? (
                        <Box display="flex" justifyContent="center">
                            <CircularProgress />
                        </Box>
                    ) : this.props.isTermsError ? (
                        <Box margin={1}>
                            <Alert severity="error">
                                Something has gone wrong during sign-up. Please
                                refresh this page and try again.
                            </Alert>
                        </Box>
                    ) : (
                        <React.Fragment>
                            <AcceptTermsCheckbox
                                checked={this.state.form.accepted_terms}
                                error={this.state.error.accepted_terms}
                                onChange={this.onChange}
                                onOpenTerms={this.onOpenTermsDialog}
                            />
                            <Button
                                className={this.classes.button}
                                variant="contained"
                                color="primary"
                                onClick={this.signUp}
                            >
                                Create account
                            </Button>
                        </React.Fragment>
                    )}
                    <Typography
                        className={this.classes.createAccount}
                        align="center"
                        variant="subtitle2"
                        color="textSecondary"
                    >
                        Have an account?{' '}
                        <Link
                            href="#"
                            color="primary"
                            onClick={this.onClickSignIn}
                        >
                            Sign in
                        </Link>
                    </Typography>
                </Box>
            </CenterWrapper>
        )
    }
}

export default withStyles(styles)(CustomSignUp)
