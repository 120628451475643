import { connect } from 'react-redux';
import WeekdayList from '../WeekdayList'
import { deletePlannedRecipeThunk, selectRecipeForDayThunk, setToastMessage } from '../../../../actions'
import { setIsDragging } from 'components/recipes/list/actions'

const mapStateToProps = state => ({
    weekDate: state.planner.weekDate,
    weekStart: state.planner.weekStart,
    recipes: state.entities.recipes,
    recipeMap: state.planner.recipeMap,
});

const mapDispatchToProps = dispatch => ({
    deletePlannedRecipe: (stamp, recipe) => dispatch(deletePlannedRecipeThunk(stamp, recipe)),
    selectRecipeForDay: (stamp, recipe, showToast = true) => {
        dispatch(selectRecipeForDayThunk(stamp, recipe));
        if (showToast)
            dispatch(setToastMessage(`Added ${recipe.title}`));
    },
    setIsDragging: isDragging => dispatch(setIsDragging(isDragging))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WeekdayList);