import { deletePlannedRecipeThunk, selectRecipeForDayThunk, setToastMessage } from '../../../../actions'
import { connect } from 'react-redux';
import { PickRecipePage } from '../PickRecipePage'

const mapStateToProps = state => ({
    recipes: state.entities.recipes,
    recipeMap: state.planner.recipeMap,
});

const mapDispatchToProps = dispatch => ({
    deletePlannedRecipe: (stamp, recipe) => dispatch(deletePlannedRecipeThunk(stamp, recipe)),
    selectRecipeForDay: (stamp, recipe, showToast = true) => {
        dispatch(selectRecipeForDayThunk(stamp, recipe));
        if (showToast)
            dispatch(setToastMessage(`Added ${recipe.title}`));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PickRecipePage);