import React from 'react'
import {
    Box
} from '@material-ui/core'
import PlannerPageBody from './containers/PlannerPageBody'
import AppBar from 'components/common/containers/AppBar'
import { PlannerPageAppBarMenu } from './PlannerPageAppBarMenu'

export const PlannerPage = () => {
    return (
        <Box>
            <AppBar
                title="Planner"
                rightMenu={<PlannerPageAppBarMenu/>}
            >
            </AppBar>
            <PlannerPageBody/>
        </Box>
    )
}
