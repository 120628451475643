import {
    setWeekStartThunk,
    setThemeConfig,
    subscribeToMarketingThunk
} from '../../../actions'
import { connect } from 'react-redux';
import SettingsBody from '../SettingsBody'

const mapStateToProps = state => ({
    weekStart: state.planner.weekStart,
    themeConfig: state.app.themeConfig,
    subscribedToMarketing: state.user.subscribedToMarketing
});

const mapDispatchToProps = dispatch => ({
    setWeekStart: weekStart => dispatch(setWeekStartThunk(weekStart)),
    setThemeConfig: config => dispatch(setThemeConfig(config)),
    setSubscribedToMarketing: subscribe => dispatch(subscribeToMarketingThunk(subscribe))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsBody);