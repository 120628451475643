import BaseQueryHandler from './BaseQueryHandler'
import HttpHelper from '../../common/HttpHelper'

export default class CheckNewTermsQueryHandler extends BaseQueryHandler {
    constructor() {
        super()
        this.httpHelper = new HttpHelper()
    }

    async fetchHttp() {
        const response = await this.httpHelper.get('terms/checknewterms')
        this.checkHttpResponse(response)
        let newTerms = await response.json()
        return newTerms.mustAcceptLatest
    }
}

export const checkNewTermsQueryHandler = new CheckNewTermsQueryHandler()