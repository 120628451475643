import RecipeCacheReader from 'services/cache/RecipeCacheReader'
import RecipeCacheWriter from 'services/cache/RecipeCacheWriter'
import commandScheduler from 'services/api/commands/commandScheduler'
import { Set as ImmutableSet } from 'immutable'

export default class EditRecipeCommandHandler {
    constructor() {
        this.recipeCacheReader = new RecipeCacheReader()
        this.recipeCacheWriter = new RecipeCacheWriter()
    }

    async execute(originalRecipe, newRecipe) {
        const diff = this.getDiff(originalRecipe, newRecipe)
        const cachedRecipe = await this.recipeCacheReader.getById(originalRecipe.id)

        // Update the cache.
        await this.recipeCacheWriter.update({
            id: originalRecipe.id,
            ...diff.properties,
            tags: ImmutableSet(cachedRecipe.tags).subtract(diff.tags.deleted).union(diff.tags.added).toArray()
        })

        // Update the server.
        await commandScheduler.editRecipe({
            id: originalRecipe.id,
            ...diff.properties,
            addedTags: diff.tags.added,
            removedTags: diff.tags.deleted,
        })
    }

    getDiff(originalRecipe, newRecipe) {
        const addedTags = ImmutableSet(newRecipe.tags).subtract(originalRecipe.tags)
        const deletedTags = ImmutableSet(originalRecipe.tags).subtract(newRecipe.tags)

        // Better to use a class to represent the recipe and compute the differences?
        let diff = {}
        originalRecipe.title !== newRecipe.title && (diff.title = newRecipe.title)
        originalRecipe.notes !== newRecipe.notes && (diff.notes = newRecipe.notes)
        originalRecipe.uri !== newRecipe.uri && (diff.uri = newRecipe.uri)

        return {
            properties: diff,
            tags: {
                added: addedTags.toArray(),
                deleted: deletedTags.toArray(),
            },
        }
    }
}

export const editRecipeCommandHandler = new EditRecipeCommandHandler()