import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography
} from '@material-ui/core'
import PolicyLink from './containers/PolicyLink'

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: `${theme.breakpoints.values.sm}px`,
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px 0`
  },
  summary: {
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px 0`
  }
}))

const CookieSettings = ({ settings, saveSettings, onSave, onCancel }) => {
  const classes = useStyles()

  // Take a snapshot of the settings for editing.
  // We adjust this local state until we save it back to the global state.
  const [settingsSnapshot, setSettingsSnapshot] = React.useState(settings)

  const onSaveSettings = React.useCallback(() => {
    saveSettings(settingsSnapshot)
    onSave()
  }, [saveSettings, settingsSnapshot, onSave])

  const onSettingsChange = React.useCallback(event => {
    setSettingsSnapshot({ ...settingsSnapshot, [event.target.name]: event.target.checked })
  }, [setSettingsSnapshot, settingsSnapshot])

  return (
    <Box marginTop={1} marginBottom={1} padding={1}>
      <Typography variant='body1' className={classes.summary}>
        We use cookies and similar technologies to provide essential functionality and offer you a better experience.
      </Typography>
      <Card className={classes.card}>
        <CardContent>
          <FormControl>
            <Typography variant='h6' component='h2'>Essential cookies</Typography>
            <FormControlLabel
              control={<Checkbox checked />}
              label={<Typography variant='subtitle1'>Minimum required for basic functionality</Typography>}
              disabled
            />
            <Typography variant='h6' component='h2'>Non-essential cookies</Typography>
            <FormControlLabel
              control={<Checkbox color='primary' name='acceptUsageMonitoring' checked={settingsSnapshot.acceptUsageMonitoring} onChange={onSettingsChange} />}
              label={<Typography variant='subtitle1'>Website usage monitoring helps us improve and develop new features</Typography>}
            />
          </FormControl>
        </CardContent>
        <CardActions>
          <Button variant='contained' onClick={onCancel}>Back</Button>
          <Button
            variant='contained'
            color='primary'
            onClick={onSaveSettings}
          >
            Save
          </Button>
        </CardActions>
      </Card>
      <Typography variant='body1'>
        You can change your preferences at any time.
      </Typography>
      <Typography variant='body1'>
        See our <PolicyLink><span>Cookie Policy</span></PolicyLink> for more information.
      </Typography>
    </Box>
  )
}

CookieSettings.propTypes = {
  settings: PropTypes.object.isRequired,
  saveSettings: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default CookieSettings
