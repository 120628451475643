import React from 'react'

export const PasswordHolder = ({ authState, children, ...props }) => {
  const [password, setPassword] = React.useState('')

  const onSetPassword = (password) => {
    setPassword(password)
  }

  const clonedChildren = React.Children.map(children, (child) =>
    React.cloneElement(child, { ...props, authState, password, onSetPassword })
  )

  return <React.Fragment>{clonedChildren.map((child) => child)}</React.Fragment>
}
