import { connect } from 'react-redux';
import ProfileFormButtons from '../ProfileFormButtons' 
import { setProfileEditing } from 'actions'
import { setProfileThunk } from '../setProfileThunk'

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    setProfile: profile => dispatch(setProfileThunk(profile)),
    setProfileEditing: isEditing => dispatch(setProfileEditing(isEditing))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileFormButtons);