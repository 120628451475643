import React from 'react'
import { Box } from '@material-ui/core'
import SettingsBody from './containers/SettingsBody'
import AppBar from 'components/common/containers/AppBar'

const Settings = ( {history, recipes} ) => {
    return (
        <Box>
            <AppBar
                title="Settings"
            >
            </AppBar>
            <SettingsBody/>
        </Box>
    )
}

export default Settings;