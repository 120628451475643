import log from 'loglevel'
import Auth from '@aws-amplify/auth'
import {
    openBusyModal,
    closeBusyModal,
    openErrorModal,
    setProfile,
    setProfileEditing,
} from 'actions'
import { getUser } from 'services/common/user'

export const setProfileThunk = profile => {
    return async dispatch => {
        dispatch(openBusyModal('Saving ...'))
        try {
            // Perform AWS Cognito update.
            const user = await getUser()
            await Auth.updateUserAttributes(user,
                {
                    given_name: profile.givenName,
                    family_name: profile.familyName,
                    email: profile.email,
                })

            dispatch(setProfile(profile))
            dispatch(setProfileEditing(false))
        } catch (error) {
            dispatch(openErrorModal('There was a problem saving your profile. Please try again.'))
            log.error('Error setting profile', error)
        } finally {
            dispatch(closeBusyModal())
        }
    }
}