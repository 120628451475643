import {
  SET_COOKIE_SETTINGS_URL,
  SET_COOKIE_POLICY_URL,
  SET_HOME_PAGE_URL,
  SET_PREVIOUS_URL
} from '../actions/actions'

const initialState = {
  previousUrl: null,
  homePageUrl: process.env.SCRANPLAN_HOME_PAGE_URL || 'https://scranplan.com',
  cookieSettingsUrl: process.env.REACT_APP_SCRANPLAN_COOKIE_SETTINGS_URL || 'https://scranplan.com/cookie-settings',
  cookiePolicyUrl: process.env.SCRANPLAN_COOKIE_POLICY_URL || 'https://scranplan.com/cookie-policy'
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PREVIOUS_URL:
      return {
        ...state,
        previousUrl: action.url
      }
    case SET_HOME_PAGE_URL:
      return {
        ...state,
        homePageUrl: action.url
      }
    case SET_COOKIE_SETTINGS_URL:
      return {
        ...state,
        cookieSettingsUrl: action.url
      }
    case SET_COOKIE_POLICY_URL:
      return {
        ...state,
        cookiePolicyUrl: action.url
      }
    default:
      return state
  }
}

export default reducer
