import { connect } from 'react-redux';
import AcceptTermsAndConditions from '../AcceptTermsAndConditions' 
import { acceptNewTermsThunk, popAppMuxPage } from 'actions'

export const PAGE_NAME_NEW_TERMS = 'PAGE_NAME_NEW_TERMS'

const mapStateToProps = state => ({
    terms: state.app.latestTerms.text,
    termsId: state.app.latestTerms.id,
    isPending: state.app.acceptNewTerms.isPending,
    isError: state.app.acceptNewTerms.isError,
});

const mapDispatchToProps = dispatch => ({
    acceptNewTerms: termsId => dispatch(acceptNewTermsThunk(termsId)),
    closePage: () => dispatch(popAppMuxPage(PAGE_NAME_NEW_TERMS))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AcceptTermsAndConditions);