import React from 'react'
import { object, string } from 'yup'
import { TextField } from '@material-ui/core'

const REQUIRED_FIELD_MESSAGE = 'This is a required field.'
const INVALID_EMAIL_MESSAGE = 'This must be a valid email.'

export const validationSchema = object().shape({
    givenName: string().required(REQUIRED_FIELD_MESSAGE),
    familyName: string().required(REQUIRED_FIELD_MESSAGE),
    email: string().email(INVALID_EMAIL_MESSAGE).required(REQUIRED_FIELD_MESSAGE),
})

const ProfileForm = ({ isDisabled, formData, error, onChange }) => {
    return (
        <React.Fragment>
            <TextField
            fullWidth
            margin="normal"
            id="first-name"
            name="givenName"
            label="First name"
            variant="outlined"
            autoComplete="given-name"
            disabled={ isDisabled }
            value={ formData.givenName }
            onChange={ onChange }
            error={ error && error.givenName }
            helperText={ error && error.givenName }
            >
            </TextField>
            <TextField
            fullWidth
            margin="normal"
            id="family-name"
            name="familyName"
            label="Family name"
            variant="outlined"
            autoComplete="family-name"
            disabled={ isDisabled }
            value={ formData.familyName }
            onChange={ onChange }
            error={ error && error.familyName }
            helperText={ error && error.familyName }
            >
            </TextField>
            <TextField
            fullWidth
            margin="normal"
            id="email"
            name="email"
            label="Email"
            variant="outlined"
            autoComplete="email"
            disabled={ isDisabled }
            value={ formData.email }
            onChange={ onChange }
            error={ error && error.email }
            helperText={ error && error.email }
            >
            </TextField>
        </React.Fragment>
    )
}

export default ProfileForm