import React from 'react'
import { SvgIcon } from '@material-ui/core'

const ScranPlanIcon = ({ color, classes, className }) => {
    return (
        <SvgIcon color={ color } classes={{ root: classes && classes.root }} className={ className }>
            <circle
                style={{ strokeWidth: 2, stroke: 'currentColor', fill: 'none' }}
                cx="12"
                cy="12"
                r="11"
            />
            <path
                d="M 5.1387791,11.734738 7.9873106,11.259153 Q 8.346123,12.380174 9.8326318,12.380174 10.989619,12.380174 10.989619,11.809473 10.989619,11.510534 10.718679,11.347477 10.455062,11.177625 9.7667274,11.048538 7.1378771,10.579747 6.3909613,9.8256048 5.6440456,9.0714633 5.6440456,8.0523531 5.6440456,6.7410979 6.7131603,5.8714572 7.7895976,5.0018165 9.803341,5.0018165 12.856908,5.0018165 13.83815,7.2778293 L 11.297173,7.9912064 Q 10.901747,7.0604191 9.6935004,7.0604191 8.6902901,7.0604191 8.6902901,7.6379149 8.6902901,7.8960894 8.9172939,8.0387649 9.1442977,8.1814403 9.7960182,8.3105276 11.59008,8.6638191 12.344319,8.9355819 13.098557,9.2073446 13.603824,9.8663692 14.10909,10.5186 14.10909,11.408623 14.10909,12.814995 12.878877,13.69143 11.648662,14.56107 9.6495642,14.56107 5.9955354,14.56107 5.1387791,11.734738 Z"
                style={{ strokeWidth: 0.42, stroke: 'none', fill: 'currentColor' }}
            />
            <path
                d="M 14.330374,16.045978 V 19.492194 H 11.147707 L 11.123694,13.663065 11.203499,12.425878 11.147707,10.008152 H 15.513578 C 16.601925,10.008152 17.428171,10.123952 17.992314,10.355553 18.56145,10.587153 19.010768,10.941503 19.340267,11.418599 19.674759,11.895697 19.842005,12.430694 19.842005,13.023591 19.842005,13.926833 19.502521,14.656374 18.823552,15.212216 18.144583,15.768057 17.238459,16.045978 16.10518,16.045978 Z M 14.285446,14.010209 H 15.333854 C 16.257452,14.010209 16.719251,13.695232 16.719251,13.065279 16.719251,12.472382 16.292399,12.175933 15.438695,12.175933 H 14.285446 Z"
                style={{ strokeWidth: 0.42, stroke: 'none', fill: 'currentColor' }}
            />
        </SvgIcon>
    )
}

export default ScranPlanIcon