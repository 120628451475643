import React from 'react';
import { Box } from '@material-ui/core'
import RecipeCardDraggable from './RecipeCardDraggable';

const RecipeList = ({
    recipes,
    onSelectRecipe,
    enableAddButton,
    enableDrag,
    setIsDragging
}) => {
    const onDragStart = React.useCallback(() => {
        setIsDragging(true)
    }, [setIsDragging])

    const onDragStop = React.useCallback(() => {
        setIsDragging(false)
    }, [setIsDragging])

    return (
        <Box>
            {recipes.map((recipe, index) => <RecipeCardDraggable
                key={recipe.id}
                recipe={recipe}
                onSelectRecipe={onSelectRecipe}
                enableAddButton={enableAddButton}
                enableDrag={enableDrag}
                onDragStart={onDragStart}
                onDragStop={onDragStop}
                />)}
        </Box>
    );
};

export default RecipeList;