import { connect } from 'react-redux'
import CookieSettingsPage from '../CookieSettingsPage'
import {
  disableAcceptCookiesDialog,
  enableAcceptCookiesDialog
} from '../actions'

const mapStateToProps = state => ({
  previousUrl: state.cookieSettings.previousUrl
})

const mapDispatchToProps = dispatch => ({
  enableAcceptCookiesDialog: () => dispatch(enableAcceptCookiesDialog()),
  disableAcceptCookiesDialog: () => dispatch(disableAcceptCookiesDialog())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CookieSettingsPage)
