import React from 'react'
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Link,
} from '@material-ui/core'

const AcceptTermsCheckbox = ({ checked, onOpenTerms, onChange, error }) => {
    return (
        <FormControl error={error != null}>
            <FormControlLabel
                label={
                    <span>
                        I agree to the&nbsp;
                        <Link href="#" onClick={onOpenTerms}>
                            Terms&nbsp;
                        </Link>
                        and&nbsp;
                        <Link
                            href="https://scranplan.com/privacy-policy"
                            target="_blank"
                            rel="noopener"
                        >
                            Privacy Policy
                        </Link>
                    </span>
                }
                control={
                    <Checkbox
                        id="accept-terms"
                        color="primary"
                        checked={checked}
                        name="accepted_terms"
                        onChange={onChange}
                    />
                }
            />
            <FormHelperText>{error}</FormHelperText>
        </FormControl>
    )
}

export default AcceptTermsCheckbox
