import React from 'react'
import memoize from 'memoize-one'
import debounce from 'lodash-es/debounce'
import { withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import RecipeList from '../list/containers/RecipeList'
import { withRouter } from 'react-router-dom'
import { Set as ImmutableSet } from 'immutable'
import CaseInsensitiveString from 'common/CaseInsensitiveString'
import { RecipeSearch } from './RecipeSearch'
import ReactGA from 'react-ga4'

class SearchableRecipeList extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            open: false,
        }

        this.searchRef = React.createRef(null)
        this.searchInputRef = React.createRef(null)
    }

    debouncedSearchAnalytics = debounce(() => {
        const text = this.getSearchParam()
        ReactGA.event({
            category: 'Recipes',
            action: 'Search Text',
            label: text,
        })
    }, 2000)

    updateUrlParams = (search, searchOptions) => {
        const searchParam = `search=${search}`
        const personalParam = searchOptions.isPersonal
            ? `&personal=${searchOptions.isPersonal}`
            : ''
        const bookmarkedParam = searchOptions.isBookmarked
            ? `&bookmarked=${searchOptions.isBookmarked}`
            : ''
        const tagsParam = !searchOptions.tags.isEmpty()
            ? `&tags=${searchOptions.tags.toArray().map((x) => x.value)}`
            : ''
        this.props.history.replace({
            pathname: this.props.history.location.pathname,
            search: `?${searchParam}${personalParam}${bookmarkedParam}${tagsParam}`,
        })
    }

    getUrl = () =>
        new URL(
            `http://test.com${this.props.history.location.pathname}${this.props.history.location.search}`
        )

    getSearchParam = () => this.getUrl().searchParams.get('search') || ''

    getSearchOptionsParam = () => {
        const url = this.getUrl()
        return {
            isPersonal: url.searchParams.get('personal') === 'true',
            isBookmarked: url.searchParams.get('bookmarked') === 'true',
            tags: ImmutableSet(
                (url.searchParams.get('tags') || '')
                    .split(',')
                    .filter((x) => x !== '')
                    .map((x) => new CaseInsensitiveString(x))
            ),
        }
    }

    onChange = (e) => {
        const text = e.target.value
        if (text) {
            this.debouncedSearchAnalytics()
        }
        this.updateUrlParams(text, this.getSearchOptionsParam())
    }

    onClearSearch = (e) => {
        this.updateUrlParams('', this.getSearchOptionsParam())
    }

    toggleSearchOptions = () => {
        if (!this.state.open) {
            ReactGA.event({
                category: 'Recipes',
                action: 'Open Search Options',
            })
        }
        this.setState({ open: !this.state.open })
    }

    setOpen = (open) => {
        this.setState({ open })
    }

    setSearchOptions = (options) => {
        this.updateUrlParams(this.getSearchParam(), options)
    }

    addRecipePlanCount = memoize((recipes, plans) => {
        // Add all recipes and set to 0 count.
        let map = new Map(
            Object.values(recipes).map((recipe) => [recipe.id, 0])
        )
        // Count the number of times a recipe has been planned.
        plans
            .toArray()
            .map(([, recipeId]) => recipeId)
            .reduce(
                (map, recipeId) => map.set(recipeId, map.get(recipeId) + 1),
                map
            )
        // Add the plan count to the recipe.
        return Array.from(map.entries()).map(([recipeId, count]) => ({
            ...recipes[recipeId],
            planCount: count,
        }))
    })

    filterRecipes = memoize((recipes, bookmarks, searchText, searchOptions) =>
        recipes.filter(
            (recipe) =>
                (!searchOptions.isPersonal || recipe.isPersonal) &&
                (!searchOptions.isBookmarked || bookmarks.has(recipe.id)) &&
                (!searchText ||
                    recipe.title
                        .toLowerCase()
                        .includes(searchText.toLowerCase())) &&
                (searchOptions.tags.length === 0 ||
                    searchOptions.tags.subtract(recipe.tags).size === 0)
        )
    )

    sortRecipes = memoize((recipes, isSortByPlanCount) => {
        const items = [...recipes]
        items.sort((a, b) => {
            // Highest plan count first.
            if (isSortByPlanCount && a.planCount !== b.planCount)
                return b.planCount - a.planCount
            if (a.title < b.title) return -1
            if (a.title > b.title) return 1
            // Show personal recipes first if they match a ScranPlan recipe title.
            if (a.isPersonal !== b.isPersonal)
                return b.isPersonal - a.isPersonal
            return 0
        })
        return items
    })

    render() {
        const {
            classes,
            recipes,
            tags,
            bookmarks,
            onSelectRecipe,
            enableAddButton,
            enableDrag,
            plans,
            sortByPlanCount,
        } = this.props

        const { open } = this.state

        const search = this.getSearchParam()
        const searchOptions = this.getSearchOptionsParam()

        const recipesWithPlanCount = this.addRecipePlanCount(recipes, plans)

        const filteredRecipes = this.filterRecipes(
            recipesWithPlanCount,
            bookmarks,
            search,
            searchOptions
        )

        const sortedRecipes = this.sortRecipes(filteredRecipes, sortByPlanCount)

        const hasSearchOptions =
            searchOptions.isPersonal ||
            searchOptions.isBookmarked ||
            searchOptions.tags.size > 0

        return (
            <Box>
                <Box ref={this.searchRef} className={classes.search}>
                    <RecipeSearch
                        hasSearchOptions={hasSearchOptions}
                        onChange={this.onChange}
                        onClearSearch={this.onClearSearch}
                        open={open}
                        search={search}
                        searchOptions={searchOptions}
                        setOpen={this.setOpen}
                        setSearchOptions={this.setSearchOptions}
                        tags={tags}
                        toggleSearchOptions={this.toggleSearchOptions}
                        ref={this.searchInputRef}
                    />
                </Box>
                <RecipeList
                    recipes={sortedRecipes}
                    onSelectRecipe={onSelectRecipe}
                    enableAddButton={enableAddButton}
                    enableDrag={enableDrag}
                />
            </Box>
        )
    }
}

export default withRouter(
    withStyles((theme) => ({
        search: {
            position: 'sticky',
            top: 0,
            // Z-index required due to CSS stacking order changes with RecipeCard transform property.
            zIndex: 1,
            backgroundColor: 'white',
        },
    }))(SearchableRecipeList)
)
