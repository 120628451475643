import { Set as ImmutableSet } from 'immutable'
import {
    BOOKMARK_RECIPE,
    UNBOOKMARK_RECIPE,
    SET_ALL_BOOKMARKS,
    SET_PROFILE,
    SET_PROFILE_EDITING,
    SET_SUBSCRIBED_TO_MARKETING
} from '../actions/actions'

const initialState = {
    bookmarks: ImmutableSet(),
    // Set initial state to empty string to prevent TextField
    // overlaying placeholder text and content.
    profile: {
        givenName: '',
        familyName: '',
        email: '',
    },
    isEditingProfile: false,
    subscribedToMarketing: false
};

const entities = (state = initialState, action) => {
    switch (action.type) {
        case BOOKMARK_RECIPE:
            return { ...state, bookmarks: state.bookmarks.add(action.recipe.id) };
        case UNBOOKMARK_RECIPE:
            return { ...state, bookmarks: state.bookmarks.delete(action.recipe.id) };
        case SET_ALL_BOOKMARKS:
            return {
                ...state,
                bookmarks: ImmutableSet(action.bookmarks)
            }
        case SET_PROFILE:
            return {
                ...state,
                profile: action.profile
            }
        case SET_PROFILE_EDITING:
            return {
                ...state,
                isEditingProfile: action.isEditing,
            }
        case SET_SUBSCRIBED_TO_MARKETING:
            return {
                ...state,
                subscribedToMarketing: action.subscribed
            }
        default:
            return state;
    }
}

export default entities;