import { connect } from 'react-redux'
import PolicyLink from '../PolicyLink'

const mapStateToProps = state => ({
  cookiePolicyUrl: state.urls.cookiePolicyUrl
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicyLink)
