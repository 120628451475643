import { connect } from 'react-redux'
import CookieSettings from '../CookieSettings'
import {
  saveCookieSettingsThunk
} from '../actions'

const mapStateToProps = state => ({
  settings: state.cookieSettings
})

const mapDispatchToProps = dispatch => ({
  saveSettings: settings => dispatch(saveCookieSettingsThunk(settings)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CookieSettings)
