import BaseQueryHandler from './BaseQueryHandler'
import HttpHelper from '../../common/HttpHelper'
import TagCacheReader from 'services/cache/TagCacheReader'
import TagCacheWriter from 'services/cache/TagCacheWriter'
import { Set as ImmutableSet } from 'immutable'

export default class GetAllTagsQueryHandler extends BaseQueryHandler{
    constructor() {
        super()
        this.httpHelper = new HttpHelper()
        this.tagCacheReader = new TagCacheReader()
        this.tagCacheWriter = new TagCacheWriter()
    }

    transformForClient(data) {
        // Client expects array of strings that are the tag names.
        return data.map(val => val.name)
    }

    async fetchCache() {
        return await this.tagCacheReader.getAll()
    }

    async fetchHttp() {
        const response = await this.httpHelper.get('tags')
        this.checkHttpResponse(response)
        return await response.json()
    }

    async updateCache(tags, cachedTags) {
        const tagNames = ImmutableSet((cachedTags || []).map(tag => tag.name))
        const tagNamesToDelete = tagNames.subtract((tags || []).map(tag => tag.name))
        await this.tagCacheWriter.bulkDelete(tagNamesToDelete.toArray())
        await this.tagCacheWriter.bulkPut(tags)
    }
}

export const getAllTagsQueryHandler = new GetAllTagsQueryHandler()