import { connect } from 'react-redux';
import CustomSignUp from '../CustomSignUp' 

const mapStateToProps = state => ({
    isTermsFetching: state.app.latestTerms.isFetching,
    isTermsError: state.app.latestTerms.error,
    terms: state.app.latestTerms.text,
    termsId: state.app.latestTerms.id,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomSignUp);