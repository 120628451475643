import { Set as ImmutableSet } from 'immutable'

export default class RecipeFormDto {
    constructor(obj) {
        this.isPersonal = obj != null && typeof(obj.isPersonal) === 'boolean' ? obj.isPersonal : true
        this.title = obj != null && typeof(obj.title) === 'string' ? obj.title : ''
        this.uri = obj != null && typeof(obj.uri) === 'string' ? obj.uri : ''
        this.notes = obj != null && typeof(obj.notes) === 'string' ? obj.notes : ''
        this.tags = obj != null && ImmutableSet.isSet(obj.tags) ? obj.tags : ImmutableSet()
    }
}