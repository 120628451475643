import Dexie from 'dexie'
import log from 'loglevel'

export const settingsId = 1

export const db = new Dexie('cache')
db.version(1).stores({
    recipes: '[id+userId],userId',
    tags: '[name+userId],userId',
    plans: '[date+userId],userId',
    settings: 'userId',
    bookmarks: '[recipeId+userId],userId',
})

db.open().catch(err => {
    log.error(`Cache DB failed to open: ${err}`)
})