import React from 'react'
import { ValidationError } from 'yup'
import { is } from 'immutable'
import log from 'loglevel'

/**
 * Trims the given string, otherwise ignores and returns the given parameter.
 * @param {string} str string to trim, otherwise no action taken
 */
const trim = (str) => {
    if (!str || typeof(str) !== 'string') {
        // Bypass
        return str
    }

    return str.trim()
}

/**
 * Shallow compares the two objects and returns true if there are differences.
 * 
 * Note: uses Immutable.is(), hence supports equality in Immutable Sets, Maps etc.,
 *       as well as objects that implement equals() and hashCode().
 * @param {object} left the left object to compare with the right
 * @param {object} right the right object to compare with the left
 */
const checkIsDirty = (left, right) =>
    Object.keys(left).reduce((acc, key) => acc || !is(trim(left[key]), trim(right[key])), false)

const checkErrors = (data, validationSchema) => {
    try {
        validationSchema.validateSync(data)

        // No errors.
        return {}
    } catch (error) {
        if (error instanceof ValidationError) {
            // Expect only single error at a time (i.e. abortEarly = true)
            return {
                [error.path]: error.message
            }
        }

        // Not sure what to do here.
        log.error('Error on validation', error)
    }
}

const FormEdit = ({ formData, children, validationSchema }) => {
    // Local snapshot of form data.
    const [origData] = React.useState(formData)
    const [newData, setNewData] = React.useState(formData)
    const [isDirty, setIsDirty] = React.useState(false)
    const [error, setError] = React.useState(checkErrors(newData,validationSchema))

    const onChange = React.useCallback(e => {
        const data = { ...newData, [e.target.name]: e.target.value }
        setNewData(data)

        setIsDirty(checkIsDirty(origData, data))
        setError(checkErrors(data, validationSchema))
    }, [setNewData, setError, newData, origData, setIsDirty, validationSchema])

    const isValid = Object.keys(error).length === 0

    const clonedChildren = React.Children.toArray(children)
        .filter(child => child != null)
        .map(
            child => React.cloneElement(child,
            {
                formData: newData,
                onChange,
                isDirty,
                error,
                isValid,
            })
        )

    return (
        <React.Fragment>
            { clonedChildren }
        </React.Fragment>
    )
}

export default FormEdit