import HttpHelper from 'services/api/common/HttpHelper'

export default class CommandApi {
    constructor() {
        this.httpHelper = new HttpHelper()
    }

    async editRecipe(recipe) {
        await this.httpHelper.put('recipes', recipe)
    }

    async createRecipe(recipe) {
        await this.httpHelper.post('recipes', recipe)
    }

    async editSettings(settings) {
        await this.httpHelper.put('user/settings', settings)
    }

    async planRecipe(date, recipeId) {
        await this.httpHelper.put('planner', { date, items: [ { recipeId }] })
    }

    async deletePlannedRecipe(date, recipeId) {
        await this.httpHelper.delete('planner', { date, items: [ { recipeId }] })
    }

    async bookmarkRecipe(recipeId) {
        await this.httpHelper.put('bookmarks', { recipeId })
    }

    async unbookmarkRecipe(recipeId) {
        await this.httpHelper.delete('bookmarks', { recipeId })
    }
}