import { controllerChangeObservable } from 'common/observables'
import log from 'loglevel'

/**
 * Listens to service worker controller changed events and 
 * refreshes the page when the event is received.
 * 
 * Note: the controller changed event should only be signalled
 * when the app (all open tabs) can safely be refreshed. This
 * implies that an updated service worker should not take
 * control until the app is ready.
 */
export default class ServiceWorkerChangedController {
    constructor() {
        this.isReloading = false
        controllerChangeObservable.subscribe(val => {
            log.info('RELOADING ...', window.location)
            window != null && window.location.reload()
            this.isReloading = true
        })
    }
}
