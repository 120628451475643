import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import AcceptTermsCheckbox from './AcceptTermsCheckbox'
import TermsAndConditionsDialog from 'components/auth/TermsAndConditionsDialog'
import CenterWrapper from 'components/common/CenterWrapper'

const styles = theme => ({
    errorButton: {
        marginTop: theme.spacing(1)
    }
})

class AcceptTermsAndConditions extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showTermsDialog: false,
            checked: false,
        }
    }
    
    onChange = event => {
        this.setState({ checked: event.target.checked })
    }

    onAccept = () => {
        this.props.acceptNewTerms(this.props.termsId)
    }

    onOpenTermsDialog = () => {
        this.setState({ showTermsDialog: true })
    }

    onCloseTermsDialog = () => {
        this.setState({ showTermsDialog: false })
    }

    render() {
        const { classes, closePage, isPending, isError, terms } = this.props
        const acceptButton = !isPending && !isError ? (
            <Button
            disabled={ !this.state.checked }
            variant="contained"
            color="primary"
            onClick={this.onAccept}
            >
                Accept Terms
            </Button>
        ) : null
        const spinner = isPending && !isError ? <CircularProgress /> : null
        const errorMessage = isError ? (
            <React.Fragment>
                <Alert severity="error">Something went wrong. We'll try again later.</Alert>
                <Button
                className={ classes.errorButton }
                variant="contained"
                color="primary"
                onClick={ closePage }
                >
                    Ok
                </Button>
            </React.Fragment>
        ) : null

        return (
            <CenterWrapper>
                <TermsAndConditionsDialog open={ this.state.showTermsDialog } onClose={ this.onCloseTermsDialog } terms={ terms } />
                <Card>
                    <CardHeader
                    title="Terms and conditions update"
                    />
                    <CardContent>
                        <Typography variant="body1">
                            We've updated our terms and conditions for using this app.
                            Please review the terms and conditions below and accept them
                            if you wish to continue using this app.
                        </Typography>
                        <AcceptTermsCheckbox
                            checked={ this.state.checked }
                            error={ this.state.checked ? null : 'You must accept the terms and conditions to continue using this app.' }
                            onChange={ this.onChange }
                            onOpenTerms={ this.onOpenTermsDialog }
                        />
                        <Box marginTop={1}>
                            { acceptButton }
                            { spinner }
                            { errorMessage }
                        </Box>
                    </CardContent>
                </Card>
            </CenterWrapper>
        )
    }
}

export default withStyles(styles)(AcceptTermsAndConditions)