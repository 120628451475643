import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Box } from '@material-ui/core';

const useStyles = makeStyles( theme => ({
    box: {
        display: 'flex',
        flexGrow: 1,
    },
    text: {
        marginLeft: theme.spacing(1),
        
    },
}));

const RecipeCardContent = ({
    onClick,
    recipe,
}) => {
    const classes = useStyles();

    return (
        <Box
        className={classes.box}
        minHeight="60px"
        alignItems="center"
        onClick={onClick}
        >
            <Typography
            className={classes.text}
            >
            {recipe.title}
            </Typography>
        </Box>
    );
};

export default RecipeCardContent