import React from 'react'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import MoreIcon from '@material-ui/icons/MoreVert'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga4'

export const PlannerPageAppBarMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState(null)

    function handleClick(event) {
        setAnchorEl(event.currentTarget)
    }

    function handleClose() {
        setAnchorEl(null)
    }

    const onNew = React.useCallback(() => {
        ReactGA.event({
            category: 'Planner',
            action: 'New Recipe',
        })
        handleClose()
    }, [])

    return (
        <React.Fragment>
            <IconButton
                edge="end"
                color="inherit"
                aria-label="more"
                onClick={handleClick}
            >
                <MoreIcon />
            </IconButton>
            <Menu
                id="more-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem component={Link} to="/recipes/new" onClick={onNew}>
                    New recipe
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
}
