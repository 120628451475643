import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles';
import {
    Box
} from '@material-ui/core'
import { appBarResizeObservable } from 'common/observables/AppBarResizeObservable'

const useStyles = makeStyles(theme => ({
    root: state => ({
        // Make scrollable in Y direction.
        overflowY: 'auto',
        // Clamp the height.
        height: `${state.height}px`
    })
}))

export const RemainingScreenHeightClamp = React.forwardRef(({
    onTouchStart,
    onTouchMove,
    classes,
    children
}, ref) => {
    const [height, setHeight] = React.useState(window.innerHeight)

    const ownClasses = useStyles({ height })

    // Create a ref to use for ourself in case a ref is has not been passed in.
    const ownRef = React.useRef(null)
    const rootRef = ref != null ? ref : ownRef

    const updateDimensions = React.useCallback(() => {
        // Get the top of the wrapping element.
        const top = (rootRef.current && rootRef.current.getBoundingClientRect().top) || 0
        // Remaining screen height is the window height minus the top of the wrapper.
        setHeight(window.innerHeight - top)
    }, [setHeight, rootRef])

    React.useEffect(() => {
        window.addEventListener("resize", updateDimensions)
        const subscription = appBarResizeObservable.subscribe(() => {
            updateDimensions()
        })
        return () => {
            window.removeEventListener("resize", updateDimensions)
            subscription.unsubscribe()
        }
    }, [updateDimensions])

    React.useLayoutEffect(() => {
        // Update dimensions on initial render.
        updateDimensions()
    }, [updateDimensions])

    return (
        <Box
            ref={rootRef}
            classes={{ root: clsx(ownClasses.root, classes && classes.root) }}
            display='flex'
            flexDirection='column'
            onTouchStart={onTouchStart}
            onTouchMove={onTouchMove}
        >
            {children}
        </Box>
    )
})  
