import { connect } from 'react-redux';
import AppUpdateDialog from '../AppUpdateDialog'
import {
    performAppUpdateThunk
} from 'actions'

const mapStateToProps = state => ({
    open: state.app.showAppUpdateDialog
});

const mapDispatchToProps = dispatch => ({
    performAppUpdate: defer => dispatch(performAppUpdateThunk(defer))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppUpdateDialog);