import React from 'react'
import { withRouter } from 'react-router'
import { Box } from '@material-ui/core'
import { RecipesPageAppBarMenu } from './RecipesPageAppBarMenu'
import AppBar from 'components/common/containers/AppBar'
import RecipesBody from './containers/RecipesBody'
import ReactGA from 'react-ga4'

class RecipesPage extends React.Component {
    onSelectRecipe = (recipe) => {
        this.props.history.push(`/recipes/calendar/${recipe.id}`)
        ReactGA.event({
            category: 'Recipes',
            action: 'Begin Plan Recipe',
            label: recipe.id,
        })
    }

    render() {
        return (
            <Box>
                <AppBar title="Recipes" rightMenu={<RecipesPageAppBarMenu />} />
                <RecipesBody
                    enableAddButton
                    onSelectRecipe={this.onSelectRecipe}
                />
            </Box>
        )
    }
}

export default withRouter(RecipesPage)
