import React from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar as MaterialAppBar, Toolbar, IconButton, Typography, CircularProgress } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ReactResizeDetector from 'react-resize-detector';
import NavDrawer from 'components/nav-drawer/containers/NavDrawer';
import ScranPlanIcon from 'components/common/ScranPlanIcon'
import { appBarResizeObservable } from 'common/observables/AppBarResizeObservable'

const useStyles = makeStyles(theme => ({
    menuButton: {
        marginRight: theme.spacing(1),
    },
    title: {
        flexGrow: 1,
    },
}));

const AppBar = ({ children, rightMenu, history, returnable, title, isRefreshing }) => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const toggleDrawer = React.useCallback((open) => {
        setOpen(open);
    }, [setOpen]);

    const openDrawer = React.useCallback(() => {
        toggleDrawer(true);
    }, [toggleDrawer]);

    const goBack = React.useCallback(() => {
        history.goBack();
    }, [history]);

    const onResize = React.useCallback((width, height) => {
        appBarResizeObservable.onResize(width, height)
    }, [])

    return (
        <React.Fragment>
            <MaterialAppBar position="sticky">
                <ReactResizeDetector handleHeight onResize={onResize}/>
                <Toolbar>
                    <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label={returnable ? "back" : "menu"}
                    onClick={returnable ? goBack : openDrawer}>
                        {returnable ? <ArrowBackIosIcon /> : <ScranPlanIcon />}
                    </IconButton>
                    { title != null ? <Typography className={classes.title}>{title}</Typography> : null }
                    { children }
                    { isRefreshing ? <CircularProgress color="inherit" size={20} /> : null }
                    { rightMenu }
                </Toolbar>
            </MaterialAppBar>
            <NavDrawer open={open} onClose={toggleDrawer}/>
        </React.Fragment>
    );
}

export default withRouter(AppBar);