import React from 'react';
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import {
    Card,
    Chip,
    IconButton } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import RecipeCardContent from './RecipeCardContent';

const useStyles = makeStyles( theme => ({
    root: {
        display: 'flex',
        alignItems: 'center'
    },
}));

const RecipeCard = React.forwardRef(({
    history,
    className,
    style,
    otherTransform,
    overrideTouchAction,
    recipe,
    onSelectRecipe,
    enableAddButton,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    onBeforeMouseDown,
    onBeforeMouseUp,
    onBeforeTouchStart,
    onBeforeTouchEnd,
    disableClick,
},
ref) => {
    const classes = useStyles();

    const onCardClick = React.useCallback(() => {
        !disableClick && recipe && history.push(`/recipes/edit/${recipe.id}`)
    }, [disableClick, history, recipe])

    const onAddClick = React.useCallback(() => {
        !disableClick && onSelectRecipe(recipe);
    }, [disableClick, recipe, onSelectRecipe]);

    const onMouseDownWrapper = React.useCallback((...props) => {
        onBeforeMouseDown && onBeforeMouseDown(onMouseDown, ...props)
    }, [onBeforeMouseDown, onMouseDown])

    const onMouseUpWrapper = React.useCallback((...props) => {
        onBeforeMouseUp && onBeforeMouseUp(onMouseUp, ...props)
    }, [onBeforeMouseUp, onMouseUp])

    const onTouchStartWrapper = React.useCallback((...props) => {
        onBeforeTouchStart && onBeforeTouchStart(onTouchStart, ...props)
    }, [onBeforeTouchStart, onTouchStart])

    const onTouchEndWrapper = React.useCallback((...props) => {
        onBeforeTouchEnd && onBeforeTouchEnd(onTouchEnd, ...props)
    }, [onBeforeTouchEnd, onTouchEnd])

    const overrideTransformStyle = { ...style, ...{ transform: clsx(style && style.transform, otherTransform) } }

    if (overrideTouchAction != null) {
        overrideTransformStyle.touchAction = overrideTouchAction
    }

    return (
        <Card
        ref={ref}
        className={clsx(className, classes.root)}
        style={overrideTransformStyle}
        square
        onMouseDown={onMouseDownWrapper}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUpWrapper}
        onTouchStart={onTouchStartWrapper}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEndWrapper}
        >
            <RecipeCardContent
            onClick={onCardClick}
            recipe={recipe}
            />
            <Chip label={recipe.planCount} size='small'/>
            { enableAddButton ? (
                <IconButton onClick={onAddClick}>
                    <EventIcon/>
                </IconButton>
            ) : null}
        </Card>
    )
})

export default RecipeCard