import Dexie from 'dexie'
import commandApi from 'services/api/commands'
import { TimerObservable, ONE_MINUTE_MILLISECONDS } from 'common/observables'
import OnlineSignedInController from 'services/common/OnlineSignedInController'
import log from 'loglevel'

const EDIT_RECIPE = 'EDIT_RECIPE'
const CREATE_RECIPE = 'CREATE_RECIPE'
const EDIT_SETTINGS = 'EDIT_SETTINGS'
const PLAN_RECIPE = 'PLAN_RECIPE'
const DELETE_PLANNED_RECIPE = 'DELETE_PLANNED_RECIPE'
const BOOKMARK_RECIPE = 'BOOKMARK_RECIPE'
const UNBOOKMARK_RECIPE = 'UNBOOKMARK_RECIPE'

const db = new Dexie('command-queue')
db.version(1).stores({
    commands: 'id++',
})

db.open().catch(err => {
    log.error(`Command Queue DB failed to open: ${err}`)
})

class CommandScheduler extends OnlineSignedInController{
    constructor() {
        super()
        this.log = log.getLogger('CommandScheduler')
        this.isSyncing = false

        // Try to send commands periodically.
        this.timerObservable = new TimerObservable(5 * ONE_MINUTE_MILLISECONDS)
        this.timerObservable.subscribe(() => {
            this.onUpdate()
        })
    }

    async onUpdate() {
        await this.sync()
    }

    async sync () {
        // Do nothing if we are offline, not signed in, or are already syncing.
        if (!this.state.isOnline || !this.state.isSignedIn || this.isSyncing) return

        // About to sync, so reset the timer.
        this.timerObservable.resetTimer()

        try {
            this.isSyncing = true
            for(;;) {
                const command = await db.commands.limit(1).first()
                if (command == null) {
                    // No more commands.
                    break
                }
                this.log.debug('GOT COMMAND', command)
                switch (command.type) {
                    case EDIT_RECIPE:
                        await commandApi.editRecipe(command.recipe)
                        break
                    case CREATE_RECIPE:
                        await commandApi.createRecipe(command.recipe)
                        break
                    case EDIT_SETTINGS:
                        await commandApi.editSettings(command.settings)
                        break
                    case PLAN_RECIPE:
                        await commandApi.planRecipe(command.date, command.recipeId)
                        break
                    case DELETE_PLANNED_RECIPE:
                        await commandApi.deletePlannedRecipe(command.date, command.recipeId)
                        break
                    case BOOKMARK_RECIPE:
                        await commandApi.bookmarkRecipe(command.recipeId)
                        break
                    case UNBOOKMARK_RECIPE:
                        await commandApi.unbookmarkRecipe(command.recipeId)
                        break
                    default:
                        this.log.error(`ERROR: unknown command: ${command}`)
                }
                await db.commands.delete(command.id)
                this.log.debug('FINISHED COMMAND', command)
            }
        } catch (err) {
            this.log.error('Command queue processing error:', err)
        } finally {
            this.isSyncing = false
        }
    }

    async add(command) {
        await db.commands.add({ ...command })
        this.sync()
    }

    async editRecipe(recipe) {
        await this.add({
            type: EDIT_RECIPE,
            recipe,
        })
    }

    async createRecipe(recipe) {
        await this.add({
            type: CREATE_RECIPE,
            recipe,
        })
    }

    async editSettings(settings) {
        await this.add({
            type: EDIT_SETTINGS,
            settings,
        })
    }

    async planRecipe(date, recipeId) {
        await this.add({
            type: PLAN_RECIPE,
            date,
            recipeId,
        })
    }

    async deletePlannedRecipe(date, recipeId) {
        await this.add({
            type: DELETE_PLANNED_RECIPE,
            date,
            recipeId,
        })
    }

    async bookmarkRecipe(recipeId) {
        await this.add({
            type: BOOKMARK_RECIPE,
            recipeId,
        })
    }

    async unbookmarkRecipe(recipeId) {
        await this.add({
            type: UNBOOKMARK_RECIPE,
            recipeId,
        })
    }
}

const commandScheduler = new CommandScheduler()

export default commandScheduler