/**
 * Generic lock that tracks multiple lock requests
 * and enqueues then to resolve later if the lock
 * is already held.
 */
class Lock {
    constructor() {
        this.isLocked = false
        this.queue = []
    }

    getLock() {
        const unlock = () => {
            if (this.queue.length === 0) {
                // Nothing waiting for the lock, so it's now unlocked.
                this.isLocked = false
            } else {
                // Get next promise resolve function.
                const resolve = this.queue.shift()
                // Resolve it.
                resolve && resolve(unlock)
            }
        }

        if (!this.isLocked) {
            // Lock is immediately available, so resolve immediately.
            this.isLocked = true
            return Promise.resolve(unlock)
        } else {
            // Cannot give the lock, hence return a promise that will
            // be resolve when the lock can be given
            let promise = new Promise((resolve, reject) => {
                // Queue the resolve function so it can be resolved later.
                this.queue.push(resolve)
            })
            return promise
        }
    }
}

/**
 * Serializes DB access by providing a locking mechanism
 * that should be awaited before performing the DB access.
 */
class TableLock {
    constructor() {
        this.lockMap = new Map()
    }

    getLock(tableName) {
        let lock = this.lockMap.get(tableName)
        if (lock == null) {
            lock = new Lock()
            this.lockMap.set(tableName, lock)
        }

        return lock.getLock()
    }
}

const tableLock = new TableLock()
export default tableLock