import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    outer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '30px',
        marginLeft: theme.spacing(1),
        flexShrink: 0
    },
    day: {
        position: 'absolute',
        top: '5%'
    },
}));

export default function DayAndDate(props) {
    const classes = useStyles();

    return (
        <div className={classes.outer}>
            <Typography variant="overline" component="span" className={classes.day}>{props.day}</Typography>
            <Typography variant="subtitle1">{props.date}</Typography>
        </div>
    );
}