import {
  SHOW_ACCEPT_COOKIES_DIALOG,
  HIDE_ACCEPT_COOKIES_DIALOG,
  DISABLE_ACCEPT_COOKIES_DIALOG,
  ENABLE_ACCEPT_COOKIES_DIALOG
} from '../actions/actions'

const initialState = {
  show: false,
  disabled: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ACCEPT_COOKIES_DIALOG:
      return {
        ...state,
        show: true
      }
    case HIDE_ACCEPT_COOKIES_DIALOG:
      return {
        ...state,
        show: false
      }
    case ENABLE_ACCEPT_COOKIES_DIALOG:
      return {
        ...state,
        disabled: false
      }
    case DISABLE_ACCEPT_COOKIES_DIALOG:
      return {
        ...state,
        disabled: true
      }
    default:
      return state
  }
}

export default reducer
