import TagCacheWriter from 'services/cache/TagCacheWriter'

export default class CreateTagsCommandHandler {
    constructor() {
        this.tagCacheWriter = new TagCacheWriter()
    }

    async execute(tags) {
        // Update the cache.
        await this.tagCacheWriter.bulkPut(tags.map(tag => ({ name: tag })))

        // No need to update server since the tags are created implicity when creating/editing the recipe.
    }
}

export const createTagsCommandHandler = new CreateTagsCommandHandler()