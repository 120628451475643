import React from 'react'
import ReactDOM from 'react-dom'
import history from 'components/app/history'
import log from 'loglevel'
import LogRocket from 'logrocket'
import ReactGA from 'react-ga4'
import AppAuth from './components/app/containers/AppAuth'
import Auth from '@aws-amplify/auth'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers/index'
// eslint-disable-next-line
import noSafariBounce from 'common/noSafariBounce'
import CacheRefreshController from 'services/cache/CacheRefreshController'
import LatestTermsController from 'services/controllers/LatestTermsController'
import OnlineListener from 'services/common/OnlineListener'
import InstallPromptListener from 'services/common/InstallPromptListener'
import ServiceWorkerChangedController from 'services/common/ServiceWorkerChangedController'
import { authObservable, storeObservable } from './common/observables'
import { setProfile, getLatestTermsThunk } from 'actions'
import { getUser } from 'services/common/user'
import {
    loadCookieSettingsThunk,
    setPreviousUrl,
} from 'components/cookies/actions'

if (process.env.NODE_ENV === 'production') {
    log.setDefaultLevel('error')
    LogRocket.init('90rzwl/scranplan-pwa-production')
} else {
    log.setDefaultLevel('warn')
    // Disable for now due to limited sessions.
    // LogRocket.init('90rzwl/scranplan-pwa-dev')
}

ReactGA.initialize('G-Q01CXL4N68')

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk, LogRocket.reduxMiddleware()))
)
storeObservable.setStore(store)

// Automatically inform GA of router location changes.
history.listen((location) => {
    ReactGA.send('pageview', location.pathname)
    store.dispatch(setPreviousUrl(location.pathname))
})

new OnlineListener(store)

new InstallPromptListener(store)

new CacheRefreshController(store)

new LatestTermsController(store)

new ServiceWorkerChangedController()

authObservable.subscribe(async (event) => {
    switch (event) {
        case 'signedIn':
            ReactGA.event({
                category: 'Sign In',
                action: 'Signed In Event',
            })
            const info = await Auth.currentUserInfo()
            if (info.attributes != null) {
                const profile = {
                    givenName: info.attributes['given_name'],
                    familyName: info.attributes['family_name'],
                    email: info.attributes['email'],
                }
                store.dispatch(setProfile(profile))

                LogRocket.identify(info.username, {
                    name: `${info.attributes['given_name']} ${info.attributes['family_name']}`,
                    email: info.attributes['email'],
                })
            }

            const firstSignInAttributeName = 'custom:first_sign_in'
            if (info.attributes && !info.attributes[firstSignInAttributeName]) {
                const user = await getUser()

                // Set Cognito attribute to record that user has signed in for the first time.
                await Auth.updateUserAttributes(user, {
                    [firstSignInAttributeName]: '1',
                })

                ReactGA.event({
                    category: 'Sign In',
                    action: 'Signed In First Time',
                    // Customer more valuable if they signed up for marketing.
                    value: info.attributes['custom:subscribe_marketing']
                        ? 2
                        : 1,
                })
            }
            break
        case 'signedUp':
            ReactGA.event({
                action: 'sign_up',
            })
            break
        case 'signedOut':
            ReactGA.event({
                category: 'Sign Out',
                action: 'Signed Out Event',
            })
            break
        default:
            break
    }
})

store
    .dispatch(getLatestTermsThunk())
    .catch((error) => log.warn('Initial getLatestTermsThunk failed', error))

// Load the cookie settings into redux.
store.dispatch(loadCookieSettingsThunk())

ReactDOM.render(
    <Provider store={store}>
        <AppAuth />
    </Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
