import { serviceWorkerWaitingObservable } from 'common/observables'
import { 
    appUpdateReadyThunk
} from 'actions'
import {
    storeObservable
} from 'common/observables'
import log from 'loglevel'

/**
 * When a new service worker is in the ready state,
 * we can go ahead and notify the app that an update
 * is ready to go ahead.
 */
class ServiceWorkerWaitingController {
    constructor() {
        this.log = log.getLogger('ServiceWorkerWaitingController')
        this.store = null
        this.registration = null
        this.timer = null

        storeObservable.subscribe(store => this.store = store)

        serviceWorkerWaitingObservable.subscribe(registration => {
            this.log.info('Found new service worker in waiting state.')
            this.registration = registration
            this._cancelTimer()
            this._notify()
        })

    }

    _notify() {
        if (this.registration != null && this.registration.waiting != null) {
            this.store.dispatch(appUpdateReadyThunk())
        }
    }

    _cancelTimer() {
        this.timer != null && clearTimeout(this.timer)
        this.timer = null
    }

    /**
     * Sets a timer that notifies that the app is ready.
     */
    notifyLater() {
        this.timer = setTimeout(() => {
            this.log.info('Notifying of new app')
            this._cancelTimer()
            this._notify()
        }, 5 * 60 * 1000)
    }

    /**
     * Updates the app.
     */
    updateApp() {
        if (this.registration != null && this.registration.waiting != null) {
            this.log.info('Updating to new app')
            // Tell the waiting service worker to execute skipWaiting()
            // to become the new controller.
            // Note: the Create React App service worker already defines a listener for this message.
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        }
    }

}

export const serviceWorkerWaitingController = new ServiceWorkerWaitingController()