import React from 'react'
import {
    Box
} from '@material-ui/core'
import { RemainingScreenHeightClamp } from 'components/common/RemainingScreenHeightClamp'
import { ManualTouchScrollable } from 'components/common/ManualTouchScrollable'
import DatePicker from '../date-picker/containers/DatePicker'
import WeekdayList from '../weekday-list/containers/WeekdayList'

export const CalendarPageBody = ({ isDragging, match, enableAddRecipe }) => {
    return (
        <ManualTouchScrollable
            isDragging={isDragging}
        >
            <RemainingScreenHeightClamp>
                <Box
                    flexGrow={1}
                    display='flex'
                    flexDirection='column'
                >
                    <DatePicker/>
                    <WeekdayList
                        match={match}
                        enableAddRecipe={enableAddRecipe}
                    />
                </Box>
            </RemainingScreenHeightClamp>
        </ManualTouchScrollable>
    )
}