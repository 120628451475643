import React from 'react';
import { withStyles  } from '@material-ui/core/styles'
import { Router, Route } from "react-router-dom";
import history from './history'
import { CalendarPage } from '../calendar/pages/CalendarPage'
import RecipesPage from '../recipes/pages/RecipesPage'
import PickRecipePage from '../recipes/pages/containers/PickRecipePage'
import PickDatePage from '../calendar/pages/containers/PickDatePage'
import Toaster from '../common/containers/Toaster';
import { PlannerPage } from '../planner/pages/PlannerPage';
import Settings from '../settings/Settings';
import Profile from 'components/profile/containers/Profile';
import NewRecipePage from 'components/recipes/pages/containers/NewRecipePage';
import EditRecipePage from 'components/recipes/pages/containers/EditRecipePage';
import BusyModal from 'components/common/containers/BusyModal'
import ErrorModal from 'components/common/containers/ErrorModal'
import CenterWrapper from 'components/common/CenterWrapper'
import AppUpdateDialog from 'components/app/containers/AppUpdateDialog'
import AcceptCookiesBanner from 'components/cookies/containers/AcceptCookiesBanner'
import CookieSettingsPage from 'components/cookies/containers/CookieSettingsPage'

export const PAGE_NAME_APP = 'PAGE_NAME_APP'

const styles = theme => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%'
  }
})

const StyledAcceptCookiesBanner = withStyles(styles)(({ classes }) => {
  return <AcceptCookiesBanner classes={{ root: classes.root }} />
})

class App extends React.Component {

  render() {
    const { authState } = this.props

    if (authState === 'signedIn') {
      return (
        <CenterWrapper>
          <Router history={history}>
            <Route path="/" exact component={PlannerPage} />
            <Route path="/calendar" exact component={CalendarPage} />
            <Route path="/recipes" exact component={RecipesPage} />
            <Route path="/calendar/recipes/:stamp" render={(props) => <PickRecipePage enableAddButton {...props}/>} />
            <Route path="/recipes/calendar/:id" render={(props) => <PickDatePage enableAddRecipe {...props}/>} />
            <Route path="/recipes/new" component={NewRecipePage} />
            <Route path="/recipes/edit/:id" component={EditRecipePage} />
            <Route path="/settings" component={Settings} />
            <Route path="/profile" component={Profile} />
            <Route path="/cookie-settings" component={CookieSettingsPage} />
            <Toaster/>
            <BusyModal/>
            <ErrorModal/>
            <AppUpdateDialog />
            <StyledAcceptCookiesBanner />
          </Router>
        </CenterWrapper>
      )
    } else {
      return null
    }
  }
}

export default App;
