import { hash } from 'immutable'

class MuxPage {
    constructor(name, priority) {
        this.name = name
        this.priority = priority
    }

    equals(other) {
        return other != null && (other instanceof MuxPage) && other.name.toLowerCase() === this.name.toLowerCase()
    }

    hashCode() {
        return hash(this.name)
    }

    static comparator(a, b) {
        return a.priority - b.priority
    }
}

export default MuxPage