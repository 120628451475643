import { connect } from 'react-redux';
import SortOptions from '../SortOptions'
import { setSortOptionsThunk } from '../actions'

const mapStateToProps = state => ({
    sortOptions: state.sortOptions
});

const mapDispatchToProps = dispatch => ({
    setSortOptions: options => dispatch(setSortOptionsThunk(options))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SortOptions);