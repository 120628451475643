import RecipeCacheWriter from 'services/cache/RecipeCacheWriter'
import commandScheduler from 'services/api/commands/commandScheduler'

export default class CreateRecipeCommandHandler {
    constructor() {
        this.recipeCacheWriter = new RecipeCacheWriter()
    }

    async execute(recipe) {
        await this.recipeCacheWriter.add(recipe)
        await commandScheduler.createRecipe(recipe)
    }
}

export const createRecipeCommandHandler = new CreateRecipeCommandHandler()