import { ValidationError } from 'yup'
import log from 'loglevel'

export const checkErrors = (form, validationSchema) => {
    try {
        validationSchema.validateSync(form)

        // No errors.
        return {}
    } catch (error) {
        if (error instanceof ValidationError) {
            // Expect only single error at a time (i.e. abortEarly = true)
            return {
                [error.path]: error.message
            }
        }

        // Not sure what to do here.
        log.error('Error on validation', error)
    }
}