import React from 'react'
import awsconfig from '../../aws-exports'
import {
  Authenticator,
  ConfirmSignUp,
  Greetings,
  SignIn,
  SignUp,
} from 'aws-amplify-react'
import { CssBaseline } from '@material-ui/core'
import CustomSignIn from 'components/auth/CustomSignIn'
import CustomSignUp from 'components/auth/containers/CustomSignUp'
import CustomConfirmSignUp from '../auth/CustomConfirmSignUp'
import AppMux from 'components/app/containers/AppMux'
import App, { PAGE_NAME_APP } from './App'
import AcceptTermsAndConditions, {
  PAGE_NAME_NEW_TERMS,
} from 'components/auth/containers/AcceptTermsAndConditions'
import { ThemeProvider } from '@material-ui/core/styles'
import { PasswordHolder } from 'components/auth/PasswordHolder'

const AppAuth = ({ theme }) => {
  return (
    <React.Fragment>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Authenticator
          hide={[SignIn, SignUp, Greetings, ConfirmSignUp]}
          amplifyConfig={awsconfig}
          usernameAttributes="email"
        >
          <CustomSignIn />
          <PasswordHolder>
            <CustomSignUp usernameAttributes="email" />
            <CustomConfirmSignUp />
          </PasswordHolder>
          <AppMux>
            <App pageName={PAGE_NAME_APP} pagePriority={0} defaultPage={true} />
            <AcceptTermsAndConditions
              pageName={PAGE_NAME_NEW_TERMS}
              pagePriority={1}
            />
          </AppMux>
        </Authenticator>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default AppAuth
