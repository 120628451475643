import BaseQueryHandler from './BaseQueryHandler'
import HttpHelper from '../../common/HttpHelper'
import SettingsCacheReader from 'services/cache/SettingsCacheReader'
import SettingsCacheWriter from 'services/cache/SettingsCacheWriter'

export default class GetSettingsQueryHandler extends BaseQueryHandler{
    constructor() {
        super()
        this.httpHelper = new HttpHelper()
        this.settingsCacheReader = new SettingsCacheReader()
        this.settingsCacheWriter = new SettingsCacheWriter()
    }

    async fetchCache() {
        return await this.settingsCacheReader.get()
    }

    async fetchHttp() {
        const response = await this.httpHelper.get('user')
        this.checkHttpResponse(response)
        return await response.json()
    }

    async updateCache(settings) {
        await this.settingsCacheWriter.put(settings)
    }
}

export const getSettingsQueryHandler = new GetSettingsQueryHandler()