import React from 'react';
import { IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import AppBar from 'components/common/AppBar'
import RecipeFormPage from '../editor/RecipeFormPage'
import BookmarkButton  from '../editor/containers/BookmarkButton'
import RecipeNotFound from '../editor/RecipeNotFound'

const EditRecipePage = ({
    history,
    match,
    recipes,
    editRecipe,
    allTags,
}) => {

    const recipe = recipes[match.params.id];

    const [showErrorDialog, setShowError] = React.useState(false)

    const [ isEditing, setIsEditing ] = React.useState(false)

    const onCloseErrorDialog = React.useCallback(() => {
        setShowError(false)
    }, [setShowError])

    const goBack = React.useCallback(() => {
        history.goBack();
    }, [history]);

    const onSave = React.useCallback(formData => {
        if (formData.title.trim().toLowerCase() !== recipe.title.trim().toLowerCase() &&
            Object.values(recipes).find(r => r.title.trim().toLowerCase() === formData.title.trim().toLowerCase()) != null) {
            setShowError(true)
            return
        }
        editRecipe(recipe, { ...formData, id: recipe.id  });
        goBack();
    }, [editRecipe, goBack, recipe, recipes, setShowError]);

    const onClick = React.useCallback(() => {
        setIsEditing(true)
    }, [setIsEditing])

    const editButton = isEditing || recipe == null || !recipe.isPersonal ?
        null
        : (<IconButton
        color="inherit"
        onClick={ onClick }
        >
        <EditIcon/>
        </IconButton>
        )

    return (
        <div>
            <AppBar
            returnable
            title="Edit recipe">
                <BookmarkButton recipe={recipe} inheritColor/>
                { editButton }
            </AppBar>
            {recipe != null ?
            <RecipeFormPage 
                isDisabled={ !isEditing }
                onCancel={goBack}
                onSave={onSave}
                recipe={recipe}
                allTags={allTags}
                showDialog={showErrorDialog}
                onCloseDialog={onCloseErrorDialog}
            />
            : <RecipeNotFound/>}
        </div>
    );
};

export default EditRecipePage;