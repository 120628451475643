import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Switch,
    Typography,
} from '@material-ui/core'
import ReactAutosuggest from 'common/ReactAutosuggest'
import { getSuggestions } from 'common/'
import CaseInsensitiveString from 'common/CaseInsensitiveString'
import ReactGA from 'react-ga4'
import SortOptions from './containers/SortOptions'

const useStyles = makeStyles((theme) => ({
    headerBar: {
        backgroundColor: theme.palette.primary.dark,
        height: 20,
    },
    mainBar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    listItem: {
        padding: `0 ${theme.spacing(1)}px 0 ${theme.spacing(1)}px`,
    },
}))

const SearchOptions = ({ tags, searchOptions, setSearchOptions }) => {
    const classes = useStyles()

    const onGetSuggestions = React.useCallback(
        (value) => {
            return getSuggestions(value, tags, searchOptions.tags)
        },
        [tags, searchOptions]
    )

    const onChange = React.useCallback(
        (el) => {
            setSearchOptions({
                ...searchOptions,
                [el.target.value]: el.target.checked,
            })
            if (el.target.checked) {
                switch (el.target.value) {
                    case 'isPersonal':
                        ReactGA.event({
                            category: 'Recipes',
                            action: 'Filter By Personal',
                        })
                        break
                    case 'isBookmarked':
                        ReactGA.event({
                            category: 'Recipes',
                            action: 'Filter By Bookmarked',
                        })
                        break
                    default:
                        break
                }
            }
        },
        [setSearchOptions, searchOptions]
    )

    const onAddChip = React.useCallback(
        (chip) => {
            const newTag = new CaseInsensitiveString(chip)
            if (chip && !searchOptions.tags.has(newTag) && tags.has(newTag)) {
                setSearchOptions({
                    ...searchOptions,
                    tags: searchOptions.tags.union(tags.intersect([newTag])),
                })
                ReactGA.event({
                    category: 'Recipes',
                    action: 'Add Search Chip',
                    label: chip,
                })
            }
        },
        [setSearchOptions, searchOptions, tags]
    )

    const onDeleteChip = React.useCallback(
        (chip) => {
            setSearchOptions({
                ...searchOptions,
                tags: searchOptions.tags.delete(
                    new CaseInsensitiveString(chip)
                ),
            })
        },
        [setSearchOptions, searchOptions]
    )

    return (
        <Box>
            <Paper square classes={{ root: classes.headerBar }}></Paper>
            <Paper square classes={{ root: classes.mainBar }}>
                <SortOptions />
                <Divider />
                <Typography classes={{ root: classes.listItem }}>
                    Search
                </Typography>
                <List>
                    <ListItem classes={{ root: classes.listItem }}>
                        <ListItemIcon>
                            <Switch
                                checked={searchOptions.isPersonal}
                                onChange={onChange}
                                value="isPersonal"
                                color="secondary"
                                inputProps={{
                                    'aria-label': 'personal recipes only',
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText primary="Personal" />
                    </ListItem>
                    <ListItem classes={{ root: classes.listItem }}>
                        <ListItemIcon>
                            <Switch
                                checked={searchOptions.isBookmarked}
                                onChange={onChange}
                                value="isBookmarked"
                                color="secondary"
                                inputProps={{
                                    'aria-label': 'bookmarked recipes',
                                }}
                            />
                        </ListItemIcon>
                        <ListItemText primary="Bookmarked" />
                    </ListItem>
                    <ListItem classes={{ root: classes.listItem }}>
                        <ReactAutosuggest
                            fullWidth
                            variant="outlined"
                            label="Tags"
                            placeholder="Tags"
                            chips={searchOptions.tags
                                .map((tag) => tag.value)
                                .toArray()}
                            onAddChip={onAddChip}
                            onDeleteChip={onDeleteChip}
                            onGetSuggestions={onGetSuggestions}
                            enableContrastText
                        />
                    </ListItem>
                </List>
            </Paper>
        </Box>
    )
}

export default SearchOptions
