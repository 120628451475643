import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import CalendarPageBody from '../../calendar/pages/containers/CalendarPageBody'
import RecipesBody from 'components/recipes/pages/containers/RecipesBody'

const useStyles = makeStyles(theme => ({
    leftBox: {
        flexBasis: '50%'
    },
    rightBox: {
        flexBasis: '50%'
    },
}));

export const PlannerPageBody = ({ isDragging }) => {
    const classes = useStyles();

    return (
        <Box display="flex">
            <Box className={classes.leftBox} flexGrow={1}>
                <RecipesBody enableDrag />
            </Box>
            <Box className={classes.rightBox} flexGrow={1}>
                <CalendarPageBody/>
            </Box>
        </Box>
    );
}
