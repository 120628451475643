import React from 'react'
import { Container, Paper } from '@material-ui/core'

const CenterWrapper = ( { children } ) => {
    return (
        <Container disableGutters maxWidth="sm">
            <Paper square>
                { children }
            </Paper>
        </Container>
    )
}

export default CenterWrapper