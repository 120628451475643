import React from 'react'
import clsx from 'clsx'

/**
 * Adds manual touch scrolling to the given child element.
 * 
 * This is useful when you have to disable the browser scrolling
 * for the child, e.g. to support drag and drop, but you need
 * to support scrolling as well.
 * 
 * Manual touch dragging does not support fling gestures.
 */
export const ManualTouchScrollable = React.forwardRef(({
    isDragging,
    children
}, ref) => {
    const [clientY, setClientY] = React.useState(null)

    // Create a ref to use for ourself in case a ref is has not been passed in.
    const ownRef = React.useRef(null)
    const scrollRef = ref != null ? ref : ownRef

    const onTouchStart = React.useCallback(e => {
        const { clientY } = getEvent(e)
        setClientY(clientY)
    }, [setClientY])

    const onTouchMove = React.useCallback(e => {
        if (!isDragging) {
            const { clientY: newClientY } = getEvent(e)
            scrollRef.current.scrollBy(0, clientY - newClientY)
            setClientY(newClientY)
        }
    }, [isDragging, clientY, setClientY, scrollRef])

    React.Children.only(children)

    const child = React.useMemo(() => React.cloneElement(children, {
        ref: scrollRef,
        classes: { root: clsx({ 'disable-safari-touch-bounce': isDragging }) },
        onTouchStart,
        onTouchMove
    }), [children, scrollRef, onTouchStart, onTouchMove, isDragging])

    return (
        <>
            {child}
        </>
    )
})

const getEvent = e => (e.targetTouches && e.targetTouches[0]) || (e.changedTouches && e.changedTouches[0]) || e
