import { Observable, Subject } from 'rxjs'

class AppBarResizeObservable {
    constructor() {
        this.subject = new Subject(null)
        Observable.create(observer => this.observer = observer).subscribe(this.subject)
    }

    subscribe = fn => {
        return this.subject.subscribe(fn)
    }

    onResize(width, height) {
        this.observer.next({ width, height })
    }
}

export const appBarResizeObservable = new AppBarResizeObservable()