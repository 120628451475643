import BaseQueryHandler from './BaseQueryHandler'
import HttpHelper from '../../common/HttpHelper'
import BookmarkCacheReader from 'services/cache/BookmarkCacheReader'
import BookmarkCacheWriter from 'services/cache/BookmarkCacheWriter'
import { Set as ImmutableSet } from 'immutable'

export default class GetAllBookmarksQueryHandler extends BaseQueryHandler {
    constructor() {
        super()
        this.httpHelper = new HttpHelper()
        this.bookmarkCacheReader = new BookmarkCacheReader()
        this.bookmarkCacheWriter = new BookmarkCacheWriter()
    }

    transformForClient(data) {
        // Client expects array of strings that are the tag names.
        return data.map(val => val.recipeId)
    }

    async fetchCache() {
        return await this.bookmarkCacheReader.getAll()
    }

    async fetchHttp() {
        const response = await this.httpHelper.get('bookmarks')
        this.checkHttpResponse(response)
        return await response.json()
    }

    async updateCache(bookmarks, cachedBookmarks) {
        const cachedRecipeIds = ImmutableSet((cachedBookmarks || []).map(bookmark => bookmark.recipeId))
        const recipeIds = ImmutableSet((bookmarks || []).map(bookmark => bookmark.recipeId))
        const recipeIdsToDelete = cachedRecipeIds.subtract(recipeIds).toArray()
        await this.bookmarkCacheWriter.bulkDelete(recipeIdsToDelete)
        await this.bookmarkCacheWriter.bulkPut(bookmarks)
    }
}

export const getAllBookmarksQueryHandler = new GetAllBookmarksQueryHandler()