import {
    checkLatestTermsThunk,
} from 'actions/'
import { TimerObservable, ONE_HOUR_MILLISECONDS } from 'common/observables'
import OnlineSignedInController from 'services/common/OnlineSignedInController'

export default class LatestTermsController extends OnlineSignedInController {
    constructor(store) {
        super()

        this.store = store

        // Refresh latest terms periodically.
        this.timerObservable = new TimerObservable(ONE_HOUR_MILLISECONDS)
        this.timerObservable.subscribe(() => {
            this.onUpdate()
        })
    }

    async onUpdate() {
        if (this.state.isSignedIn && this.state.isOnline) {
            await this.store.dispatch(checkLatestTermsThunk())
        }
    }
}