import React from 'react'
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
    Button,
} from '@material-ui/core'

const ErrorModal = ( { open, message, closeErrorModal } ) => {
    return (
        <Dialog
        open={ open }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Error</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    { message }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeErrorModal} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ErrorModal