import { connect } from 'react-redux';
import RecipeList from '../RecipeList'
import { setIsDragging } from '../actions'

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    setIsDragging: isDragging => dispatch(setIsDragging(isDragging))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RecipeList);