import React from 'react'
import SaveCancelButtons from 'components/common/SaveCancelButtons'
import ReactGA from 'react-ga4'

const ProfileFormButtons = ({
    setProfileEditing,
    setProfile,
    formData,
    isDirty,
    isValid,
}) => {
    const onCancel = React.useCallback(() => {
        setProfileEditing(false)
    }, [setProfileEditing])

    const onSave = React.useCallback(() => {
        setProfile(formData)
        ReactGA.event({
            category: 'Profile',
            action: 'Save',
        })
    }, [setProfile, formData])

    return (
        <React.Fragment>
            <SaveCancelButtons
                onCancel={onCancel}
                onSave={onSave}
                isDirty={isDirty && isValid}
            />
        </React.Fragment>
    )
}

export default ProfileFormButtons
