import { bookmarkRecipeThunk, unbookmarkRecipeThunk } from 'actions'
import { connect } from 'react-redux';
import BookmarkButton from '../BookmarkButton' 

const mapStateToProps = state => ({
    bookmarks: state.user.bookmarks,
});

const mapDispatchToProps = dispatch => ({
    bookmarkRecipe: recipe => dispatch(bookmarkRecipeThunk(recipe)),
    unbookmarkRecipe: recipe => dispatch(unbookmarkRecipeThunk(recipe)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BookmarkButton);