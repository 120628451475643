import React from 'react'
import { RemainingScreenHeightClamp } from 'components/common/RemainingScreenHeightClamp'
import { ManualTouchScrollable } from 'components/common/ManualTouchScrollable'
import PickRecipeSearch from 'components/recipes/search/containers/SearchableRecipeList'

const withManualScroll = (element, isDragging) => {
    return (
        <ManualTouchScrollable
            isDragging={isDragging}
        >
            {element}
        </ManualTouchScrollable>
    )
}

export const RecipesBody = ({
    isDragging,
    enableDrag,
    enableAddButton,
    onSelectRecipe
}) => {
    const pickRecipeSearch = (
        <RemainingScreenHeightClamp>
            <PickRecipeSearch
                enableDrag={enableDrag}
                enableAddButton={enableAddButton}
                onSelectRecipe={onSelectRecipe}
            />
        </RemainingScreenHeightClamp>
    )

    const element = enableDrag ? withManualScroll(pickRecipeSearch, isDragging) : pickRecipeSearch

    return (
        <>
            {element}
        </>
    )
}