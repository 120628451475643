import React from 'react'
import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
      },
}))

const SaveCancelButtons = ({ isDirty, onSave, onCancel }) => {
    const classes = useStyles()
    
   return (
    <Box display="flex" justifyContent="flex-end">
        <Button
        className={classes.button}
        variant="contained"
        onClick={onCancel}>
            Cancel
        </Button>
        <Button
        className={classes.button}
        variant="contained"
        color="primary"
        disabled={!isDirty}
        onClick={onSave}>
            Save
        </Button>
    </Box>
   ) 
}

export default SaveCancelButtons