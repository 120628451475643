import { combineReducers } from 'redux';
import planner from './planner';
import entities from './entities';
import app from './app';
import user from './user';
import { sortOptions } from 'components/recipes/search/reducers/sortOptions'
import { recipeList } from 'components/recipes/list/reducers/recipeList'
import acceptCookiesDialog from 'components/cookies/reducer/acceptCookiesDialog'
import cookieSettings from 'components/cookies/reducer/cookieSettings'
import urls from 'components/cookies/reducer/urls'

export default combineReducers({
    planner,
    entities,
    app,
    user,
    acceptCookiesDialog,
    cookieSettings,
    urls,
    sortOptions,
    recipeList
});
