import React from 'react'
import { ConfirmSignUp } from 'aws-amplify-react'
import Auth from '@aws-amplify/auth'
import { Box, Button, Link, TextField, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { object, string } from 'yup'
import { checkErrors } from './common'
import CenterWrapper from 'components/common/CenterWrapper'
import ReactGA from 'react-ga4'

const REQUIRED_FIELD_MESSAGE = 'This is a required field.'

const styles = (theme) => ({
    button: {
        marginTop: theme.spacing(3),
    },
    backToSignIn: {
        marginTop: theme.spacing(2),
    },
})

class CustomConfirmSignUp extends ConfirmSignUp {
    constructor(props) {
        super(props)
        this.classes = props.classes
        this._validAuthStates = ['confirmSignUp']

        // Yup validation schema.
        this.validationSchema = object().shape({
            code: string().required(REQUIRED_FIELD_MESSAGE),
        })

        this.state = {
            form: {
                email: '',
                code: '',
            },
            error: {},
        }
    }

    onClickConfirm = () => {
        const error = checkErrors(this.state.form, this.validationSchema)
        this.setState({ error })

        if (Object.keys(error).length === 0) {
            var username = this.usernameFromAuthData() || this.inputs.username
            var code = this.inputs.code
            Auth.confirmSignUp(username, code)
                .then(
                    function () {
                        return Auth.signIn(username, this.props.password)
                    }.bind(this)
                )
                .then(
                    function (user) {
                        this.props.onSetPassword('')
                        this.changeState('signedIn', user)
                    }.bind(this)
                )
                .catch(
                    function (err) {
                        return this.error(err)
                    }.bind(this)
                )
            ReactGA.event({
                category: 'Confirm Sign Up',
                action: 'Send Confirmation Code',
            })
        }
    }

    onClickResendCode = () => {
        super.resend()
        ReactGA.event({
            category: 'Confirm Sign Up',
            action: 'Resend Confirmation Code',
        })
    }

    onClickBackToSignIn = () => {
        super.changeState('signIn')
        ReactGA.event({
            category: 'Confirm Sign Up',
            action: 'Click Sign In',
        })
    }

    onChange = (e) => {
        const form = { ...this.state.form, [e.target.name]: e.target.value }
        const error = checkErrors(form, this.validationSchema)
        this.setState({ form, error })
        this.handleInputChange(e)
    }

    showComponent(theme) {
        const username = this.usernameFromAuthData() || this.inputs.username

        return (
            <CenterWrapper>
                <Box display="flex" flexDirection="column" margin={2}>
                    <Typography color="textPrimary">Confirm Sign Up</Typography>
                    <TextField
                        disabled
                        margin="normal"
                        id="username"
                        name="username"
                        label="Email"
                        type="email"
                        variant="outlined"
                        value={username}
                    />
                    <TextField
                        margin="normal"
                        type="text"
                        id="code"
                        name="code"
                        label="Confirmation code"
                        variant="outlined"
                        value={this.state.form.code}
                        onChange={this.onChange}
                        error={
                            this.state.error && this.state.error.code != null
                        }
                        helperText={this.state.error && this.state.error.code}
                    />
                    <Typography variant="subtitle2" color="textSecondary">
                        Lost your code?{' '}
                        <Link
                            href="#"
                            color="primary"
                            onClick={this.onClickResendCode}
                        >
                            Resend Code
                        </Link>
                    </Typography>
                    <Button
                        className={this.classes.button}
                        variant="contained"
                        color="primary"
                        onClick={this.onClickConfirm}
                    >
                        Confirm
                    </Button>

                    <Link
                        className={this.classes.backToSignIn}
                        href="#"
                        color="primary"
                        onClick={this.onClickBackToSignIn}
                    >
                        Back to Sign In
                    </Link>
                </Box>
            </CenterWrapper>
        )
    }
}

export default withStyles(styles)(CustomConfirmSignUp)
