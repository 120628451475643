import React from 'react'
import { IconButton } from '@material-ui/core'
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import BookmarkIcon from '@material-ui/icons/Bookmark';

const BookmarkButton = ({ recipe, inheritColor, bookmarks, bookmarkRecipe, unbookmarkRecipe }) => {
    const bookmarked = recipe && bookmarks.has(recipe.id)

    const onBookmark = React.useCallback(() => {
        bookmarked ? unbookmarkRecipe(recipe) : bookmarkRecipe(recipe)
    }, [bookmarkRecipe, unbookmarkRecipe, recipe, bookmarked]);

    return (
        <IconButton
        onClick={onBookmark}
        color={inheritColor ? "inherit" : "default"}>
            {bookmarked ? <BookmarkIcon/> : <BookmarkBorderOutlinedIcon/>}
        </IconButton>
    )
}

export default BookmarkButton