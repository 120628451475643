import BaseQueryHandler from './BaseQueryHandler'
import HttpHelper from '../../common/HttpHelper'
import { object, string, date } from 'yup'

const termsSchema = object().shape({
    id: string().required().matches(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i),
    uri: string().required().url(),
    dateCreated: date().required(),
})

const textSchema = string().required()

export default class GetLatestTermsQueryHandler extends BaseQueryHandler {
    constructor() {
        super()
        this.httpHelper = new HttpHelper()
    }

    async fetchHttp() {
        const response = await this.httpHelper.get('terms/latest', true)
        this.checkHttpResponse(response)
        let terms = await response.json()
        terms = await termsSchema.validate(terms)
        const textResponse = await fetch(terms.uri)
        this.checkHttpResponse(textResponse)
        const termsText = await textResponse.text()
        await textSchema.validate(termsText)
        return { ...terms, text: termsText }
    }
}

export const getLatestTermsQueryHandler = new GetLatestTermsQueryHandler()