import React, { forwardRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
    Collapse,
    Paper
} from '@material-ui/core'
import SearchOptions from './SearchOptions'
import SearchInput from './SearchInput'

const useStyles = makeStyles(theme => ({
    root: {
        paddingBottom: theme.spacing(0.25)
    }
}))

export const RecipeSearch = forwardRef(({
    hasSearchOptions,
    onChange,
    onClearSearch,
    open,
    search,
    searchOptions,
    setOpen,
    setSearchOptions,
    tags,
    toggleSearchOptions
},
ref) => {
    const classes = useStyles()

    return (
        <Paper
            square
            elevation={4}
            classes={{ root: classes.root }}
        >
            <SearchInput
            search={search}
            onSearchChange={onChange}
            onSearchOptions={toggleSearchOptions}
            hasSearchOptions={hasSearchOptions}
            onClearSearch={onClearSearch}
            searchOptionsOpen={open}
            ref={ref}
            />
            <Collapse in={open}>
                <SearchOptions
                open={open}
                tags={tags}
                setOpen={setOpen}
                searchOptions={searchOptions}
                setSearchOptions={setSearchOptions}/>
            </Collapse>
        </Paper>
    )
})
