import uuidv4 from 'uuid/v4';
import loglevel from 'loglevel'
import {
    SET_PLANNER_WEEK,
    PREV_PLANNER_WEEK,
    NEXT_PLANNER_WEEK,
    DELETE_PLANNED_RECIPE,
    SELECT_RECIPE_FOR_DAY,
    CLEAR_TOAST_MESSAGE,
    SET_TOAST_MESSAGE,
    SET_WEEK_START,
    CREATE_NEW_RECIPE,
    EDIT_RECIPE,
    CREATE_NEW_TAGS,
    BOOKMARK_RECIPE,
    UNBOOKMARK_RECIPE,
    SET_ALL_RECIPES,
    SET_ALL_TAGS,
    SET_FULL_CALENDAR,
    SET_ALL_BOOKMARKS,
    SET_ONLINE_STATUS,
    SET_REFRESHING_KEY,
    CLEAR_REFRESHING_KEY,
    SET_PROFILE,
    SET_PROFILE_EDITING,
    OPEN_BUSY_MODAL,
    CLOSE_BUSY_MODAL,
    OPEN_ERROR_MODAL,
    CLOSE_ERROR_MODAL,
    SET_LATEST_TERMS_FETCHING,
    SET_LATEST_TERMS_ERROR,
    SET_LATEST_TERMS,
    SET_ACCEPT_NEW_TERMS_PENDING,
    SET_ACCEPT_NEW_TERMS_ERROR,
    SET_THEME_CONFIG,
    REGISTER_APP_MUX_PAGES,
    PUSH_APP_MUX_PAGE,
    POP_APP_MUX_PAGE,
    SET_INSTALL_PROMPT,
    SHOW_APP_UPDATE_DIALOG,
    SET_SUBSCRIBED_TO_MARKETING
} from './actions'
import { PAGE_NAME_NEW_TERMS } from 'components/auth/containers/AcceptTermsAndConditions'
import {
    getSerializableRecipe,
    getSerializableTags,
} from 'common/serializeHelpers'

import { getAllRecipesQueryHandler } from 'services/api/queries/handlers/GetAllRecipesQueryHandler'
import { getAllTagsQueryHandler } from 'services/api/queries/handlers/GetAllTagsQueryHandler'
import { getFullCalendarQueryHandler } from 'services/api/queries/handlers/GetFullCalendarQueryHandler'
import { getSettingsQueryHandler } from 'services/api/queries/handlers/GetSettingsQueryHandler'
import { getAllBookmarksQueryHandler } from 'services/api/queries/handlers/GetAllBookmarksQueryHandler'
import { getLatestTermsQueryHandler } from 'services/api/queries/handlers/GetLatestTermsQueryHandler'
import { checkNewTermsQueryHandler } from 'services/api/queries/handlers/CheckNewTermsQueryHandler'
import { editSettingsCommandHandler } from 'services/api/commands/handlers/EditSettingsCommandHandler'
import { createRecipeCommandHandler } from 'services/api/commands/handlers/CreateRecipeCommandHandler'
import { editRecipeCommandHandler } from 'services/api/commands/handlers/EditRecipeCommandHandler'
import { planRecipeCommandHandler } from 'services/api/commands/handlers/PlanRecipeCommandHandler'
import { deletePlannedRecipeCommandHandler } from 'services/api/commands/handlers/DeletePlannedRecipeCommandHandler'
import { bookmarkRecipeCommandHandler } from 'services/api/commands/handlers/BookmarkRecipeCommandHandler'
import { unbookmarkRecipeCommandHandler } from 'services/api/commands/handlers/UnbookmarkRecipeCommandHandler'
import { createTagsCommandHandler } from 'services/api/commands/handlers/CreateTagsCommandHandler'
import { acceptNewTermsCommandHandler } from 'services/api/commands/handlers/AcceptNewTermsCommandHandler'

import { serviceWorkerWaitingController } from 'services/common/ServiceWorkerWaitingController'

const log = loglevel.getLogger('actions')

export const setPlannerWeek = weekDate => ({
    type: SET_PLANNER_WEEK,
    weekDate
});

export const prevPlannerWeek = () => ({
    type: PREV_PLANNER_WEEK
});

export const nextPlannerWeek = () => ({
    type: NEXT_PLANNER_WEEK
});

export const setWeekStart = (weekStart) => ({
    type: SET_WEEK_START,
    weekStart
});

export const deletePlannedRecipe = (stamp, recipe) => ({
    type: DELETE_PLANNED_RECIPE,
    stamp,
    recipe,
});

export const selectRecipeForDay = (stamp, recipeId) => ({
    type: SELECT_RECIPE_FOR_DAY,
    stamp,
    recipeId
});

export const clearToastMessage = () => ({
    type: CLEAR_TOAST_MESSAGE
});

export const setToastMessage = (message) => ({
    type: SET_TOAST_MESSAGE,
    message
});

export const createNewRecipe = (recipe) => ({
    type: CREATE_NEW_RECIPE,
    recipe
});

export const editRecipe = (recipe) => ({
    type: EDIT_RECIPE,
    recipe
});

export const createNewTags = (tags) => ({
    type: CREATE_NEW_TAGS,
    tags
});

export const bookmarkRecipe = (recipe) => ({
    type: BOOKMARK_RECIPE,
    recipe
})

export const unbookmarkRecipe = (recipe) => ({
    type: UNBOOKMARK_RECIPE,
    recipe
})

export const setAllRecipes = (recipes) => ({
    type: SET_ALL_RECIPES,
    recipes
})

export const setAllTags = (tags) => ({
    type: SET_ALL_TAGS,
    tags
})

export const setFullCalendar = (calendar) => ({
    type: SET_FULL_CALENDAR,
    calendar
})

export const setAllBookmarks = (bookmarks) => ({
    type: SET_ALL_BOOKMARKS,
    bookmarks
})

export const setOnlineStatus = (isOnline) => ({
    type: SET_ONLINE_STATUS,
    isOnline,
})

export const setRefreshingKey = (key) => ({
    type: SET_REFRESHING_KEY,
    key,
})

export const clearRefreshingKey = (key) => ({
    type: CLEAR_REFRESHING_KEY,
    key,
})

export const setProfile = profile => ({
    type: SET_PROFILE,
    profile,
})

export const setProfileEditing = isEditing => ({
    type: SET_PROFILE_EDITING,
    isEditing,
})

export const openBusyModal = message => ({
    type: OPEN_BUSY_MODAL,
    message,
})

export const closeBusyModal = () => ({
    type: CLOSE_BUSY_MODAL,
})

export const openErrorModal = message => ({
    type: OPEN_ERROR_MODAL,
    message,
})

export const closeErrorModal = () => ({
    type: CLOSE_ERROR_MODAL,
})

export const setLatestTermsFetching = isFetching => ({
    type: SET_LATEST_TERMS_FETCHING,
    isFetching,
})

export const setLatestTermsError = error => ({
    type: SET_LATEST_TERMS_ERROR,
    error,
})

export const setLatestTerms = terms => ({
    type: SET_LATEST_TERMS,
    terms,
})

export const setAcceptNewTermsPending = isPending => ({
    type: SET_ACCEPT_NEW_TERMS_PENDING,
    isPending,
})

export const setAcceptNewTermsError = isError => ({
    type: SET_ACCEPT_NEW_TERMS_ERROR,
    isError,
})

export const registerAppMuxPages = pages => ({
    type: REGISTER_APP_MUX_PAGES,
    pages,
})

export const pushAppMuxPage = pageName => ({
    type: PUSH_APP_MUX_PAGE,
    pageName,
})

export const popAppMuxPage = pageName => ({
    type: POP_APP_MUX_PAGE,
    pageName,
})

export const setThemeConfig = config => ({
    type: SET_THEME_CONFIG,
    config,
})

export const setInstallPrompt = installPrompt => ({
    type: SET_INSTALL_PROMPT,
    installPrompt,
})

export const showAppUpdateDialog = show =>({
    type: SHOW_APP_UPDATE_DIALOG,
    show
})

export const setSubscribedToMarketing = subscribed =>({
    type: SET_SUBSCRIBED_TO_MARKETING,
    subscribed
})

/* Thunks */

/**
 * A new service worker is ready to take control.
 * Notify the user that an app update is ready.
 */
export const appUpdateReadyThunk = () => {
    return dispatch => {
        dispatch(showAppUpdateDialog(true))
    }
}

export const performAppUpdateThunk = defer => {
    return dispatch => {
        dispatch(showAppUpdateDialog(false))
        // Small delay otherwise the dialog doesn't seems to close.
        setTimeout(() => {
            if (defer) {
                serviceWorkerWaitingController.notifyLater()
            } else {
                serviceWorkerWaitingController.updateApp()
            }
        }, 100)
    }
}

export const showInstallPromptThunk = () => {
    return async (dispatch, getState) => {
        const installPrompt = getState().app.installPrompt
        if (installPrompt != null) {
            installPrompt.prompt()
            installPrompt.userChoice.then(result => {
                if (result.outcome === 'accepted') {
                    log.info('User accepted the install prompt');
                } else {
                    log.info('User dismissed the install prompt');
                }
                // Prompt has been used, so clear it.
                dispatch(setInstallPrompt(null))
            })
        } else {
            log.warn('Warning: app requested installation prompt, but it was null!')
        }
    }
}

export const getAllRecipes = () => {
    return async dispatch => {
        dispatch(setRefreshingKey('getAllRecipes'))
        try {
            const cacheResult = await getAllRecipesQueryHandler.fetch()
            cacheResult.data != null && dispatch(setAllRecipes(cacheResult.data))
            const httpResult = await cacheResult.httpResult
            httpResult.data != null && dispatch(setAllRecipes(httpResult.data))
        } catch (e) {
            log.warn('Problem fetching recipes', e)
        } finally {
            dispatch(clearRefreshingKey('getAllRecipes'))
        }
    }
}

export const getAllTags = () => {
    return async dispatch => {
        dispatch(setRefreshingKey('getAllTags'))
        try {
            const cacheResult = await getAllTagsQueryHandler.fetch()
            cacheResult.data != null && dispatch(setAllTags(cacheResult.data))
            const httpResult = await cacheResult.httpResult
            httpResult.data != null && dispatch(setAllTags(httpResult.data))
        } catch (e) {
            log.warn('Problem fetching tag', e)
        } finally {
            dispatch(clearRefreshingKey('getAllTags'))
        }
    }
}

export const getFullCalendar = () => {
    return async dispatch => {
        dispatch(setRefreshingKey('getFullCalendar'))
        try {
            const cacheResult = await getFullCalendarQueryHandler.fetch()
            cacheResult.data != null && dispatch(setFullCalendar(cacheResult.data))
            const httpResult = await cacheResult.httpResult
            httpResult.data != null && dispatch(setFullCalendar(httpResult.data))
        } catch (e) {
            log.warn('Problem fetching calendar', e)
        } finally {
            dispatch(clearRefreshingKey('getFullCalendar'))
        }
    }
}

export const getSettings = () => {
    return async dispatch => {
        dispatch(setRefreshingKey('getSettings'))
        try {
            const cacheResult = await getSettingsQueryHandler.fetch()
            if (cacheResult.data != null) {
                dispatch(setWeekStart(cacheResult.data.weekStart))
                dispatch(setSubscribedToMarketing(cacheResult.data.subscribedToMarketing))
            }
            const httpResult = await cacheResult.httpResult
            if (httpResult.data != null) {
                dispatch(setWeekStart(httpResult.data.weekStart))
                dispatch(setSubscribedToMarketing(httpResult.data.subscribedToMarketing))
            }
        } catch (e) {
            log.warn('Problem fetching settings', e)
        } finally {
            dispatch(clearRefreshingKey('getSettings'))
        }
    }
}

export const getAllBookmarks = () => {
    return async dispatch => {
        dispatch(setRefreshingKey('getAllBookmarks'))
        try {
            const cacheResult = await getAllBookmarksQueryHandler.fetch()
            cacheResult.data != null && dispatch(setAllBookmarks(cacheResult.data))
            const httpResult = await cacheResult.httpResult
            httpResult.data != null && dispatch(setAllBookmarks(httpResult.data))
        } catch (e) {
            log.warn('Problem fetching bookmarks', e)
        } finally {
            dispatch(clearRefreshingKey('getAllBookmarks'))
        }
    }
}


let latestTermsPromise = null
/**
 * Gets the latest terms data, including the text.
 */
export const getLatestTermsThunk = () => {
    return async (dispatch, getState) => {
        if (latestTermsPromise != null) {
            // Already in the process of fetching the terms.
            return await latestTermsPromise
        }

        dispatch(setLatestTermsFetching(true))
        try {
            latestTermsPromise = getLatestTermsQueryHandler.fetchHttp()
            const terms = await latestTermsPromise
            dispatch(setLatestTerms(terms))
            dispatch(setLatestTermsError(null))
        } catch (error) {
            dispatch(setLatestTermsError(error.message))

            throw error
        } finally {
            latestTermsPromise = null
            dispatch(setLatestTermsFetching(false))
        }
    }
}

/**
 * Checks whether the user needs to accept the latest terms.
 * If so, then the accept terms UI is shown.
 */
export const checkLatestTermsThunk = () => {
    return async dispatch => {
        try {
            const mustAcceptNewTerms = await checkNewTermsQueryHandler.fetchHttp()
            if (mustAcceptNewTerms) {
                await dispatch(getLatestTermsThunk())
                dispatch(pushAppMuxPage(PAGE_NAME_NEW_TERMS))
            }
        } catch (e) {
            log.warn('Problem fetching latest terms', e)
        }
    }
}

let isRefreshingCache = false
export const refreshCache = () => {
    return async dispatch => {
        if (!isRefreshingCache) {
            isRefreshingCache = true
            try {
                await Promise.all([
                    dispatch(getAllRecipes()),
                    dispatch(getAllTags()),
                    dispatch(getFullCalendar()),
                    dispatch(getSettings()),
                    dispatch(getAllBookmarks()),
                ])
            } finally {
                isRefreshingCache = false
            }
        }
    }
}

export const editRecipeThunk = (orignalRecipe, newRecipe) => {
    return async (dispatch, getState) => {
        const newTags = newRecipe.tags.subtract(getState().entities.tags)
        if (!newTags.isEmpty()) {
            // TODO: create new tags implicitly.
            dispatch(createNewTags(newTags))
            await createTagsCommandHandler.execute(getSerializableTags(newTags))
        }

        dispatch(editRecipe(newRecipe))

        await editRecipeCommandHandler.execute(getSerializableRecipe(orignalRecipe), getSerializableRecipe(newRecipe))
    }
}

export const createRecipeThunk = recipe => {
    return async (dispatch, getState) => {
        const newTags = recipe.tags.subtract(getState().entities.tags)
        if (!newTags.isEmpty()) {
            // TODO: create new tags implicitly.
            dispatch(createNewTags(newTags))
        }

        const recipeWithId = { ...recipe, id: uuidv4() }
        dispatch(createNewRecipe(recipeWithId))
        const serializableRecipe = getSerializableRecipe(recipeWithId)
        await createRecipeCommandHandler.execute(serializableRecipe)
    }
}

export const setWeekStartThunk = weekStart => {
    return async dispatch => {
        dispatch(setWeekStart(weekStart))
        await editSettingsCommandHandler.execute({ weekStart })
    }
}

export const selectRecipeForDayThunk = (stamp, recipe) => {
    return async dispatch => {
        dispatch(selectRecipeForDay(stamp, recipe.id))
        await planRecipeCommandHandler.execute(new Date(stamp), recipe.id)
    }
}

export const deletePlannedRecipeThunk = (stamp, recipe) => {
    return async dispatch => {
        dispatch(deletePlannedRecipe(stamp, recipe))
        await deletePlannedRecipeCommandHandler.execute(new Date(stamp), recipe.id)
    }
}

export const bookmarkRecipeThunk = recipe => {
    return async dispatch => {
        dispatch(bookmarkRecipe(recipe))
        await bookmarkRecipeCommandHandler.execute(recipe.id)
    }
}

export const unbookmarkRecipeThunk = recipe => {
    return async dispatch => {
        dispatch(unbookmarkRecipe(recipe))
        await unbookmarkRecipeCommandHandler.execute(recipe.id)
    }
}

export const acceptNewTermsThunk = termsId => {
    return async dispatch => {
        dispatch(setAcceptNewTermsPending(true))
        dispatch(setAcceptNewTermsError(false))
        try {
            await acceptNewTermsCommandHandler.execute(termsId)
            dispatch(popAppMuxPage(PAGE_NAME_NEW_TERMS))
        } catch (error) {
            dispatch(setAcceptNewTermsError(true))
        } finally {
            dispatch(setAcceptNewTermsPending(false))
        }
    }
}

export const subscribeToMarketingThunk = subscribe => {
    return async dispatch => {
        dispatch(setSubscribedToMarketing(subscribe))
        await editSettingsCommandHandler.execute({ subscribedToMarketing: subscribe })
    }
}