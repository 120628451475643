import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Switch,
    Typography
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    listItem: {
        padding: `0 ${theme.spacing(1)}px 0 ${theme.spacing(1)}px`
    }
}))

const SortOptions = ({
    sortOptions,
    setSortOptions
}) => {
    const classes = useStyles()

    const onChange = React.useCallback(el => {
        setSortOptions({ ...sortOptions, [el.target.value]: el.target.checked })
    }, [setSortOptions, sortOptions])

    return (
        <List>
            <Typography classes={{ root: classes.listItem }}>Sort</Typography>
            <ListItem classes={{ root: classes.listItem }}>
                <ListItemIcon>
                    <Switch
                    checked={sortOptions.sortByPlanCount}
                    onChange={onChange}
                    value="sortByPlanCount"
                    color="secondary"
                    inputProps={{ 'aria-label': 'sort by plan count' }}
                    />
                </ListItemIcon>
                <ListItemText primary="Plan count" />
            </ListItem>
        </List>
    )
}

export default SortOptions