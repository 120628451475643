import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import Draggable from 'react-draggable'
import clsx from 'clsx'
import { timer, Subject, Observable, of } from 'rxjs'
import { windowToggle, filter, mergeMap, toArray } from 'rxjs/operators'

class DraggableCard extends React.Component {
    constructor(props) {
        super(props)
        this.touchObserver = null
        this.clickObserver = null
        this.cardRef = React.createRef(null)
        this.state = {
            isDragging: false,
            x: 0,
            y: 0,
            width: 0,
            height: 0,
        }
        this.createDragDetection()
    }

    // Creates an observable that uses a time window to detect if dragging
    // should start or be cancelled.
    createDragDetectionSubscription = onCreateObservable => {
        const subject = new Subject()
        const source = Observable.create(onCreateObservable)
        source.subscribe(subject)
        return subject.pipe(
            // Create window of events starting at start events, and over a certain length of time.
            windowToggle(subject.pipe(filter(val => val.type === 'start')), val => timer(200)),
            mergeMap(o => o.pipe(toArray())),
            // If we only have the start event (no move or end), then we can start the drag.
            // Later we could check the x and y changes to reduce sensitivity.
            mergeMap(val => val.length === 1 ? of(val[0]) : of()),
        )
        // Call the handler of the draggable since dragging has started.
        .subscribe(val => val.handler())
    }

    // Create drag detection for touch and click events.
    createDragDetection = () => {
        this.createDragDetectionSubscription(observer => this.touchObserver = observer)
        this.createDragDetectionSubscription(observer => this.clickObserver = observer)
    }

    getEvent = e => (e.targetTouches && e.targetTouches[0]) || (e.changedTouches && e.changedTouches[0]) || e

    // Reduce width by 20% to stabilise recipe drag.
    adjustWidth = coords => ({ ...coords, width: parseInt(coords.width * 0.8, 10)})

    onBeforeTouchStart = (onTouchStart, e, ...props) => {
        e.persist()
        e.preventDefault() // stop native scrolling
        const { clientX, clientY } = this.getEvent(e)
        this.touchObserver.next({ type: 'start', x: clientX, y: clientY, handler: () => onTouchStart(e, ...props) })
        this.props.onBeforeTouchStart && this.props.onBeforeTouchStart(e, ...props)
    }

    onTouchMove = e => {
        const { clientX, clientY } = this.getEvent(e)
        this.touchObserver.next({ type: 'move', x: clientX, y: clientY })
    }

    onBeforeTouchEnd = (onTouchEnd, ...props) => {
        this.touchObserver.next({ type: 'end' })
        this.props.onBeforeTouchEnd && this.props.onBeforeTouchEnd(...props)
        onTouchEnd(...props)
    }

    onBeforeMouseDown = (onMouseDown, e, ...props) => {
        e.persist()
        const { clientX, clientY } = this.getEvent(e)
        this.clickObserver.next({ type: 'start', x: clientX, y: clientY, handler: () => onMouseDown(e, ...props) })
        this.props.onBeforeMouseDown && this.props.onBeforeMouseDown(e, ...props)
    }

    onMouseMove = e => {
        const { clientX, clientY } = this.getEvent(e)
        this.clickObserver.next({ type: 'move', x: clientX, y: clientY })
    }

    onBeforeMouseUp = (onMouseUp, ...props) => {
        this.clickObserver.next({ type: 'end' })
        this.props.onBeforeMouseUp && this.props.onBeforeMouseUp(...props)
        onMouseUp(...props)
    }

    onStart = (e, data) => {
        this.props.dragChannel.lift.send({ index: this.props.liftIndex })
        const { left, top } = this.cardRef.current.getBoundingClientRect()
        const coords = { x: left, y: top, width: this.cardRef.current.offsetWidth, height: this.cardRef.current.offsetHeight }
        this.props.dragChannel.coords.send(this.adjustWidth(coords))
        this.setState({ isDragging: true, ...coords })
        this.props.onDragStart && this.props.onDragStart()
    }

    onDrag = (e, data) => {
        const { x, y } = data
        const newX = this.props.verticalOnly ? this.state.x : x
        this.props.dragChannel.coords.send(this.adjustWidth({ x: newX, y, width: this.state.width, height: this.state.height }))
        this.setState({ x: newX, y })
    }

    onStop = () => {
        this.props.dragChannel.drop.send({ recipe: this.props.dropItem })
        this.setState({ isDragging: false, x: 0, y: 0 })
        this.props.onDragStop && this.props.onDragStop()
    }

    render() {
        const {
            children,
            classes,
            className,
            backingCardClassName,
            forwardedRef,
        } = this.props

        const child = React.Children.only(children)

        const draggableChild = React.cloneElement(
            child, {
                ref: this.cardRef,
                className: clsx(child.props.className, this.state.isDragging && classes.isDragging),
                style: this.state.isDragging ? { width: this.state.width , height: this.state.height } : null,
                otherTransform: this.state.isDragging ? 'rotate(3deg)' : '',
                overrideTouchAction: this.props.enableDrag ? null : 'auto',
                onBeforeTouchStart: this.props.enableDrag ? this.onBeforeTouchStart : undefined,
                onTouchMove: this.props.enableDrag ? this.onTouchMove : undefined,
                onBeforeTouchEnd: this.props.enableDrag ? this.onBeforeTouchEnd : undefined,
                onBeforeMouseDown: this.props.enableDrag ? this.onBeforeMouseDown : undefined,
                onMouseMove: this.props.enableDrag ? this.onMouseMove : undefined,
                onBeforeMouseUp: this.props.enableDrag ? this.onBeforeMouseUp : undefined,
                isBackingCard: false,
            }
        )

        const backingChild = React.cloneElement(
            child, {
                className: backingCardClassName,
                isBackingCard: true,
            }
        )
        const card = (
            <React.Fragment>
                <Draggable
                disabled={!this.props.enableDrag}
                position={this.state}
                onStart={this.onStart}
                onStop={this.onStop}
                onDrag={this.onDrag}
                enableUserSelectHack={false}
                axis={this.props.verticalOnly ? 'y' : 'both'}
                >
                    { draggableChild }
                </Draggable>
                { this.state.isDragging ? backingChild : null }
            </React.Fragment>
        )

        return (
            <Box
            ref={forwardedRef}
            className={clsx(className, classes.root)}
            >
                {card}
            </Box>
        )
    }
};

export default withStyles({
    root: {
        // Prevent text selection.
        userSelect: 'none'
    },
    isDragging: {
        zIndex: 1023,
        position: 'fixed',
        left: '0px',
        top: '0px',
        transform: 'skew(10deg)'
    },
})(React.forwardRef((props, ref) => <DraggableCard { ...props } forwardedRef={ref} />))