import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core'

const AppUpdateDialog = ({ open, performAppUpdate }) => {
    const handleAction = React.useCallback(defer => {
        performAppUpdate(defer)
    }, [performAppUpdate])

    return (
        <Dialog
            open={open}
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby="app-update-dialog-title"
            aria-describedby="app-update-dialog-description"
        >
            <DialogTitle id="app-update-dialog-title">Update ready</DialogTitle>
                <DialogContent>
                    <DialogContentText id="app-update-dialog-description">
                        Click install to refresh this app to the new version. This will refresh any open ScranPlan tabs.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => handleAction(true)}>
                        Later
                    </Button>
                    <Button variant="contained" onClick={() => handleAction(false)} color="primary" autoFocus>
                        Install
                    </Button>
                </DialogActions>
        </Dialog>
    )
}

export default AppUpdateDialog