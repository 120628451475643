import { connect } from 'react-redux';
import AppInstallCta from '../AppInstallCta'
import {
    showInstallPromptThunk
} from 'actions'

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    showInstallPrompt: () => dispatch(showInstallPromptThunk())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppInstallCta);