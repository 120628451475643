import React from 'react'
import { Box } from '@material-ui/core'
import WeekDayCardDraggable from 'components/calendar/weekday/WeekDayCardDraggable'
import moment from 'moment'
import { withRouter } from 'react-router'
import { recipeDragChannel, weekdayDragChannel } from 'common/dragChannels'
import ReactGA from 'react-ga4'

const indexToDay = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const getWeekdays = (weekStart, weekDate) => {
    const getDate = (index) => moment(weekDate).add(index, 'days')
    return [...Array(7).keys()].map((index) => {
        const date = getDate(index)
        const stamp = date.valueOf()
        return {
            dayTitle: indexToDay[(weekStart + index) % 7],
            date: date.date(),
            stamp,
        }
    })
}

const reorderRecipesByDrag = (recipesSnapshot, initialIndex, dropIndex) => {
    if (initialIndex === dropIndex) return recipesSnapshot

    const getNextIndex = (index) =>
        dropIndex > initialIndex ? index - 1 : index + 1
    const reorderedRecipes = [...recipesSnapshot]
    reorderedRecipes[initialIndex] = null
    reorderedRecipes[dropIndex] = recipesSnapshot[initialIndex]
    if (recipesSnapshot[dropIndex] != null) {
        let currentIndex = dropIndex
        while (1) {
            const nextIndex = getNextIndex(currentIndex)
            const shouldStop = reorderedRecipes[nextIndex] == null
            reorderedRecipes[nextIndex] = recipesSnapshot[currentIndex]
            currentIndex = nextIndex
            if (shouldStop) break
        }
    }
    return reorderedRecipes
}

function getDimensions(el) {
    return {
        x: el.getBoundingClientRect().left,
        y: el.getBoundingClientRect().top,
        width: el.offsetWidth,
        height: el.offsetHeight,
    }
}

function computeOverlayRatio(weekDay, dragState) {
    const ax = weekDay.x + weekDay.width - dragState.x
    const bx = dragState.x + dragState.width - weekDay.x
    const ay = weekDay.y + weekDay.height - dragState.y
    const by = dragState.y + dragState.height - weekDay.y

    if (ax > 0 && bx > 0 && ay > 0 && by > 0) {
        return Math.min(ax, bx) * Math.min(ay, by)
    }

    return 0
}

class WeekdayList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dropTargetIndex: null,
            weekdayRecipesReorder: null,
        }
        this.weekdayRefs = [...Array(7)].map((x) => React.createRef({}))
        this.weekdays = null
        this.weekdayRecipesSnapshot = null
        this.dragSourceIndex = null
    }

    componentDidMount() {
        this.coordsRecipeSubscription = recipeDragChannel.coords.subscribe(
            (coords) => {
                const dropZone = this.getDropZone(coords)
                const newIndex =
                    dropZone.overlayRatio > 0 ? dropZone.index : null
                if (this.state.dropTargetIndex !== newIndex) {
                    this.setState({ dropTargetIndex: newIndex })
                }
            }
        )

        this.dropRecipeSubscription = recipeDragChannel.drop.subscribe(
            (drop) => {
                if (this.state.dropTargetIndex != null) {
                    const dropStamp =
                        this.weekdays[this.state.dropTargetIndex].stamp
                    const origRecipe =
                        this.props.recipes[this.props.recipeMap.get(dropStamp)]
                    if (origRecipe != null) {
                        this.props.deletePlannedRecipe(dropStamp, origRecipe)
                    }
                    this.props.selectRecipeForDay(dropStamp, drop.recipe, false)
                    this.setState({ dropTargetIndex: null })
                    ReactGA.event({
                        category: 'Planner',
                        action: 'Plan Recipe DnD',
                        label: drop.recipe.id,
                    })
                }
            }
        )

        this.liftWeekdaySubscription = weekdayDragChannel.lift.subscribe(
            (lift) => {
                this.dragSourceIndex = lift.index
            }
        )

        this.coordsWeekdaySubscription = weekdayDragChannel.coords.subscribe(
            (coords) => {
                const dropZone = this.getDropZone(coords)
                const newIndex =
                    dropZone.overlayRatio > 0 ? dropZone.index : null
                if (this.state.dropTargetIndex !== newIndex) {
                    if (this.weekdayRecipesSnapshot == null) {
                        this.weekdayRecipesSnapshot = this.getWeekdayRecipes()
                    }
                    const reorderRecipes =
                        newIndex != null
                            ? reorderRecipesByDrag(
                                  this.weekdayRecipesSnapshot,
                                  this.dragSourceIndex,
                                  newIndex
                              )
                            : null
                    this.setState({
                        dropTargetIndex: newIndex,
                        weekdayRecipesReorder: reorderRecipes,
                    })
                }
            }
        )

        this.dropWeekdaySubscription = weekdayDragChannel.drop.subscribe(
            (drop) => {
                if (this.state.dropTargetIndex != null) {
                    const recipes = this.getWeekdayRecipes()
                    this.state.weekdayRecipesReorder &&
                        this.state.weekdayRecipesReorder.forEach(
                            (recipe, index) => {
                                if (recipes[index] !== recipe) {
                                    if (recipe != null) {
                                        if (recipes[index] != null) {
                                            this.props.deletePlannedRecipe(
                                                this.weekdays[index].stamp,
                                                recipes[index]
                                            )
                                        }
                                        this.props.selectRecipeForDay(
                                            this.weekdays[index].stamp,
                                            recipe,
                                            false
                                        )
                                        ReactGA.event({
                                            category: 'Calendar',
                                            action: 'Replan Day DnD',
                                            label: this.weekdays[
                                                index
                                            ].stamp.toString(),
                                        })
                                    } else {
                                        this.props.deletePlannedRecipe(
                                            this.weekdays[index].stamp,
                                            recipes[index]
                                        )
                                    }
                                }
                            }
                        )
                    this.setState({
                        dropTargetIndex: null,
                        weekdayRecipesReorder: null,
                    })
                }
                this.weekdayRecipesSnapshot = null
                this.dragSourceIndex = null
            }
        )
    }

    componentWillUnmount() {
        this.liftWeekdaySubscription.unsubscribe()
        this.coordsRecipeSubscription.unsubscribe()
        this.coordsWeekdaySubscription.unsubscribe()
        this.dropRecipeSubscription.unsubscribe()
        this.dropWeekdaySubscription.unsubscribe()
    }

    getEvent = (e) =>
        (e.targetTouches && e.targetTouches[0]) ||
        (e.changedTouches && e.changedTouches[0]) ||
        e

    getDropZone = (coords) =>
        this.weekdayRefs
            .map((ref, index) => ({
                index: index,
                overlayRatio: computeOverlayRatio(
                    getDimensions(ref.current),
                    coords
                ),
            }))
            .reduce((acc, value) =>
                value.overlayRatio > acc.overlayRatio ? value : acc
            )

    getRecipeByStamp = (stamp) =>
        this.props.recipes[this.props.recipeMap.get(stamp)]

    getWeekdayRecipes = () =>
        this.weekdays.map((weekday) => this.getRecipeByStamp(weekday.stamp))

    deleteRecipe = (stamp, recipe) => {
        this.props.deletePlannedRecipe(stamp, recipe)
        ReactGA.event({
            category: 'Calendar',
            action: 'Unplan Recipe',
        })
    }

    selectRecipe = (stamp) => {
        if (this.props.match.params.id == null) {
            // Move onto pick recipe.
            this.props.history.push(`/calendar/recipes/${stamp}`)
            ReactGA.event({
                category: 'Calendar',
                action: 'Begin Plan Day',
                label: stamp.toString(),
            })
        } else {
            // Otherwise this is a date picker.
            const origRecipe =
                this.props.recipes[this.props.recipeMap.get(stamp)]
            if (origRecipe != null) {
                this.props.deletePlannedRecipe(stamp, origRecipe)
            }
            const recipe = this.props.recipes[this.props.match.params.id]
            this.props.selectRecipeForDay(stamp, recipe)
            this.props.history.goBack()
            ReactGA.event({
                category: 'Recipes',
                action: 'End Plan Recipe',
                label: recipe.id,
            })
        }
    }

    onDragStart = () => {
        this.props.setIsDragging(true)
    }

    onDragStop = () => {
        this.props.setIsDragging(false)
    }

    render() {
        const { history, weekStart, weekDate, enableAddRecipe } = this.props

        this.weekdays = getWeekdays(weekStart, weekDate)
        const weekdayRecipes =
            this.state.weekdayRecipesReorder != null
                ? this.state.weekdayRecipesReorder
                : this.getWeekdayRecipes()

        return (
            <Box display="flex" flexDirection="column" flexGrow={1}>
                {this.weekdays.map((weekDay, index) => (
                    <WeekDayCardDraggable
                        ref={this.weekdayRefs[index]}
                        key={weekDay.stamp}
                        history={history}
                        index={index}
                        day={weekDay.dayTitle}
                        date={weekDay.date}
                        stamp={weekDay.stamp}
                        recipe={weekdayRecipes[index]}
                        deleteRecipe={this.deleteRecipe}
                        selectRecipe={this.selectRecipe}
                        isDropTarget={this.state.dropTargetIndex === index}
                        enableAddRecipe={enableAddRecipe}
                        onDragStart={this.onDragStart}
                        onDragStop={this.onDragStop}
                    />
                ))}
            </Box>
        )
    }
}

export default withRouter(WeekdayList)
