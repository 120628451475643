const awsconfig = {
    Auth: {

        // REQUIRED - Amazon Cognito Region
        region: process.env.REACT_APP_AWS_AUTH_REGION,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_AWS_AUTH_USERPOOLID,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.REACT_APP_AWS_AUTH_USERPOOLWEBCLIENTID,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,
        
        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_SRP_AUTH',
    }
}

export default awsconfig
