import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    Badge,
    Box,
    Button,
    Divider,
    Drawer,
    FormControlLabel,
    List,
    ListItem,
    ListItemText,
    Switch
} from '@material-ui/core'
import CustomListItemIcon from 'components/common/CustomListItemIcon'
import FastfoodIcon from '@material-ui/icons/Fastfood'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import ListAltIcon from '@material-ui/icons/ListAlt'
import SettingsIcon from '@material-ui/icons/Settings'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import TuneIcon from '@material-ui/icons/Tune'
import { Link } from 'react-router-dom'
import Auth from '@aws-amplify/auth'
import { useLocation } from 'react-router-dom'
import AppInstallCta from 'components/app/containers/AppInstallCta'

const useStyles = makeStyles(theme => ({
    center: {
        justifyContent: 'center'
    },
    // Override ListItem selected styling.
    // We need a higher specificity since ListItem's style
    // rule for a selected item targets .Mui-ListItem.Mui-Selected.
    selected: {
        '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText
        }
    },
    selectedInverted: {
        '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText
        }
    },
    badgeOnline: {
        backgroundColor: theme.palette.info.main,
        color: theme.palette.info.contrastText
    },
    badgeOffline: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.warning.contrastText
    },
    drawer: {
        color: theme.palette.primary.main
    },
    drawerInverted: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    signOutButton: {
        color: theme.palette.secondary.light,
        borderColor: theme.palette.secondary.light
    }
}))

const NavListItem = ({ children, colorsInverted, ...props }) => {
    const classes = useStyles()

    return (
        <ListItem
        button
        component={Link}
        classes={{ selected: colorsInverted ? classes.selectedInverted : classes.selected }}
        { ...props }
        >
            { children }
        </ListItem>
    )
}

const navPaths = [
    {
        path: '/calendar',
        text: 'Calendar',
        icon: <CalendarTodayIcon/>
    },
    {
        path: '/',
        text: 'Planner',
        icon: <ListAltIcon/>
    },
    {
        path: '/recipes',
        text: 'Recipes',
        icon:<FastfoodIcon/>
    },
    {
        path: '/settings',
        text: 'Settings',
        icon:<SettingsIcon/>
    },
    {
        path: '/profile',
        text: 'Profile',
        icon:<AccountBoxIcon/>
    },
    {
        path: '/cookie-settings',
        text: 'Cookies',
        icon:<TuneIcon/>
    }
]

const NavDrawer = ({ open, onClose, isOnline, isInstallable }) => {
    const classes = useStyles()

    const location = useLocation()

    const [colorsInverted, setColorsInverted] = React.useState(true)

    const invertColors = React.useCallback(event => {
        setColorsInverted(event.target.checked)
    }, [setColorsInverted])

    const onCloseDrawer = React.useCallback(() => {
        onClose();
    }, [onClose]);

    const onSignOut = React.useCallback(() => {
        Auth.signOut()
    }, [])

    return (
        <Drawer open={open} onClose={onCloseDrawer} classes={{ paper: colorsInverted ? classes.drawerInverted : classes.drawer }}>
            <List component="nav" aria-label="main navigation">
                {
                    navPaths.map((item, index) => (
                        <NavListItem to={item.path} selected={location.pathname === item.path} key={index} colorsInverted={colorsInverted}>
                            <CustomListItemIcon>{item.icon}</CustomListItemIcon>
                            <ListItemText primary={item.text}/>
                        </NavListItem>        
                    ))
                }
            </List>
            <Divider/>
            <List aria-label="sign out and status">
                <ListItem className={classes.center}>
                    <Button classes={{ root: classes.signOutButton }} variant="outlined" size="small" disableElevation onClick={onSignOut}>Sign out</Button>
                </ListItem>
                <ListItem className={classes.center}>
                    <Box>
                    <Badge badgeContent={isOnline ? 'online' : 'offline'} classes={{ badge: isOnline ? classes.badgeOnline : classes.badgeOffline }}></Badge>
                    </Box>
                </ListItem>
                <ListItem>
                <FormControlLabel
                        control={
                            <Switch
                            checked={colorsInverted}
                            onChange={invertColors}
                            color={ colorsInverted ? "secondary" : "primary" }
                            />
                        }
                        label="Invert colors"
                    />
                </ListItem>
                {
                    isInstallable ? (
                        <ListItem>
                            <AppInstallCta onClickedCta={onClose} />
                        </ListItem>
                    ) : null
                    }
            </List>
        </Drawer>
    );
}

export default NavDrawer;