import { Set as ImmutableSet } from 'immutable'
import CaseInsensitiveString from 'common/CaseInsensitiveString'
import {
    CREATE_NEW_RECIPE,
    EDIT_RECIPE,
    SET_ALL_RECIPES,
    SET_ALL_TAGS,
    CREATE_NEW_TAGS,
} from '../actions/actions';

const initialState = {
    recipes: {},
    tags: ImmutableSet()
};

const toCaseInsensitiveSet = items => ImmutableSet(items.map(item => new CaseInsensitiveString(item)))

const entities = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_NEW_RECIPE:
            return { ...state, recipes: { ...state.recipes, [action.recipe.id]: action.recipe } }
        case EDIT_RECIPE:
            const origRecipe = state.recipes[action.recipe.id];
            const editedRecipe = { ...origRecipe, ...action.recipe }
            const newTags2 = action.recipe.tags.subtract(state.tags)
            return { ...state, recipes: { ...state.recipes, [action.recipe.id]: editedRecipe }, tags: state.tags.union(newTags2) }
        case SET_ALL_RECIPES:
            const recipes = action.recipes
            .map(recipe => ({ ...recipe, tags: toCaseInsensitiveSet(recipe.tags || []) }))
            .reduce((acc, recipe) => ({ ...acc, [recipe.id]: recipe }), {})
            return { ...state, recipes }
        case SET_ALL_TAGS:
            return { ...state, tags: toCaseInsensitiveSet(action.tags) }
        case CREATE_NEW_TAGS:
            return { ...state, tags: state.tags.union(action.tags) }
        default:
            return state;
    }
}

export default entities;