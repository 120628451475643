import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import WeekDayCard from './WeekDayCard'
import DraggableCard from 'components/common/DraggableCard'
import { weekdayDragChannel } from 'common/dragChannels'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexBasis: '60px',
        marginBottom: '2px'
    },
    dropTarget: {
        backgroundColor: '#eeffee',
    },
    backingCard: {
        backgroundColor: '#eeeeff'
    }
}))

const WeekDayCardDraggable = React.forwardRef(({
    history,
    recipe,
    index,
    stamp,
    date,
    day,
    deleteRecipe,
    selectRecipe,
    isDropTarget,
    enableAddRecipe,
    onDragStart,
    onDragStop,
}, ref) => {
    const classes = useStyles()

    const [isDisableClick, setIsDisableClick] = React.useState(false)
    const [recipeSnapshot, setRecipeSnapshot] = React.useState(null)

    const onBeforeTouchStart = React.useCallback(() => {
        setIsDisableClick(false)
    }, [setIsDisableClick])

    const onBeforeMouseDown = React.useCallback(() => {
        setIsDisableClick(false)
    }, [setIsDisableClick])

    const onDragStartWrapper = React.useCallback(() => {
        setIsDisableClick(true)
        setRecipeSnapshot(recipe)
        onDragStart && onDragStart()
    }, [setRecipeSnapshot, recipe, setIsDisableClick, onDragStart])

    const onDragStopWrapper = React.useCallback(() => {
        setRecipeSnapshot(null)
        onDragStop && onDragStop()
    }, [setRecipeSnapshot, onDragStop])

    return (
        <DraggableCard
        ref={ref}
        className={classes.root}
        enableDrag={recipe != null || recipeSnapshot != null}
        dropItem={recipe}
        liftIndex={index}
        backingCardClassName={classes.backingCard}
        verticalOnly
        dragChannel={weekdayDragChannel}
        onDragStart={onDragStartWrapper}
        onDragStop={onDragStopWrapper}
        onBeforeTouchStart={onBeforeTouchStart}
        onBeforeMouseDown={onBeforeMouseDown}
        >
            <WeekDayCard
            className={clsx(isDropTarget && classes.dropTarget)}
            history={history}
            recipe={recipeSnapshot || recipe}
            backingRecipe={recipe}
            stamp={stamp}
            date={date}
            day={day}
            deleteRecipe={deleteRecipe}
            selectRecipe={selectRecipe}
            enableAddRecipe={enableAddRecipe}
            disableClick={isDisableClick}
            />
        </DraggableCard>
    )
})

export default WeekDayCardDraggable