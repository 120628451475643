import { connect } from 'react-redux';
import AppBar from '../AppBar' 

const mapStateToProps = state => ({
    isRefreshing: !state.app.refreshingKeys.isEmpty(),
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppBar);