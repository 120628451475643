import { installPromptObservable } from 'common/observables'
import { 
    setInstallPrompt,
} from 'actions'

export default class InstallPromptListener {
    constructor(store) {
        this.store = store

        installPromptObservable.subscribe(val => {
            this.store.dispatch(setInstallPrompt(val))
        })
    }
}
