import { db } from 'services/cache/CacheController'
import { getUsername } from 'services/common/user'

export default class BookmarkCacheWriter {
    /**
     * Create or overwrite into the cache.
     * @param {*} bookmarks 
     */
    async bulkPut(bookmarks) {
        let userId = await getUsername()
        await db.transaction('rw', db.bookmarks, async () => {
            await db.bookmarks.bulkPut(bookmarks.map(bookmark => ({ ...bookmark, userId })))
        })
    }

    async bulkDelete(recipeIds) {
        let userId = await getUsername()
        await db.transaction('rw', db.bookmarks, async () => {
            await db.bookmarks.bulkDelete(recipeIds.map(recipeId => [recipeId, userId]))
        })
    }

    async bookmark(recipeId) {
        let userId = await getUsername()
        await db.transaction('rw', db.bookmarks, async () => {
            await db.bookmarks.put({ recipeId, userId })
        })
    }

    async unbookmark(recipeId) {
        let userId = await getUsername()
        await db.transaction('rw', db.bookmarks, async () => {
            await db.bookmarks.delete( [recipeId, userId] )
        })
    }
}