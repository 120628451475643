import { editRecipeThunk } from 'actions'
import { connect } from 'react-redux';
import EditRecipePage from '../EditRecipePage' 

const mapStateToProps = state => ({
    recipes: state.entities.recipes,
    allTags: state.entities.tags,
});

const mapDispatchToProps = dispatch => ({
    editRecipe: (orignalRecipe, newRecipe) => dispatch(editRecipeThunk(orignalRecipe, newRecipe)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditRecipePage);