export const SET_PLANNER_WEEK = 'SET_PLANNER_WEEK'
export const PREV_PLANNER_WEEK = 'PREV_PLANNER_WEEK'
export const NEXT_PLANNER_WEEK = 'NEXT_PLANNER_WEEK'
export const DELETE_PLANNED_RECIPE = 'DELETE_PLANNED_RECIPE'
export const SELECT_RECIPE_FOR_DAY = 'SELECT_RECIPE_FOR_DAY'
export const CLEAR_TOAST_MESSAGE = 'CLEAR_TOAST_MESSAGE'
export const SET_TOAST_MESSAGE = 'SET_TOAST_MESSAGE'
export const SET_WEEK_START = 'SET_WEEK_START'
export const CREATE_NEW_RECIPE = 'CREATE_NEW_RECIPE'
export const EDIT_RECIPE = 'EDIT_RECIPE'
export const CREATE_NEW_TAGS = 'CREATE_NEW_TAGS'
export const BOOKMARK_RECIPE = 'BOOKMARK_RECIPE'
export const UNBOOKMARK_RECIPE = 'UNBOOKMARK_RECIPE'
export const SET_ALL_RECIPES = 'SET_ALL_RECIPES'
export const SET_ALL_TAGS = 'SET_ALL_TAGS'
export const SET_FULL_CALENDAR = 'SET_FULL_CALENDAR'
export const SET_ALL_BOOKMARKS = 'SET_ALL_BOOKMARKS'
export const SET_ONLINE_STATUS = 'SET_ONLINE_STATUS'
export const SET_REFRESHING_KEY = 'SET_REFRESHING_KEY'
export const CLEAR_REFRESHING_KEY = 'CLEAR_REFRESHING_KEY'
export const SET_PROFILE = 'SET_PROFILE'
export const SET_PROFILE_EDITING = 'SET_PROFILE_EDITING'
export const OPEN_BUSY_MODAL = 'OPEN_BUSY_MODAL'
export const CLOSE_BUSY_MODAL = 'CLOSE_BUSY_MODAL'
export const OPEN_ERROR_MODAL = 'OPEN_ERROR_MODAL'
export const CLOSE_ERROR_MODAL = 'CLOSE_ERROR_MODAL'
export const SET_LATEST_TERMS_FETCHING = 'SET_LATEST_TERMS_FETCHING'
export const SET_LATEST_TERMS_ERROR = 'SET_LATEST_TERMS_ERROR'
export const SET_LATEST_TERMS = 'SET_LATEST_TERMS'
export const SET_ACCEPT_NEW_TERMS_PENDING = 'SET_ACCEPT_NEW_TERMS_PENDING'
export const SET_ACCEPT_NEW_TERMS_ERROR = 'SET_ACCEPT_NEW_TERMS_ERROR'
export const REGISTER_APP_MUX_PAGES = 'REGISTER_APP_MUX_PAGES'
export const PUSH_APP_MUX_PAGE = 'PUSH_APP_MUX_PAGE'
export const POP_APP_MUX_PAGE = 'POP_APP_MUX_PAGE'
export const SET_THEME_CONFIG = 'SET_THEME_CONFIG'
export const SET_INSTALL_PROMPT = 'SET_INSTALL_PROMPT'
export const SHOW_APP_UPDATE_DIALOG = 'SHOW_APP_UPDATE_DIALOG'
export const SET_SUBSCRIBED_TO_MARKETING = 'SET_SUBSCRIBED_TO_MARKETING'