import React from 'react'
import {
    Box
} from '@material-ui/core'

export default class AppMux extends React.Component {
    componentDidUpdate() {
        const pages = React.Children.map(this.props.children, child => ({ name: child.props.pageName, priority: child.props.pagePriority }))
        this.props.registerPages(pages)

        const defaultPage = React.Children.toArray(this.props.children).find(child => child.props.defaultPage)
        if (defaultPage != null) {
            this.props.pushAppMuxPage(defaultPage.props.pageName)
        }
    }

    render() {
        const { children, currentPageName, authState } = this.props
        const clonedPages = React.Children.map(children, child => React.cloneElement(child, { authState }))

        return (
            <React.Fragment>
                {
                    clonedPages.map(page => (
                        <Box display={ page.props.pageName === currentPageName ? "block" : "none"  } key={ page.props.pageName }>
                            { page }
                        </Box>
                    ))
                }
            </React.Fragment>
        )
    }
}