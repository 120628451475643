import { Observable } from 'rxjs'

/**
 * Represents a channel where you can send events or subscribe to the events.
 */
class CustomChannel {
    constructor() {
        this.observer = null
        this.observable = Observable.create(observer => {
            this.observer = observer
        })
    }

    send = val => {
        this.observer && this.observer.next(val)
    }

    subscribe = action => this.observable.subscribe(action)
}

/**
 * Represent combined coods and drop channels used to track
 * drag and drop events.
 */
class DragChannel {
    constructor() {
        this.coords = new CustomChannel()
        this.lift = new CustomChannel()
        this.drop = new CustomChannel()
    }
}

export const recipeDragChannel = new DragChannel()
export const weekdayDragChannel = new DragChannel()