import AuthorizationException from '../../exceptions/AuthorizationException'
import ServerErrorException from '../../exceptions/ServerErrorException'

/**
 * The base query handler implements the common fetching
 * strategy of server resources.
 * 
 * Resources are fetched from cache first and then from
 * HTTP. This allows the client to use the cache result
 * immediately while waiting on the HTTP promise to
 * resolve later.
 */
export default class BaseQueryHandler {
    async fetch(...props) {
        let cacheData = await this.fetchCache(...props)
        let httpResult = this.fetchHttp(...props)

        let httpPromise = new Promise((resolve, reject) => {
            httpResult.then(httpData => {
                // Update the cache with the latest fetched values.
                this.updateCache(httpData, cacheData)

                // Resolve with similar data structure.
                resolve({
                    data: this.transformForClient(httpData)
                })
            }).catch(error => {
                reject(error)
            })
        })

        return {
            data: this.transformForClient(cacheData),
            httpResult: httpPromise,
        }
    }

    /**
     * Check that the HTTP response is OK, otherwise throw the relevant exception.
     * @param {fetch API HTTP response} response 
     */
    checkHttpResponse(response) {
        if (!response.ok) {
            switch (response.status) {
                case 401:
                    throw new AuthorizationException('HTTP authorization challenge')
                default:
                    throw new ServerErrorException(`Server error: ${response.status}`)
            }
        }
    }

    /**
     * Transforms the data for the client from its cached or HTTP form.
     * 
     * Feel free to override this default implementation.
     * @param {*} data 
     */
    transformForClient(data) {
        return data
    }

    /**
     * Fetch the resource from the cache.
     * You must override this function when you inherit this class.
     * @param  {...any} props
     */
    async fetchCache(...props) {
        return null
    }

    /**
     * Fetch the resource from HTTP.
     * You must override this function when you inherit this class.
     * @param  {...any} props
     */
    async fetchHttp(...props) {
        return null
    }

    /**
     * Update the resource in the cache.
     * You must override this function when you inherit this class.
     * @param  {...any} props
     */
    async updateCache(...props) {
    }
}