import { clearToastMessage } from '../../../actions'
import { connect } from 'react-redux';
import Toaster from '../Toaster'

const mapStateToProps = state => ({
    message: state.app.toasterMessage,
});

const mapDispatchToProps = dispatch => ({
    clearMessage: () => dispatch(clearToastMessage()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Toaster);