import BaseQueryHandler from './BaseQueryHandler'
import HttpHelper from '../../common/HttpHelper'
import PlanCacheReader from 'services/cache/PlanCacheReader'
import PlanCacheWriter from 'services/cache/PlanCacheWriter'
import { Set as ImmutableSet } from 'immutable'

export default class GetFullCalendarQueryHandler extends BaseQueryHandler{
    constructor() {
        super()
        this.httpHelper = new HttpHelper()
        this.planCacheReader = new PlanCacheReader()
        this.planCacheWriter = new PlanCacheWriter()
    }

    transformForClient(plans) {
        return plans
        .filter(plan => plan.items.length > 0)
        // Support only single recipe per date for now.
        .map(plan => ({ date: plan.date, recipeId: plan.items[0].recipeId}))
    }

    async fetchCache() {
        return await this.planCacheReader.getAll()
    }   

    async fetchHttp() {
        const response = await this.httpHelper.get('planner')
        this.checkHttpResponse(response)
        const plans = await response.json()
        // Convert the date from a string to date object.
        return plans.map(plan => ({ ...plan, date: new Date(plan.date)}))
    }

    async updateCache(plans, cachedPlans) {
        // Note: immutable-js 4.0.0-rc.12 throws an error when using more than 8 dates in a set.
        //       Just convert the dates to numbers for now until a fix becomes available.
        const cachedPlanDates = ImmutableSet((cachedPlans || []).map(plan => plan.date.valueOf()))
        const planDates = ImmutableSet((plans || []).map(plan => plan.date.valueOf()))
        const planDatesToDelete = cachedPlanDates.subtract(planDates).toArray().map(dateValue => new Date(dateValue))
        await this.planCacheWriter.bulkDelete(planDatesToDelete)
        await this.planCacheWriter.bulkPut(plans)
    }
}

export const getFullCalendarQueryHandler = new GetFullCalendarQueryHandler()