import { connect } from 'react-redux';
import AppAuth from '../AppAuth'

const mapStateToProps = state => ({
    theme: state.app.themeConfig.theme
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppAuth);