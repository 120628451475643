import { db } from 'services/cache/CacheController'
import { getUsername } from 'services/common/user'

export default class PlanCacheReader {
    async getAll() {
        let userId = await getUsername()
        return await db.plans.where({ userId }).toArray()
    }

    async getByDate(date) {
        let userId = await getUsername()
        return await db.plans.where({ date, userId }).first()
    }
}