import { db } from 'services/cache/CacheController'
import { getUsername } from 'services/common/user'

export default class TagCacheWriter {
    /**
     * Create or overwrite into the cache.
     * @param {*} tags 
     */
    async bulkPut(tags) {
        let userId = await getUsername()
        await db.transaction('rw', db.tags, async () => {
            await db.tags.bulkPut(tags.map(tag => ({ ...tag, userId })))
        })
    }

    async bulkDelete(tagNames) {
        let userId = await getUsername()
        await db.transaction('rw', db.tags, async () => {
            await db.tags.bulkDelete(tagNames.map(tagName => [tagName, userId]))
        })
    }
}