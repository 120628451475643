import React from 'react'
import AppBar from 'components/common/containers/AppBar'
import { Box, IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import FormEdit from 'components/common/FormEdit'
import ProfileForm, { validationSchema } from './ProfileForm'
import ProfileFormButtons from './containers/ProfileFormButtons'
import ReactGA from 'react-ga4'

const Profile = ({ profile, isEditing, setProfileEditing }) => {
    const isDisabled = !isEditing

    const onClick = React.useCallback(() => {
        setProfileEditing(true)
        ReactGA.event({
            category: 'Profile',
            action: 'Edit',
        })
    }, [setProfileEditing])

    React.useEffect(() => {
        // On unmount, ensure we are in the not editing state.
        return () => {
            setProfileEditing(false)
        }
    }, [setProfileEditing])

    const editButton = isDisabled ? (
        <IconButton color="inherit" onClick={onClick}>
            <EditIcon />
        </IconButton>
    ) : null

    return (
        <Box>
            <AppBar title="Profile" rightMenu={editButton}></AppBar>
            <Box m={1}>
                {isDisabled ? (
                    <ProfileForm formData={profile} isDisabled />
                ) : (
                    <FormEdit
                        formData={profile}
                        validationSchema={validationSchema}
                    >
                        <ProfileForm />
                        <ProfileFormButtons />
                    </FormEdit>
                )}
            </Box>
        </Box>
    )
}

export default Profile
