import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Card, Typography, CardActions, IconButton } from '@material-ui/core'
import DayAndDate from './DayAndDate'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles(theme => ({
    card: {
        minHeight: '70px',
        display: 'flex',
        flexGrow: 1,
    },
    text: {
        marginLeft: theme.spacing(1),
        flexGrow: 1,
    },
    details: {
        flexGrow: 1,
        display: 'flex',
        overflow: 'hidden',
        alignItems: 'center',
    },
    cardActions: {
        padding: '0px',
    },
    actionButton: {
        padding: '4px',
    },
}))

const WeekDayCard = React.forwardRef(({
    // Required by DraggableCard
    className,
    style,
    otherTransform,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    onBeforeMouseDown,
    onBeforeMouseUp,
    onBeforeTouchStart,
    onBeforeTouchEnd,
    // Normal props
    history,
    recipe,
    backingRecipe,
    isBackingCard,
    stamp,
    date,
    day,
    deleteRecipe,
    selectRecipe,
    enableAddRecipe,
    disableClick,
}, ref) => {
    const classes = useStyles();

    const theRecipe = isBackingCard ? backingRecipe : recipe

    const onMouseDownWrapper = React.useCallback((...props) => {
        onBeforeMouseDown && onBeforeMouseDown(onMouseDown, ...props)
    }, [onBeforeMouseDown, onMouseDown])

    const onMouseUpWrapper = React.useCallback((...props) => {
        onBeforeMouseUp && onBeforeMouseUp(onMouseUp, ...props)
    }, [onBeforeMouseUp, onMouseUp])

    const onTouchStartWrapper = React.useCallback((...props) => {
        onBeforeTouchStart && onBeforeTouchStart(onTouchStart, ...props)
    }, [onBeforeTouchStart, onTouchStart])

    const onTouchEndWrapper = React.useCallback((...props) => {
        onBeforeTouchEnd && onBeforeTouchEnd(onTouchEnd, ...props)
    }, [onBeforeTouchEnd, onTouchEnd])

    const onDelete = React.useCallback(() => {
        deleteRecipe(stamp, recipe)
    }, [deleteRecipe, stamp, recipe]);

    const onSelect = React.useCallback(() => {
        selectRecipe(stamp)
    }, [selectRecipe, stamp]);

    const onCardClick = React.useCallback(() => {
        !disableClick && recipe && history.push(`/recipes/edit/${recipe.id}`)
    }, [disableClick, history, recipe]);

    const action = theRecipe != null ? (
        <IconButton onClick={onDelete} className={classes.actionButton}>
            <DeleteIcon/>
        </IconButton>
    ) : enableAddRecipe ? (
        <IconButton onClick={onSelect} className={classes.actionButton}>
            <AddIcon/>
        </IconButton>
    ) : null;
    
    const overrideTransformStyle = { ...style, ...{ transform: clsx(style && style.transform, otherTransform) } }

    return (
        <Card
        ref={ref}
        className={clsx(className, classes.card)}
        style={overrideTransformStyle}
        square
        onMouseDown={onMouseDownWrapper}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUpWrapper}
        onTouchStart={onTouchStartWrapper}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEndWrapper}
        >
            <DayAndDate day={day} date={date}/>
            <div
            className={classes.details}
            onClick={onCardClick}
            >
                { theRecipe != null ? <Typography className={classes.text}>{theRecipe.title}</Typography> : null }
            </div>
            <CardActions
            className={classes.cardActions}
            disableSpacing
            aria-label="choose recipe">
                {action}
            </CardActions>
        </Card>
    );
});

export default WeekDayCard