import React from 'react'
import AppBar from 'components/common/containers/AppBar'
import CalendarPageBody from './containers/CalendarPageBody'

export const PickDatePage = ({ recipes, match, enableAddRecipe }) => {
    const recipe = match.params.id != null ? recipes[match.params.id] : null
    const title = recipe != null ? `Pick date (${recipe.title})` : 'Pick date'

    return (
        <div>
            <AppBar
                title={title}
                returnable
            >
            </AppBar>
            <CalendarPageBody match={match} enableAddRecipe={enableAddRecipe}/>
        </div>
    )
}