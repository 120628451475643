import { connect } from 'react-redux';
import { closeErrorModal } from 'actions'
import ErrorModal from '../ErrorModal' 

const mapStateToProps = state => ({
    open: state.app.errorModal.open,
    message: state.app.errorModal.message,
});

const mapDispatchToProps = dispatch => ({
    closeErrorModal: () => dispatch(closeErrorModal())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ErrorModal);