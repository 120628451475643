import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box } from '@material-ui/core'
import CookieSettings from './containers/CookieSettings'
import AppBar from 'components/common/containers/AppBar'

const CookieSettingsPage = ({ previousUrl, enableAcceptCookiesDialog, disableAcceptCookiesDialog }) => {
    const history = useHistory()

    const onDone = React.useCallback(()=> {
        if (previousUrl != null) {
            history.goBack()
        } else {
            history.replace('/')
        }
    }, [history, previousUrl])

    React.useEffect(() => {
        disableAcceptCookiesDialog()

        return enableAcceptCookiesDialog
    }, [disableAcceptCookiesDialog, enableAcceptCookiesDialog])

    return (
        <Box>
            <AppBar
                title="Cookie Settings"
            >
            </AppBar>
            <CookieSettings onSave={onDone} onCancel={onDone} />
        </Box>
    )
}

export default CookieSettingsPage