import {
  SHOW_ACCEPT_COOKIES_DIALOG,
  HIDE_ACCEPT_COOKIES_DIALOG,
  DISABLE_ACCEPT_COOKIES_DIALOG,
  ENABLE_ACCEPT_COOKIES_DIALOG,
  SET_COOKIE_SETTINGS,
  SET_HOME_PAGE_URL,
  SET_COOKIE_SETTINGS_URL,
  SET_COOKIE_POLICY_URL,
  SET_PREVIOUS_URL
} from './actions'
import {
  acceptAllCookieSettings,
  clearCookieSettings,
  defaultCookieSettings,
  getCookieSettings,
  isValidCookieSettings,
  saveCookieSettings
} from '../cookieSettingsStorage'

export const showAcceptCookiesDialog = () => ({
  type: SHOW_ACCEPT_COOKIES_DIALOG
})

export const hideAcceptCookiesDialog = () => ({
  type: HIDE_ACCEPT_COOKIES_DIALOG
})

export const enableAcceptCookiesDialog = () => ({
  type: ENABLE_ACCEPT_COOKIES_DIALOG
})

export const disableAcceptCookiesDialog = () => ({
  type: DISABLE_ACCEPT_COOKIES_DIALOG
})

export const setCookieSettings = settings => ({
  type: SET_COOKIE_SETTINGS,
  settings
})

export const setHomePageUrl = url => ({
  type: SET_HOME_PAGE_URL,
  url
})

export const setCookieSettingsUrl = url => ({
  type: SET_COOKIE_SETTINGS_URL,
  url
})

export const setCookiePolicyUrl = url => ({
  type: SET_COOKIE_POLICY_URL,
  url
})

export const setPreviousUrl = url => ({
  type: SET_PREVIOUS_URL,
  url
})

export const loadCookieSettingsThunk = () => {
  return async dispatch => {
    const settings = getCookieSettings()

    // Only load the cookie settings if they are valid.
    if (isValidCookieSettings(settings)) {
      dispatch(setCookieSettings(settings))
      dispatch(hideAcceptCookiesDialog())
    } else {
      dispatch(showAcceptCookiesDialog())
    }
  }
}

export const saveCookieSettingsThunk = settings => {
  return async dispatch => {
    saveCookieSettings(settings)
    dispatch(setCookieSettings(settings))
    dispatch(loadCookieSettingsThunk())
  }
}

export const clearCookieSettingsThunk = settings => {
  return async dispatch => {
    clearCookieSettings(settings)
    dispatch(setCookieSettings(defaultCookieSettings))
  }
}

export const acceptAllCookiesThunk = () => {
  return async dispatch => {
    saveCookieSettings(acceptAllCookieSettings)
    dispatch(setCookieSettings(acceptAllCookieSettings))
    dispatch(hideAcceptCookiesDialog())
  }
}
