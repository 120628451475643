import { connect } from 'react-redux';
import BusyModal from '../BusyModal' 

const mapStateToProps = state => ({
    open: state.app.busyModal.open,
    message: state.app.busyModal.message,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BusyModal);