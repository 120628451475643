import React from 'react'
import { makeStyles } from '@material-ui/styles'
import DateFnsUtils from '@date-io/moment'
import {
    MuiPickersUtilsProvider,
    DatePicker as MuiDatePicker,
} from '@material-ui/pickers'
import { Box, IconButton, Paper } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ReactGA from 'react-ga4'

const useStyles = makeStyles((theme) => ({
    picker: {
        color: 'inherit',
        flexGrow: 1,
    },
}))

export const DatePicker = ({
    weekDate,
    setWeek,
    setPrevPlannerWeek,
    setNextPlannerWeek,
}) => {
    const classes = useStyles()

    const handleDateChange = React.useCallback(
        (date) => {
            setWeek(date)
            ReactGA.event({
                category: 'Calendar',
                action: 'Select Week',
            })
        },
        [setWeek]
    )

    const onPrevWeek = React.useCallback(() => {
        setPrevPlannerWeek()
        ReactGA.event({
            category: 'Calendar',
            action: 'Previous Week',
        })
    }, [setPrevPlannerWeek])

    const onNextWeek = React.useCallback(() => {
        setNextPlannerWeek()
        ReactGA.event({
            category: 'Calendar',
            action: 'Next Week',
        })
    }, [setNextPlannerWeek])

    const onOpen = React.useCallback(() => {
        ReactGA.event({
            category: 'Calendar',
            action: 'Open Date Picker',
        })
    }, [])

    return (
        <Box position="sticky" top={0} zIndex={1} bgcolor="white">
            <Paper square elevation={4}>
                <Box
                    display="flex"
                    paddingTop={2}
                    marginLeft={1}
                    paddingBottom={0.25}
                >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <MuiDatePicker
                            disableToolbar
                            variant="inline"
                            format="MMMM"
                            id="date-picker-inline"
                            value={weekDate}
                            onChange={handleDateChange}
                            onOpen={onOpen}
                            autoOk="true"
                            InputProps={{
                                className: classes.picker,
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <IconButton
                        aria-label="previous week"
                        size="small"
                        onClick={onPrevWeek}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                    <IconButton
                        aria-label="next week"
                        size="small"
                        onClick={onNextWeek}
                    >
                        <ChevronRightIcon />
                    </IconButton>
                </Box>
            </Paper>
        </Box>
    )
}
