import {
    CLEAR_TOAST_MESSAGE,
    SET_TOAST_MESSAGE,
    SET_ONLINE_STATUS,
    SET_REFRESHING_KEY,
    CLEAR_REFRESHING_KEY,
    OPEN_BUSY_MODAL,
    CLOSE_BUSY_MODAL,
    OPEN_ERROR_MODAL,
    CLOSE_ERROR_MODAL,
    SET_LATEST_TERMS_FETCHING,
    SET_LATEST_TERMS_ERROR,
    SET_LATEST_TERMS,
    SET_ACCEPT_NEW_TERMS_PENDING,
    SET_ACCEPT_NEW_TERMS_ERROR,
    SET_THEME_CONFIG,
    REGISTER_APP_MUX_PAGES,
    PUSH_APP_MUX_PAGE,
    POP_APP_MUX_PAGE,
    SET_INSTALL_PROMPT,
    SHOW_APP_UPDATE_DIALOG
} from '../actions/actions'
import {
    Map as ImmutableMap,
    Set as ImmutableSet,
    OrderedSet as ImmutableOrderedSet,
} from 'immutable'
import MuxPage from './MuxPage'
import { defaultTheme } from 'components/app/themes'
import loglevel from 'loglevel'

const log = loglevel.getLogger('reducers')

const initialState = {
    toasterMessage: null,
    isOnline: false,
    refreshingKeys: ImmutableSet(),
    busyModal: {
        open: false,
        message: ''
    },
    errorModal: {
        open: false,
        message: ''
    },
    latestTerms: {
        isFetching: false,
        error: null,
        id: null,
        uri: null,
        dateCreated: null,
        text: null,
    },
    acceptNewTerms: {
        isPending: false,
        isError: false
    },
    appMux: {
        pageMap: ImmutableMap(),
        pageQueue: ImmutableOrderedSet(),
    },
    themeConfig: {
        index: 0,
        theme: defaultTheme
    },
    installPrompt: null,
    showAppUpdateDialog: false
};

const entities = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_TOAST_MESSAGE:
            return { ...state, toasterMessage: null };
        case SET_TOAST_MESSAGE:
            return { ...state, toasterMessage: action.message };
        case SET_ONLINE_STATUS:
            return { ...state, isOnline: action.isOnline }
        case SET_REFRESHING_KEY:
            return { ...state, refreshingKeys:  state.refreshingKeys.add(action.key) }
        case CLEAR_REFRESHING_KEY:
            return { ...state, refreshingKeys:  state.refreshingKeys.delete(action.key) }
        case OPEN_BUSY_MODAL:
            return {
                ...state,
                busyModal: {
                    open: true,
                    message: action.message,
                } ,
            }
        case CLOSE_BUSY_MODAL:
            return {
                ...state,
                busyModal: {
                    open: false,
                } ,
            }
        case OPEN_ERROR_MODAL:
            return {
                ...state,
                errorModal: {
                    open: true,
                    message: action.message,
                } ,
            }
        case CLOSE_ERROR_MODAL:
            return {
                ...state,
                errorModal: {
                    open: false,
                } ,
            }
        case SET_LATEST_TERMS_FETCHING:
            return {
                ...state,
                latestTerms: {
                    ...state.latestTerms,
                    isFetching: action.isFetching,
                }
            }
        case SET_LATEST_TERMS_ERROR:
            return {
                ...state,
                latestTerms: {
                    ...state.latestTerms,
                    error: action.error,
                }
            }
        case SET_LATEST_TERMS:
            return {
                ...state,
                latestTerms: {
                    ...state.latestTerms,
                    id: action.terms.id,
                    uri: action.terms.uri,
                    dateCreated: action.terms.dateCreated,
                    text: action.terms.text,
                }
            }
        case SET_ACCEPT_NEW_TERMS_PENDING:
            return {
                ...state,
                acceptNewTerms: {
                    ...state.acceptNewTerms,
                    isPending: action.isPending,
                }
            }
        case SET_ACCEPT_NEW_TERMS_ERROR:
            return {
                ...state,
                acceptNewTerms: {
                    ...state.acceptNewTerms,
                    isError: action.isError,
                }
            }
        case REGISTER_APP_MUX_PAGES:
            const newPageMap = ImmutableMap(action.pages.map(page => [page.name, new MuxPage(page.name, page.priority)]))

            if (!newPageMap.equals(state.appMux.pageMap)) {

                return {
                    ...state,
                    appMux:{
                        ...state.appMux,
                        pageMap: newPageMap,
                    }
                }
            }

            return state
        case PUSH_APP_MUX_PAGE:
            const pageToAdd = state.appMux.pageMap.get(action.pageName)
            if (pageToAdd == null) {
                log.warn('PUSH_APP_MUX_PAGE: unknown page:', action.pageName)
                return state
            }

            if (!state.appMux.pageQueue.has(pageToAdd)) {
                return {
                    ...state,
                    appMux:{
                        ...state.appMux,
                        pageQueue: state.appMux.pageQueue.add(pageToAdd).sort(MuxPage.comparator),
                    }
                }
            }

            return state
        case POP_APP_MUX_PAGE:
            const pageToDelete = state.appMux.pageMap.get(action.pageName)
            if (pageToDelete == null) {
                log.warn('POP_APP_MUX_PAGE: unknown page:', action.pageName)
                return state
            }

            return {
                ...state,
                appMux:{
                    ...state.appMux,
                    pageQueue: state.appMux.pageQueue.delete(pageToDelete),
                }
            }
        case SET_THEME_CONFIG: {
            return  {
                ...state,
                themeConfig: action.config
            }
        }
        case SET_INSTALL_PROMPT: {
            return {
                ...state,
                installPrompt : action.installPrompt
            }
        }
        case SHOW_APP_UPDATE_DIALOG: {
            return {
                ...state,
                showAppUpdateDialog: action.show
            }
        }
        default:
            return state;
    }
}

export default entities;