import React from 'react';
import {
    Box
} from '@material-ui/core'
import AppBar from 'components/common/containers/AppBar'
import CalendarPageBody from './containers/CalendarPageBody'

export const CalendarPage = () => {
    return (
        <Box>
            <AppBar title="Calendar"/>
            <CalendarPageBody enableAddRecipe/>
        </Box>
    )
}