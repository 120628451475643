import React from 'react'
import PropTypes from 'prop-types'
import {
  Link
} from '@material-ui/core'

const PolicyLink = ({ children, cookiePolicyUrl }) => {
  return (
    <Link href={cookiePolicyUrl} target='_blank' rel='noopener' color='inherit' underline='always'>
      {children}
    </Link>
  )
}

PolicyLink.propTypes = {
  children: PropTypes.element.isRequired,
  cookiePolicyUrl: PropTypes.string.isRequired
}

export default PolicyLink
