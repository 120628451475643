import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, InputLabel, Input, InputAdornment, IconButton } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import FilterListIcon from '@material-ui/icons/FilterList';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%'
    },
    optionsButton: {
    },
    padding: {
        paddingLeft: theme.spacing(1)
    },
}));

const SearchInput = React.forwardRef(({
    search,
    onSearchChange,
    onSearchOptions,
    hasSearchOptions,
    onClearSearch,
    searchOptionsOpen
}, ref) => {
    const classes = useStyles();
    return (
        <FormControl
        className={classes.form}
        ref={ref}>
            <InputLabel htmlFor="filled-search" className={classes.padding}>Search</InputLabel>
            <Input
            inputProps={{
                className: classes.padding
            }}
            id="filled-search"
            label="Search"
            type="text"
            margin="none"
            variant="filled"
            fullWidth={true}
            onChange={onSearchChange}
            value={search}
            endAdornment={
                <InputAdornment position="end">
                    {search ?
                        <IconButton
                        aria-label="clear search"
                        onClick={onClearSearch}>
                            <ClearIcon/>
                        </IconButton> : null
                    }
                    <IconButton
                    className={classes.optionsButton}
                    aria-label="search options"
                    onClick={onSearchOptions}
                    color={hasSearchOptions ? 'secondary' : 'default'}
                    >
                        { searchOptionsOpen ? <ExpandLessIcon/> : <FilterListIcon/> }
                    </IconButton>
                </InputAdornment>
            }
            />
        </FormControl>
    )
})

export default SearchInput