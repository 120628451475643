import { setPlannerWeek, prevPlannerWeek, nextPlannerWeek } from 'actions'
import { connect } from 'react-redux';
import { DatePicker } from '../DatePicker'

const mapStateToProps = state => ({
    weekDate: state.planner.weekDate
});

const mapDispatchToProps = dispatch => ({
    setWeek: date => dispatch(setPlannerWeek(date)),
    setPrevPlannerWeek: () => dispatch(prevPlannerWeek()),
    setNextPlannerWeek: () => dispatch(nextPlannerWeek())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DatePicker);