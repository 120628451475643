import { connect } from 'react-redux'
import {
  acceptAllCookiesThunk
} from '../actions'
import AcceptCookiesBanner from '../AcceptCookiesBanner'

const mapStateToProps = state => ({
  open: state.acceptCookiesDialog.show,
  disabled: state.acceptCookiesDialog.disabled,
  cookieSettingsUrl: state.urls.cookieSettingsUrl
})

const mapDispatchToProps = dispatch => ({
  acceptAllCookies: () => dispatch(acceptAllCookiesThunk())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcceptCookiesBanner)
