import Cookies from 'js-cookie'

// Note: this won't work for .co.uk domains since co.uk is the top level domain.
const match = new RegExp(/[^.]+\.[^.]+$/).exec(window.location.host)

let attributes = { path: '/', expires: 365 * 2 }

if (match) {
  attributes = { ...attributes, domain: `.${match[0]}` }
}

const cookiesApi = Cookies.withAttributes(attributes)

/**
 * The version of the cookie settings.
 *
 * This version simply tracks the version of the current cookie
 * settings object. If there is a mismatch between the version
 * accepted by the user and the current version, then the user
 * will need to review his cookie settings again.
 */
const version = 1

const cookieSettingsKey = 'CookiePreferences'

// By default, cookies must be deselected until the user selects them.
export const defaultCookieSettings = {
  version,
  acceptUsageMonitoring: false
}

export const acceptAllCookieSettings = {
  ...defaultCookieSettings,
  acceptUsageMonitoring: true
}

export const isValidCookieSettings = settings => {
  return settings != null &&
    (typeof settings === 'object') &&
    settings.version === version
}

export const saveCookieSettings = settings => {
  if (!isValidCookieSettings(settings)) {
    throw Error(`Failed to save cookie settings: invalid settings: ${settings}`)
  }

  cookiesApi.set(cookieSettingsKey, JSON.stringify(settings))
}

export const clearCookieSettings = () => {
  cookiesApi.remove(cookieSettingsKey)
}

export const getCookieSettings = () => {
  return JSON.parse(cookiesApi.get(cookieSettingsKey) || '{}')
}
