import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ReactMarkdown from 'react-markdown'

const useStyles = makeStyles(theme => ({
    dialogContent:{
        '& h1': {
            fontSize: '1.5rem',
        },
        '& h2': {
            fontSize: '1.35rem',
            margin: '1rem 0 ',
        },
        '& h3': {
            fontSize: '1.2rem',
            margin: '1rem 0 ',
        },
        '& h4, h5, h6': {
            fontSize: '1.1rem',
            fontWeight: '400',
            margin: '1rem 0 ',
        },
        '& p':{
            margin: '1rem 0 ',
        }
    },
}))

const TermsAndConditionsDialog = ( { open, onClose, terms } ) => {
    const classes = useStyles()

    return (
        <Dialog
        aria-labelledby="terms-dialog-title"
        maxWidth="md"
        disableBackdropClick
        open={ open }
        onClose={ onClose }
        >
            <DialogTitle
            id="terms-dialog-title"
            >
                Terms and conditions
            </DialogTitle>
            <DialogContent
            className={classes.content}
            dividers
            >
                <ReactMarkdown
                className={ classes.dialogContent }
                source={ terms }
                renderers={{
                    heading: (props) => {
                    return <Typography variant={`h${props.level}`}>{props.children}</Typography>
                    },
                    paragraph: (props) => <Typography align="justify" variant="body2">{props.children}</Typography>,
                }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                color="primary"
                onClick={ onClose }
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default TermsAndConditionsDialog